import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Trash2, Eye } from "react-feather";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "sweetalert2";
import {
  faAdd,
  faArrowLeft,
  faArrowRight,
  faUpload,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import DrawingGallary from "./Image-Gallary/DrawingGallary";
import {
  getOperationApi,
  getRoutesApi,
  getMachineList,
  getProdConfig,
  uploadMultiImage,
  createProdConfig,
  updateProdConfig,
  getProdImages,
  deleteProdOperation,
  deleteProdMachine,
  createProdMachines, bulk_delete_product_operation
} from "../../../../../api/index";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import MachineModel from "./Machine-Model/MachineModel";
import {
  convertBase64,
  getLabels,
} from "../../../../../common/Functions/CommonFunctions";
import CreateBOM from "../Bill-Of-Material/CreateBOM";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const schema = yup
  .object()
  .shape({
    routes: yup.array().of(
      yup.object().shape({
        operationList: yup.array().of(
          yup.object().shape({
            sequenceNo: yup
              .string()
              .matches(/^\d+$/, "Enter valid sequence")
              .required("Please enter sequence"),
            operation: yup
              .array()
              .required("Please select operation")
              .min(1, "Please select operation"),
            oprcost: yup
              .string()
              .required("Operation cost is required !")
              .matches(/^\d+(\.\d+)?$/, "Enter valid operation cost"),
            man_hour_cost: yup.string().matches(/^\d+(\.\d+)?$/, {
              message: "Enter valid man hour cost !",
              excludeEmptyString: true,
            }),
            job_weight: yup.string().matches(/^\d+(\.\d+)?$/, {
              message: "Enter valid job weight !",
              excludeEmptyString: true,
            }),
            job_length: yup.string().matches(/^\d+(\.\d+)?$/, {
              message: "Enter valid job length !",
              excludeEmptyString: true,
            }),
            route: yup
              .array()
              .required("Please select route")
              .min(1, "Please select route"),
            serial_number_tracking: yup.boolean().notRequired(),
          }))
      }))
  })
// .required();

function ProductSpecification({
  nextStep,
  previousStep,
  main_obj,
  setMain_obj, routesList, importData
}) {
  const [drawingFiles, setDrawingFiles] = useState([[{ img: [] }]]);
  const [machines, setMachines] = useState([[{ data: [] }]]);
  const [images, setImages] = useState({ loading: false, data: [] });

  const [operationList, setOperationList] = useState({
    data: [],
    loading: false,
  });

  const [action, setAction] = useState("create");
  // const [routesList, setRoutesList] = useState({ data: [], loading: false });
  const [galVisible, setGalVisible] = useState(false);
  const [machineModal, setMachineModal] = useState({
    state: false,
    mode: "create",
    selMachine: {},
    macInd: "",
  });

  const [currIndex, setCurrIndex] = useState(0);
  const [operIndex, setoperIndex] = useState(0);

  const [machineList, setMachineList] = useState({ data: [], loading: false });
  const [operList, setOperList] = useState({ data: [], loading: false });
  const [constLabel, setConstLabel] = useState({});
  const [btnDisable, setBtnDisable] = useState(false);

  const [bomShow, setBomShow] = useState(false);
  const [routeData, setRouteData] = useState({ uniqueRoutesWithMachines: [] });

  const [key, setKey] = useState(null);

  let navigate = useNavigate();

  // const productData = JSON.parse(localStorage.getItem("prod_res"));
  // const bom_status = JSON.parse(localStorage.getItem('bomStatus'));

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    getValues,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      routes: [{ operationList: [{ serial_number_tracking: false }] }],
    },
  });

  let { errors } = formState;

  const { fields, append, replace, remove } = useFieldArray({ control, name: "routes" });

  const fullFormData = watch('routes');
  // const prod_data = JSON.parse(localStorage.getItem("prod_res"));

  // function removeMachine(index) {
  //   let tempMachines = [...machines];

  //   tempMachines[index]?.data?.splice(index, 1);
  //   setMachines(tempMachines);

  // }

  function fetchImages(index, operIndex) {
    console.log("fields", fields);
    console.log("index", index);
    console.log("operIndex", operIndex);
    if (fields[index].operationList[operIndex].po_uuid !== undefined) {
      setImages({ ...images, loading: true, data: [] });
      getProdImages({ product_operation_id: fields[index].operationList[operIndex].po_uuid }).then(
        (res) => {
          setImages({ ...images, loading: false, data: res.data.data });
        },
        (err) => {
          setImages({ ...images, loading: false, data: [] });
        }
      );
    } else {
      setImages({ ...images, loading: true, data: [] });
    }
  }

  useEffect(() => {
    if (!importData) {
      getchProdConfig();
    }
  }, [importData])

  useEffect(() => {
    setConstLabel(getLabels("Master", "Product"));
    // setRoutesList({ ...routesList, data: [], loading: true });
    setOperationList({ ...operationList, data: [], loading: true });

    // getRoutesApi().then(
    //   (res) => {
    //     setRoutesList({ ...routesList, data: res.data.data, loading: false });
    //   },
    //   (err) => {
    //     setRoutesList({ ...routesList, data: [], loading: false });
    //   }
    // );

    getOperationApi().then(
      (res) => {
        setOperationList({
          ...operationList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        setOperationList({ ...operationList, data: [], loading: false });
      }
    );

    setMachineList({ ...machineList, data: [], loading: true });
    getMachineList().then(
      (res) => {
        const result = res.data.data.filter(
          (item) =>
            item.machineName !== "" &&
            item.machineName !== null &&
            item.machineName !== undefined
        );

        const ress = result.map((item) => ({
          ...item,
          machineNameSerialNo: item.machineName + " - " + item.serialNo,
        }));
        setMachineList({ ...machineList, data: ress, loading: false });
      },
      (err) => {
        setMachineList({ ...machineList, data: [], loading: false });
      }
    );


  }, []);

  useEffect(() => {
    console.log(key);
    console.log(routeData);
    console.log(main_obj.selected_routes);

    if (key && main_obj.selected_routes.length > 0 && routeData.uniqueRoutesWithMachines.length > 0) {
      console.log("---------------");
      setlineItems(routeData.uniqueRoutesWithMachines, "newTab");

    }
    // this condition means there was one route and user was deleting that one last route
    else if (key && main_obj.selected_routes.length == 0 && routeData.uniqueRoutesWithMachines.length > 0) {
      const oprIdsToDelete = [];

      for (const item of routeData.uniqueRoutesWithMachines) {
        for (const opr of item.operations) {
          oprIdsToDelete.push(opr.uuid);
        }
      }

      console.log("oprIdsToDelete", oprIdsToDelete);

      bulk_delete_product_operation({ uuids: oprIdsToDelete }).then((res) => {
        console.log("res", res);
        // setDrawingFiles([[{ img: [] }]]);
        // setMachines([[{ data: [] }]]);
        getchProdConfig();

        toast.success("Route deleted successfully", {
          autoClose: 2000,
        });
      }, (err) => {
        console.log("err", err);
        toast.success(err.response.data.message, {
          autoClose: 2000,
        });
      });


    }
    // below else if means there routes selected new or old
    else if (main_obj.selected_routes && main_obj.selected_routes.length > 0) {
      console.log("routesList.data", routesList.data);
      console.log("main_obj.selected_routes", main_obj.selected_routes);

      const selectedRouteIndex = routesList.data.findIndex((val) => val.uuid == main_obj.selected_routes[0].uuid);
      console.log("selectedRouteIndex", selectedRouteIndex);
      console.log("main_obj.selected_routes[selectedRouteIndex] ", routesList.data[selectedRouteIndex]);

      const newRoute = { ...main_obj.selected_routes[0] };

      newRoute.operations = [];

      newRoute.operationList = [{
        sequenceNo: "1", operation: [], oprcost: "0", man_hour_cost: "", job_weight: "", job_length: "",
        route: [main_obj.selected_routes[0]], serial_number_tracking: false
      }]


      reset({
        routes: [newRoute],
      });

      // setMachines([{ data: [] }]);
      // setImages({ loading: false, data: [] });
    } else if (routeData.uniqueRoutesWithMachines.length == 0) {
      reset({
        routes: [{ operationList: [{ serial_number_tracking: false }] }],
      })
    }

  }, [key, routeData.uniqueRoutesWithMachines, main_obj]);


  function getchProdConfig(callBy) {
    setOperList({ ...operList, data: [], loading: true });

    getProdConfig({ product_id: main_obj?.prod_overview?.uuid }).then(
      (res) => {
        if (res.data.data.length > 0) {
          setOperList({ ...operList, data: res.data.data, loading: false });
          setAction("update");


          const uniqueList = [];
          const seenRouteUUIDs = new Set();

          res.data.data.forEach(item => {
            if(item.routesDetails){
              delete item.routesDetails.id;
              if (!seenRouteUUIDs.has(item.routesDetails.uuid)) {
                seenRouteUUIDs.add(item.routesDetails.uuid);
                uniqueList.push(item.routesDetails);
              }
            }

          });

          console.log("uniqueList", uniqueList);


          const groupedData = {};
          res.data.data.forEach(item => {
            if(item.routesDetails){
              const routesUUID = item.routesDetails.uuid;

              // If the routesDetails object is not already in the groupedData object, add it
              if (!groupedData[routesUUID]) {
                groupedData[routesUUID] = {
                  ...item.routesDetails,
                  operations: []
                };
              }

              // Add the current operation to the appropriate routesDetails object
              groupedData[routesUUID].operations.push(item);
            }
          });

          // Convert the groupedData object to an array (if needed)
          const transformedData = Object.values(groupedData);
          console.log("transformedData", transformedData);


          if (!key) {
            setKey(uniqueList?.[0]?.uuid);
          }
          setMain_obj({ ...main_obj, selected_routes: uniqueList, });

          setRouteData({ ...routeData, uniqueRoutesWithMachines: transformedData });

          // setlineItems(transformedData, uniqueList, callBy);
        } else {
          setAction("create");

        }
      },
      (err) => {
        reset({
          routes: [[]],
        });
        setOperList({ ...operList, data: [], loading: false });
      }
    );
  }


  function setlineItems(transformedData, callBy) {
    const tempList = [];

    // let operationsOfCurrentActivevRoute = null;
    // if (!key) {
    //   operationsOfCurrentActivevRoute = transformedData.filter((val) =>
    //     val.uuid == uniqueList[0].uuid);
    // } else {
    //   console.log("key", key);
    //   operationsOfCurrentActivevRoute = transformedData.filter((val) =>
    //     val.uuid == key);

    // }
    // console.log("operationsOfCurrentActivevRoute", operationsOfCurrentActivevRoute);


    if (transformedData.length > 0) {

      let i = 0;
      for (const item of transformedData) {
        const newItem = { ...item };
        newItem.operationList = [];
        delete newItem.operation;
        tempList.push(newItem);

        for (const opr of transformedData[i].operations) {
          tempList[i].operationList.push({
            sequenceNo: opr.sequenceNo,
            serial_number_tracking: opr.serial_number_tracking,
            po_uuid: opr.uuid,
            operation: [
              {
                ProdOperationId: opr.ProdOperationId,
                uuid: opr.operation_id,
                operationName: opr.operationname,
                operationID: opr.operationid,
              },
            ],
            oprcost: opr.oprcost,
            man_hour_cost: opr.man_hour_cost !== null ? opr.man_hour_cost : "",
            job_weight: opr.job_weight !== null ? opr.job_weight : "",
            job_length: opr.job_length !== null ? opr.job_length : "",
            route: [
              {
                routesId: opr.routes1,
                routesType: opr.routesname,
                uuid: opr.routes_id,
              },
            ],
          });
        }

        i++;
      }

      console.log("transformedData", transformedData);
      console.log("main_obj.selected_routes", main_obj.selected_routes);

      console.log("main_obj.selected_routes.length", main_obj.selected_routes.length);
      console.log("transformedData.length", transformedData.length);

      // this if condition means that in dropdown new route is selected but there no operation for new route
      if (main_obj.selected_routes.length > transformedData.length) {

        console.log("main_obj", main_obj);
        const index = main_obj.selected_routes.length - 1;
        const newRoute = { ...main_obj.selected_routes[index] };

        newRoute.operations = [];

        newRoute.operationList = [{
          sequenceNo: "1", operation: [], oprcost: "0", man_hour_cost: "", job_weight: "", job_length: "",
          route: [{ ...main_obj.selected_routes[index] }], serial_number_tracking: false
        }]


        console.log("newRoute", newRoute);
        tempList.push(newRoute);


      }
      // this condition executes when there are more than one routes and user delete either of them
      else if (main_obj.selected_routes.length < transformedData.length) {

        const set2 = new Set(main_obj.selected_routes.map(item => item.uuid));

        const notInList2 = tempList.filter(item => !set2.has(item.uuid));
        console.log("notInList2", notInList2);
        const operationsToDelete = notInList2[0]?.operations?.map(val => val.uuid);

        console.log("operationsToDelete", operationsToDelete);
        bulk_delete_product_operation({ uuids: operationsToDelete }).then((res) => {
          console.log("res", res);
          // setDrawingFiles([[{ img: [] }]]);
          // setMachines([[{ data: [] }]]);
          getchProdConfig();
          toast.success("Route deleted successfully", {
            autoClose: 2000,
          });
        }, (err) => {
          console.log("err", err);
          toast.success(err.response.data.message, {
            autoClose: 2000,
          });
        });
      }
      console.log("tempList", tempList);




      let tempMachines;
      if (callBy !== "delete" && callBy !== "newTab") {
        tempMachines = [...machines];
      } else {
        tempMachines = [[{ data: [] }]];

        if (main_obj.selected_routes.length > transformedData.length) {
          tempMachines.push([{ data: [] }]);
        }
      }

      const response = [...transformedData];

      let dfiles = [...drawingFiles];

      // if (response.length > 0) {
      //   for (let i = 0; i < response.length; i++) {
      //     if (i !== 0) {
      //       tempMachines.push([{ data: [] }]);
      //       dfiles.push({ img: [] });
      //     }
      //     for (let j = 0; j < response[i].machine.length; j++) {
      //       let data = {
      //         cycleTime: response[i].machine[j].cycle_time,
      //         machineRate: response[i].machine[j].machine_rate,
      //         settingTime: response[i].machine[j].settings_time,
      //         toolChangeTime: response[i].machine[j].tool_change_time,
      //         machine: [
      //           {
      //             machineName: response[i].machine[j].name,
      //             serialNo: response[i].machine[j].serialNo,
      //             uuid: response[i].machine[j].machine_id_id,
      //             machineId: response[i].machine[j].machine_id,
      //             machineNameSerialNo:
      //               response[i].machine[j].name +
      //               " - " +
      //               response[i].machine[j].serialNo,
      //           },
      //         ],
      //         uuid: response[i].machine[j].uuid,
      //       };

      //       // Find the index of the matching object in tempMachines[i]?.data[i]
      //       const indexInTempMachines = tempMachines[i]?.data.findIndex(
      //         (tempMachine) => tempMachine?.uuid === data.uuid
      //       );

      //       if (indexInTempMachines !== -1) {
      //         // Delete the matching object from tempMachines[i]?.data[i]
      //         tempMachines[i]?.data.splice(indexInTempMachines, 1);
      //       }

      //       // Push the currentResponseMachine into tempMachines[i]?.data
      //       tempMachines[i]?.data.push(data);

      //       // tempMachines[i].data?.push(data);
      //     }
      //   }
      //   console.log("tempMachines", tempMachines);
      //   setMachines(tempMachines);
      //   setDrawingFiles(dfiles);
      // }


      if (response.length > 0) {
        let a = 0;
        for (const route of response) {
          if (a !== 0) {
            tempMachines.push([{ data: [] }]);


            dfiles.push([{ img: [] }]);
          }

          let b = 0;
          for (const oper of route.operations) {
            dfiles[a][b] = { img: [] }
            tempMachines[a][b] = { data: [] }

            let c = 0;
            let machineArr = [];
            for (const mach of oper.machine) {

              let data = {
                cycleTime: mach.cycle_time,
                machineRate: mach.machine_rate,
                settingTime: mach.settings_time,
                toolChangeTime: mach.tool_change_time,
                machine: [
                  {
                    machineName: mach.name,
                    serialNo: mach.serialNo,
                    uuid: mach.machine_id_id,
                    machineId: mach.machine_id,
                    machineNameSerialNo:
                      mach.name +
                      " - " +
                      mach.serialNo,
                  },
                ],
                uuid: mach.uuid,
              };

              // Find the index of the matching object in tempMachines[i]?.data[i]



              // const indexInTempMachines = tempMachines[a][b]?.data.findIndex(
              //   (tempMachine) => tempMachine?.uuid === data.uuid
              // );

              // if (indexInTempMachines !== -1) {
              //   // Delete the matching object from tempMachines[a][b]?.data[i]
              //   tempMachines[a][b]?.data.splice(indexInTempMachines, 1);
              // }

              // Push the currentResponseMachine into tempMachines[a][b]?.data
              machineArr.push(data);


              c++;
            }
            console.log(tempMachines);

            tempMachines[a][b].data = machineArr
            b++;
          }
          a++;
        }

        console.log("tempMachines", tempMachines);
        setMachines(tempMachines);
        console.log("dfiles", dfiles);
        setDrawingFiles(dfiles);
      }
      reset({
        routes: tempList,
      });
    } else {

      const currentSelectedRoute = routesList.data.find((val) => val.uuid == key);
      console.log("currentSelectedRoute", currentSelectedRoute);
      reset({
        routes: [{
          operationList: [{
            sequenceNo: "", operation: [], oprcost: "0", man_hour_cost: "", job_weight: "", job_length: "",
            route: [currentSelectedRoute], serial_number_tracking: false
          }]
        }],
      });

      setMachines([{ data: [] }]);
      setImages({ loading: false, data: [] });
    }
  }



  function removeMachine(machine) {
    SweetAlert.fire({
      title: "Are you sure to delete \n" + machine.machine[0].machineName,
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteProdMachine({ uuid: machine.uuid }).then(
          (res) => {
            toast.success("Machine deleted successfully", {
              autoClose: 2000,
            });
            getchProdConfig("delete");
          },
          (err) => {
            toast.error("Something went wrong", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  }

  function removeMac(index, maci, operIndex) {
    let tempMachines = [...machines];

    tempMachines[index][operIndex].data.splice(maci, 1);
    setMachines(tempMachines);
  }

  const onSubmit = async (data) => {
    console.log("data", data);
    console.log("machines", machines);
    console.log("drawingFiles", drawingFiles);
    setBtnDisable(true);
    // setMachineData([...machineData, data]);

    let primaryData = [];
    let drawingData = [];
    let machineData = [];

    if (action == "create") {
      let r = 0;
      let pri_ind = 0;

      for (let route of data.routes) {
        let o = 0;
        for (let opr of route.operationList) {
          primaryData.push({
            productid: main_obj && main_obj?.prod_overview?.productId,
            productname: main_obj && main_obj?.prod_overview?.productName,
            prodType1: main_obj && main_obj?.prod_overview?.productType,
            product_id: main_obj && main_obj?.prod_overview?.uuid,
            product_type_id:
              main_obj && main_obj?.prod_overview?.product_type_id.uuid,
            serial_number_tracking: opr.serial_number_tracking,
            operation_id: opr.operation[0].uuid,
            operationname: opr.operation[0].operationName,
            operationid: opr.operation[0].operationID,
            oprcost: opr.oprcost,
            sequenceNo: opr.sequenceNo,
            routes1: opr.route[0].routesId,
            routesname: opr.route[0].routesType,
            routes_id: opr.route[0].uuid,
            man_hour_cost: opr.man_hour_cost !== "" ? opr.man_hour_cost : null,
            job_weight: opr.job_weight !== "" ? opr.job_weight : null,
            job_length: opr.job_length !== "" ? opr.job_length : null
          });


          const machineArr = [];
          for (let k = 0; k < machines[r][o].data.length; k++) {
            machineArr.push({
              product_id: main_obj && main_obj?.prod_overview?.productId,
              operation_id: opr.operation[0].operationID,
              machine_id: machines[r][o].data[k].machine[0].machineId,
              name: machines[r][o].data[k].machine[0].machineName,
              serialNo: machines[r][o].data[k].machine[0].serialNo,
              cycle_time: machines[r][o].data[k].cycleTime,
              settings_time: machines[r][o].data[k].settingTime,
              tool_change_time: machines[r][o].data[k].toolChangeTime,
              machine_rate: machines[r][o].data[k].machineRate,
              product_id_id: main_obj && main_obj?.prod_overview?.uuid,
              // operation_id_id: data.items[r][o].operation[0].uuid,
              machine_id_id: machines[r][o].data[k].machine[0].uuid,
              uuid: machines[r][o].data[k].uuid,
            });
          }

          primaryData[pri_ind].product_machine = machineArr;

          pri_ind++;
          o++;
        }

        // for (let j = 0; j < machines.length; j++) {
        // machineData.push([]);
        // for (let k = 0; k < machines[r][].data.length; k++) {
        //   machineData[r].push({
        //     product_id: main_obj && main_obj?.prod_overview?.productId,
        //     operation_id: opr.operation[0].operationID,
        //     machine_id: machines[r].data[k].machine[0].machineId,
        //     name: machines[r].data[k].machine[0].machineName,
        //     serialNo: machines[r].data[k].machine[0].serialNo,
        //     cycle_time: machines[r].data[k].cycleTime,
        //     settings_time: machines[r].data[k].settingTime,
        //     tool_change_time: machines[r].data[k].toolChangeTime,
        //     machine_rate: machines[r].data[k].machineRate,
        //     product_id_id: main_obj && main_obj?.prod_overview?.uuid,
        //     // operation_id_id: data.items[r].operation[0].uuid,
        //     machine_id_id: machines[r].data[k].machine[0].uuid,
        //     uuid: machines[r].data[k].uuid,
        //   });
        // }
        // }

        console.log("drawingData", drawingData);
        console.log("drawingFiles", drawingFiles);

        for (let x = 0; x < drawingFiles[r].length; x++) {
          // drawingData.push([]);
          for (let y = 0; y < drawingFiles[r][x].img.length; y++) {
            // drawingData[r][x].push({
            drawingData.push({
              name: drawingFiles[r][x].img[y].filename,
              attachment: await convertBase64(drawingFiles[r][x].img[y].file),
              doc_type: drawingFiles[r][x].img[y].docType[0].name,
              // product_operation: data.items[r][x].operation[0].uuid,
              doc_type_id: drawingFiles[r][x].img[y].docType[0].uuid,
              ind: drawingFiles[r][x].ind,
            });
          }
        }
        r++;
      }


      // console.log("drawingFiles create", drawingFiles);


      console.log("primaryData", primaryData);

      // for (let a = 0; a < primaryData.length; a++) {
      // primaryData[a].product_machine = machineData[a];
      // primaryData[a].operation_drawing = drawingData[a];
      // primaryData[a].operation_drawing = [];
      // }

      const payload = {
        product_operation: primaryData,
      };
      console.log("payload", payload);
      createProdConfig(payload).then(
        (res) => {

          // console.log("res", res);
          // let imgArr = {};
          // console.log("drawingData", drawingData);
          let drawing_array = [];
          for (let a = 0; a < res.data.data.length; a++) {
            let obj_item = res.data.data[a];
            // drawingData[a].product_operation_id = res.data.data[0].product_operation.uuid;

            let arr = drawingData
              .filter((item) => item.ind == a)
              .map((item) => {
                item.product_operation_id = obj_item.product_operation.uuid;
                delete item.ind;
                return item;
              });
            drawing_array.push(...arr);
            // for (let b = 0; b < drawingData[a].length; b++) {

            //   imgArr["attachment" + (b + 1)] = drawingData[a][b].attachment;
            //   // delete drawingData[a][b].attachment;
            //   drawingData[a][b].product_operation =
            //     res.data.data[a].product_operation.uuid;
            //   imgArr["obj" + (b + 1)] = JSON.stringify(drawingData[a][b]);
            //   imgArr["img_count"] = drawingData[a].length;
            // }

            // let formDataToSend = new FormData();

            // for (const [key, value] of Object.entries(imgArr)) {
            //   formDataToSend.append(key, value);
            // }
            // imgArr = {};
            // console.log("drawingData created", drawingData);
          }

          // console.log("drawing_array",drawing_array)

          if (drawing_array.length > 0) {
            uploadMultiImage({ data: drawing_array }).then(
              (res) => {
                toast.success("Operation sequence created successfully", {
                  autoClose: 2000,
                });
                setBtnDisable(false);



                setTimeout(() => {
                  nextStep();
                }, [2300]);
              },
              (err) => {
                toast.error(err.response.data.message, {
                  autoClose: 2000,
                });
              }
            );
          } else {
            setBtnDisable(false);


            toast.success("Operation sequence created successfully", {
              autoClose: 2000,
            });
            setTimeout(() => {
              nextStep();
            }, [2300]);
          }
        },
        (err) => {
          setBtnDisable(false);

          toast.error(err.response.data.message, {
            autoClose: 2000,
          });
        }
      );
    } else {
      let a = 0;
      let pri_ind = 0;

      for (const route of data.routes) {
        let b = 0;
        for (const opr of route.operationList) {
          let temp = {
            ProdOperationId: opr.operation[0].ProdOperationId,
            uuid: opr.po_uuid,
            productid: main_obj && main_obj?.prod_overview?.productId,
            productname: main_obj && main_obj?.prod_overview?.productName,
            prodType1: main_obj && main_obj?.prod_overview?.productType,
            product_id: main_obj && main_obj?.prod_overview?.uuid,
            product_type_id:
              main_obj && main_obj?.prod_overview?.product_type_id.uuid,
            operation_id: opr.operation[0].uuid,
            serial_number_tracking: opr.serial_number_tracking,
            operationname: opr.operation[0].operationName,
            operationid: opr.operation[0].operationID,
            oprcost: opr.oprcost,
            sequenceNo: opr.sequenceNo,
            routes1: opr.route[0].routesId,
            routesname: opr.route[0].routesType,
            routes_id: opr.route[0].uuid,
            man_hour_cost: opr.man_hour_cost !== "" ? opr.man_hour_cost : null,
            job_weight: opr.job_weight !== "" ? opr.job_weight : null,
            job_length: opr.job_length !== "" ? opr.job_length : null
          };
          if (opr.operation[0].ProdOperationId == undefined) {
            delete temp.ProdOperationId;
          }
          if (opr.po_uuid == undefined) {
            delete temp.uuid;
          }
          primaryData.push(temp);

          // for (let j = 0; j < machines[a].length; j++) {
          const machineArr = [];
          for (let k = 0; k < machines[a][b].data.length; k++) {
            // machineData.push([]);
            machineArr.push({
              product_id: main_obj && main_obj?.prod_overview?.productId,
              operation_id: opr.operation[0].operationID,
              machine_id: machines[a][b].data[k].machine[0].machineId,
              name: machines[a][b].data[k].machine[0].machineName,
              serialNo: machines[a][b].data[k].machine[0].serialNo,
              cycle_time: machines[a][b].data[k].cycleTime,
              settings_time: machines[a][b].data[k].settingTime,
              tool_change_time: machines[a][b].data[k].toolChangeTime,
              machine_rate: machines[a][b].data[k].machineRate,
              product_id_id: main_obj && main_obj?.prod_overview?.uuid,
              // operation_id_id:opr.operation[0].uuid,
              machine_id_id: machines[a][b].data[k].machine[0].uuid,
              uuid: machines[a][b].data[k].uuid,
            });
          }
          console.log("primaryData", primaryData);
          console.log("b", b);

          primaryData[pri_ind].product_machine = machineArr;

          // }
          b++;
          pri_ind++;
        }

        for (let x = 0; x < drawingFiles[a].length; x++) {
          // drawingData.push([]);
          for (let y = 0; y < drawingFiles[a][x].img.length; y++) {
            // drawingData[x].push({
            drawingData.push({
              name: drawingFiles[a][x].img[y].filename,
              attachment: await convertBase64(drawingFiles[a][x].img[y].file),
              doc_type: drawingFiles[a][x].img[y].docType[0].name,
              // product_operation: data.items[x].operation[0].uuid,
              doc_type_id: drawingFiles[a][x].img[y].docType[0].uuid,
              ind: drawingFiles[a][x].ind,
            });
          }
        }

        a++;
      }


      // console.log("drawingFiles update", drawingFiles);

      console.log("primaryData", primaryData);
      console.log("machineData", machineData);

      const payload = {
        product_operation: primaryData,
      };
      console.log("payload", payload);
      console.log("drawingData", drawingData);

      updateProdConfig(payload).then(
        (res) => {


          console.log(res.data.data);


          for (const img of drawingData) {
            const result = res.data.data.find(val => val.prod_operation.operation_id == img.ind);
            console.log("result", result);
            img.product_operation_id = result.prod_operation.uuid;
            delete img.ind;
          }


          console.log("drawingData", drawingData);

          if (drawingData.length > 0) {
            uploadMultiImage({ data: drawingData }).then(
              (ress) => {
                toast.success("Operation sequence updated successfully", {
                  autoClose: 2000,
                });
                setBtnDisable(false);
                setTimeout(() => {
                  nextStep();
                }, [2300]);
              },
              (err) => {
                toast.error(err.response.data.message, {
                  autoClose: 2000,
                });
              }
            );
          } else {
            setBtnDisable(false);
            toast.success("Operation sequence updated successfully", {
              autoClose: 2000,
            });
            setTimeout(() => {
              nextStep();
            }, [2300]);
          }
          drawingData = [];
        },
        (err) => {
          setBtnDisable(false);
          toast.error(err.response.data.message, {
            autoClose: 2000,
          });
        }
      );
    }
  };


  // const onSubmit = async (data) => {
  //   console.log("data", data);
  // }


  function delProdOper(index, operIndex) {
    SweetAlert.fire({
      title: "Are you sure to delete",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteProdOperation({ uuid: fields[index].operationList[operIndex].po_uuid }).then(
          (res) => {
            toast.success("Operation sequence deleted successfully !", {
              autoClose: 1000,
            });
            getchProdConfig();

            setoperIndex((prevOperIndex) => operIndex == 0 ? 0 : parseInt(prevOperIndex) - 1);

            // if only one operation is present in route then resetting the state
            if (operIndex == 0) {
              setRouteData({ ...routeData, uniqueRoutesWithMachines: [] });
              setDrawingFiles([[{ img: [] }]]);
              setMachines([[{ data: [] }]]);
            }
          },
          (err) => { }
        );
      }
    });
  }

  function machineConfig(operation, index, operIndex) {
    if (operation.length > 0 && operation[0]?.machine_data?.length > 0) {

      let tempMachines = [];
      console.log("operation", operation);
      console.log("routeData", routeData);



      for (let i = 0; i < operation[0]?.machine_data.length; i++) {
        tempMachines = [...machines];
        console.log("tempMachines", tempMachines);
        console.log("index", index);
        // Check if the machine with the given UUID already exists in tempMachines
        if (tempMachines[index][operIndex].data.length > 0) {
          const machineExists = tempMachines[index][operIndex].data[0]?.machine.some(
            (existingMachine) =>
              existingMachine.uuid ===
              operation[0]?.machine_data[i]?.machine[0]?.uuid
          );
          console.log("machineExists", machineExists);

          // If the machine doesn't exist, push it to tempMachines
          if (!machineExists) {
            tempMachines[index][operIndex].data.push(operation[0].machine_data[i]);
          }
        }
      }
      console.log("tempMachines", tempMachines);
      // setMachines(tempMachines);
      // }
    }

    // const currentActiveRoute = routeData.uniqueRoutesWithMachines.find((val) => val.routes_id == key);
    // console.log("currentActiveRoute", currentActiveRoute);

  }

  return (
    <React.Fragment>
      <ToastContainer />
      <DrawingGallary
        galVisible={galVisible}
        setGalVisible={setGalVisible}
        setDrawingFiles={setDrawingFiles}
        drawingFiles={drawingFiles}
        currIndex={currIndex}
        operIndex={operIndex}
        fetchImages={fetchImages}
        images={images}
        action={action}
        operList={operList}
        fields={fields}
        fullFormData={fullFormData}
      />

      {machineModal.state ? (
        <MachineModel
          machineList={machineList}
          machineModal={machineModal}
          setMachineModal={setMachineModal}
          machines={machines}
          setMachines={setMachines}
          currIndex={currIndex}
          operIndex={operIndex}
          fields={fields}
          getchProdConfig={getchProdConfig}
          propsData={main_obj.prod_overview}
        />
      ) : null}



      {fields.length > 0 ?
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => {
            console.log("k", k);
            // useState([[{ img: [] }]]);

            setDrawingFiles(prevDrawingFiles => [...prevDrawingFiles, [{ img: [] }]]);
            setKey(k);


          }}
          className="mb-3"
        >
          {fields.map((val, index) => (
            <Tab eventKey={val.uuid} title={val.routesType} key={index}>
              <div className="prod_configurator card shadow-sm rounded-0 p-2">
                <div className="card-body">
                  <div className="d-flex justify-content-start">
                    <p className="pc_card_title roboto-bold">
                      {constLabel?.lbl_product_specification
                        ? constLabel.lbl_product_specification
                        : "Operation Sequence"}
                    </p>
                  </div>


                  {val.operationList?.map((operation, operIndex) => (

                    <React.Fragment>
                      <div className="col-12 row me-0 me-md-1 vf-danger">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <label className="lbl-style col-form-label roboto-bold">
                              {constLabel?.lbl_product_name
                                ? constLabel.lbl_product_name
                                : "Product Name"}
                            </label>

                            <label
                              className="col-form-label roboto-bold ms-0 ms-md-2"
                              style={{ color: "#EEAE2D" }}
                            >
                              {main_obj && main_obj?.prod_overview?.productName}
                            </label>
                          </div>
                          <div>
                            <FontAwesomeIcon
                              style={{
                                color: "black",
                                cursor: "pointer",
                              }}
                              icon={faClose}
                              size="lg"
                              onClick={() => {


                                if (fields[index].operationList[operIndex].po_uuid !== undefined) {
                                  delProdOper(index, operIndex);
                                } else {

                                  console.log("index", index);
                                  console.log("operIndex", operIndex);
                                  console.log("fields", fields);

                                  // const currRoute = getValues(`routes`);
                                  const currRoute = [...fields];
                                  currRoute[index].operationList.splice(operIndex, 1)
                                  console.log("currRoute", currRoute);



                                  // If user is not deleting the latest operation in route
                                  if (currRoute[index].operationList[operIndex]) {
                                    console.log(" currRoute[index].operationList[operIndex]",
                                      currRoute[index].operationList[operIndex]);

                                    setValue(`routes.${index}.operationList.${operIndex}.operation`,
                                      currRoute[index].operationList[operIndex].operation);

                                    setValue(`routes.${index}.operationList.${operIndex}.oprcost`,
                                      currRoute[index].operationList[operIndex].oprcost);

                                    setValue(`routes.${index}.operationList.${operIndex}.route`,
                                      currRoute[index].operationList[operIndex].route);

                                    setValue(`routes.${index}.operationList.${operIndex}.man_hour_cost`,
                                      currRoute[index].operationList[operIndex].man_hour_cost); 

                                    setValue(`routes.${index}.operationList.${operIndex}.job_weight`,
                                      currRoute[index].operationList[operIndex].job_weight);

                                    setValue(`routes.${index}.operationList.${operIndex}.job_length`,
                                      currRoute[index].operationList[operIndex].job_length);

                                    setValue(`routes.${index}.operationList.${operIndex}.sequenceNo`,
                                      currRoute[index].operationList[operIndex].sequenceNo);

                                    setValue(`routes.${index}.operationList.${operIndex}.serial_number_tracking`,
                                      currRoute[index].operationList[operIndex].serial_number_tracking);
                                  } else {
                                    // If user is deleting the latest operation in route
                                    replace(currRoute);
                                  }


                                  let tempMachines = [...machines];
                                  tempMachines[index].splice(operIndex, 1);
                                  setMachines(tempMachines);

                                  setoperIndex((prevOperIndex) => parseInt(prevOperIndex) - 1);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3 mb-5">
                        <div className="col-md-4">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="lbl-style roboto-bold">
                                  {constLabel?.lbl_sequence_no
                                    ? constLabel.lbl_sequence_no
                                    : "Sequence No."}
                                </label>
                                <input
                                  className={
                                    errors.routes?.[index]?.operationList?.[operIndex]?.sequenceNo
                                      ? "form-control mt-2 is-invalid"
                                      : "form-control mt-2 "
                                  }
                                  type="text"

                                  {...register(`routes.${index}.operationList.${operIndex}.sequenceNo`)}
                                  // {...register(`items[${index}].sequenceNo`)}
                                  placeholder="Enter sequence no."
                                />

                                {errors.routes?.[index]?.operationList?.[operIndex]?.sequenceNo && (
                                  <span className="text-danger err-msg">
                                    {errors.routes?.[index]?.operationList?.[operIndex]?.sequenceNo.message}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="col-md-8">
                              <div className="form-group">
                                <label className="lbl-style roboto-bold">
                                  {constLabel?.lbl_operation_name
                                    ? constLabel.lbl_operation_name
                                    : "Operation Name"}
                                </label>
                                <Controller
                                  name={`routes.${index}.operationList.${operIndex}.operation`}
                                  control={control}
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <Typeahead
                                      className="mt-2"
                                      id="basic-typeahead"
                                      labelKey="operationName"
                                      multiple={false}
                                      options={operationList.data}
                                      isLoading={false}
                                      placeholder="Select operation"
                                      // onChange={onChange}
                                      onChange={(selected) => {
                                        // onChange(selected);
                                        console.log("selected", selected);
                                        setValue(`routes.${index}.operationList.${operIndex}.operation`, selected);
                                        setValue(`routes.${index}.operationList.${operIndex}.man_hour_cost`, selected?.[0]?.man_hour_cost !== null ? selected?.[0]?.man_hour_cost : "" );
                                        machineConfig(selected, index, operIndex);
                                      }}
                                      onBlur={onBlur}
                                      selected={value || []}
                                      isInvalid={
                                        errors.routes?.[index]?.operationList?.[operIndex]?.operation
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                                />

                                {errors.routes?.[index]?.operationList?.[operIndex]?.operation && (
                                  <span className="text-danger err-msg">
                                    {errors.routes?.[index]?.operationList?.[operIndex]?.operation.message}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="lbl-style roboto-bold">
                                  {constLabel?.lbl_operation_cost
                                    ? constLabel.lbl_operation_cost
                                    : "Operation Cost "}{" "}
                                  (<span>&#8377;</span>)
                                </label>
                                <input
                                  className={
                                    errors.routes?.[index]?.operationList?.[operIndex]?.oprcost
                                      ? "form-control mt-2 is-invalid"
                                      : "form-control mt-2 "
                                  }
                                  type="text"
                                  // defaultValue={0}
                                  placeholder="Enter operation cost"
                                  {...register(`routes.${index}.operationList.${operIndex}.oprcost`)}
                                />

                                {errors.routes?.[index]?.operationList?.[operIndex]?.oprcost && (
                                  <span className="text-danger err-msg">
                                    {errors.routes?.[index]?.operationList?.[operIndex]?.oprcost.message}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="lbl-style roboto-bold">
                                  {constLabel?.lbl_route
                                    ? constLabel.lbl_route
                                    : "Route"}
                                </label>
                                <Controller
                                  name={`routes.${index}.operationList.${operIndex}.route`}
                                  control={control}
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <Typeahead
                                      className="mt-2"
                                      id="basic-typeahead"
                                      labelKey="routesType"
                                      multiple={false}
                                      options={[]}
                                      isLoading={false}
                                      // options={routesList.data}
                                      // isLoading={routesList.loading}
                                      placeholder="Select route"
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      selected={value || []}
                                      isInvalid={
                                        errors.routes?.[index]?.operationList?.[operIndex]?.route ? true : false
                                      }
                                      disabled
                                    />
                                  )}
                                />

                                {errors.routes?.[index]?.operationList?.[operIndex]?.route && (
                                  <span className="text-danger err-msg">
                                    {errors.routes?.[index]?.operationList?.[operIndex]?.route.message}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="lbl-style roboto-bold">
                                  {constLabel?.lbl_man_hour_cost
                                    ? constLabel.lbl_man_hour_cost
                                    : "Man Hour Cost"}{" "}
                                  (<span>&#8377;</span>)
                                </label>
                                <input
                                  className={
                                    errors.routes?.[index]?.operationList?.[operIndex]?.man_hour_cost
                                      ? "form-control mt-2 is-invalid"
                                      : "form-control mt-2 "
                                  }
                                  type="text"
                                  placeholder="Enter man hour cost"
                                  {...register(`routes.${index}.operationList.${operIndex}.man_hour_cost`)}
                                />

                                {errors.routes?.[index]?.operationList?.[operIndex]?.man_hour_cost && (
                                  <span className="text-danger err-msg">
                                    {errors.routes?.[index]?.operationList?.[operIndex]?.man_hour_cost.message}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="lbl-style roboto-bold">
                                  {constLabel?.lbl_job_weight
                                    ? constLabel.lbl_job_weight
                                    : "Job Weight"}{" "}(<span>&#13199;</span>)
                                </label>
                                <input
                                  className={
                                    errors.routes?.[index]?.operationList?.[operIndex]?.job_weight
                                      ? "form-control mt-2 is-invalid"
                                      : "form-control mt-2 "
                                  }
                                  type="text"
                                  placeholder="Enter job weight"
                                  {...register(`routes.${index}.operationList.${operIndex}.job_weight`)}
                                />

                                {errors.routes?.[index]?.operationList?.[operIndex]?.job_weight && (
                                  <span className="text-danger err-msg">
                                    {errors.routes?.[index]?.operationList?.[operIndex]?.job_weight.message}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-2 mt-2">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label className="lbl-style roboto-bold">
                                  {constLabel?.lbl_job_length
                                    ? constLabel.lbl_job_length
                                    : "Job Length"}{" "}(<span>m</span>)
                                </label>
                                <input
                                  className={
                                    errors.routes?.[index]?.operationList?.[operIndex]?.job_length
                                      ? "form-control mt-2 is-invalid"
                                      : "form-control mt-2 "
                                  }
                                  type="text"
                                  placeholder="Enter job length"
                                  {...register(`routes.${index}.operationList.${operIndex}.job_length`)}
                                />

                                {errors.routes?.[index]?.operationList?.[operIndex]?.job_length && (
                                  <span className="text-danger err-msg">
                                    {errors.routes?.[index]?.operationList?.[operIndex]?.job_length.message}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 machineDropdown mt-2">
                          <div className="d-flex">
                            <div>
                              <div className="mt-2">
                                <label className="invisible">Dropdown</label>
                              </div>

                              <button
                                className="btn btn-grey px-3"
                                onClick={() => {
                                  setCurrIndex(index);
                                  setoperIndex(operIndex);

                                  console.log("operIndex", operIndex);

                                  setMachineModal({
                                    ...machineModal,
                                    state: true,
                                    mode: "create",
                                  });
                                }}
                              >
                                <FontAwesomeIcon
                                  style={{
                                    color: "#fff",
                                  }}
                                  icon={faAdd}
                                  size="sm"
                                  className="me-2"
                                />
                                {constLabel?.lbl_add_machine
                                  ? constLabel.lbl_add_machine
                                  : "Add machine"}
                              </button>
                            </div>

                            <div className="ms-0 ms-md-3">
                              <div className="mt-2">
                                <label className="invisible">Dropdown</label>
                              </div>
                              <button
                                className="btn btn-yellow-wt px-3"
                                onClick={() => {
                                  setGalVisible(true);
                                  setCurrIndex(index);
                                  setoperIndex(operIndex);
                                  console.log("drawingFiles", drawingFiles);
                                  if (action == "update") {
                                    fetchImages(index, operIndex);
                                  }
                                }}
                              >
                                <FontAwesomeIcon
                                  style={{
                                    color: "#fff",
                                    marginRight: "5px",
                                  }}
                                  icon={faUpload}
                                  size="sm"
                                />{" "}
                                {constLabel?.lbl_drawing
                                  ? constLabel.lbl_drawing
                                  : "Drawing"}
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="form-check mt-3 ms-3">
                          {/* <Controller
                   name={`items[${index}].serial_number_tracking`}
                   control={control}
                   // defaultValue={false}
                   render={({
                     field: { onChange, onBlur, value, ref },
                   }) => (
                     <>
                       <input
                         className="form-check-input"
                         type="checkbox"
                         // value=""
                         id={"serialNumTracking" + index}
                         // {...field}
                         onChange={onChange}
                         onBlur={onBlur}
                         checked={value}
                       />
                       <label
                         className="form-check-label" htmlFor={"serialNumTracking" + index}
                         style={{ fontSize: '14px', color: '#344454' }}>
                         Serial number tracking
                       </label>
                     </>
                   )}
                 /> */}

                          <input
                            className="form-check-input"
                            type="checkbox"
                            // id={`serialNumTracking_${index}_${key}`}
                            {...register(`routes.${index}.operationList.${operIndex}.serial_number_tracking`)}
                          />
                          <label
                            className="form-check-label"
                            // htmlFor={`serialNumTracking_${index}_${key}`}
                            style={{ fontSize: "14px", color: "#344454" }}
                          >
                            Serial number tracking
                          </label>
                        </div>
                      </div>


                      {machines[index] && machines[index][operIndex] &&
                        machines[index][operIndex]?.data?.length > 0 ? (
                        <div className="pill-container">
                          {machines[index][operIndex]?.data?.map((mac, maci) => (
                            <div className="custom-pill" key={maci}>

                              <u
                                style={{ color: "blue" }}
                                onClick={() => {
                                  setCurrIndex(index);
                                  setoperIndex(operIndex);
                                  setMachineModal({
                                    ...machineModal,
                                    state: true,
                                    mode: "update",
                                    selMachine: mac,
                                    macInd: maci,
                                  });
                                }}
                              >
                                {mac.machine[0]?.machineName + " - " + mac.machine[0]?.serialNo + " - " + "(" + mac.cycleTime + ")"}
                              </u>

                              <FontAwesomeIcon
                                style={{
                                  color: "#fff",
                                  cursor: "pointer",
                                  marginLeft: "20px",
                                }}
                                icon={faClose}
                                size="lg"
                                onClick={() => {
                                  if (mac.uuid !== undefined) {
                                    removeMachine(mac);
                                  } else {
                                    removeMac(index, maci, operIndex);
                                  }
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      ) : null}

                      <hr className="my-4" />
                    </React.Fragment>
                  ))}


                  <div>
                    <span
                      onClick={() => {
                        console.log("index", index);
                        console.log("routesList", routesList);
                        console.log("key", key);

                        const currentSelectedRoute = routesList.data.find((val) => val.uuid == key);

                        const currRoute = getValues(`routes`);
                        const currRouteasd = getValues(`routes.${index}`);
                        console.log("currRoute", currRoute);
                        console.log("currRouteasd", currRouteasd);
                        const currLineItems = getValues(`routes.${index}.operationList`);
                        console.log("currLineItems", currLineItems);

                        let nxtSeq = "1";

                        if (currLineItems && currLineItems.length > 0) {
                          nxtSeq = Number(currLineItems[currLineItems.length - 1].sequenceNo) + 1;
                        }


                        console.log("nxtSeq", nxtSeq);

                        currLineItems.push({
                          sequenceNo: nxtSeq, operation: [], oprcost: "0",
                          route: [currentSelectedRoute], serial_number_tracking: false
                        });

                        currRoute[index].operationList = currLineItems;

                        console.log("currRoute", currRoute);
                        replace(currRoute);

                        setoperIndex((prevOperIndex) => parseInt(prevOperIndex) + 1);
                        // append({
                        //   operationList: [{
                        //     sequenceNo: nxtSeq, operation: [], oprcost: "0",
                        //     route: [currentSelectedRoute], serial_number_tracking: false
                        //   }]
                        // });
                        console.log("drawingFiles", drawingFiles);
                        let tempdimg = [...drawingFiles];
                        tempdimg[index].push({ img: [] });
                        console.log("tempdimg", tempdimg);
                        setDrawingFiles(tempdimg);

                        let tempMachines = [...machines];

                        tempMachines[index].push({ data: [] });
                        setMachines(tempMachines);
                        // setCurrIndex((prevCurr) => parseInt(prevCurr) + 1);
                      }}
                      className="mt-4 mb-0 text_gold roboto-bold cursor_pointer"
                      style={{ fontSize: "20px" }}
                    >
                      {"+"}{" "}
                      {constLabel?.lbl_add_sequence
                        ? constLabel?.lbl_add_sequence
                        : "Add Sequence"}
                    </span>
                  </div>

                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-grey px-4 me-2"
                      onClick={() => previousStep()}
                    >
                      {constLabel?.lbl_previous ? constLabel?.lbl_previous : "Previous"}
                    </button>
                    <button
                      className="btn button-primary px-4 me-2"
                      onClick={handleSubmit(onSubmit)}
                      disabled={btnDisable}
                    >
                      {constLabel?.lbl_save_continue
                        ? constLabel?.lbl_save_continue
                        : "Save & Continue"}
                    </button>

                    <button
                      className="btn btn-grey px-4 "
                      // onClick={() => props.setCurrentStep(props.currentStep + 1)}
                      onClick={() => {
                        nextStep();
                      }}
                    >
                      {constLabel?.lbl_skip ? constLabel?.lbl_skip : "Skip"}
                    </button>
                  </div>
                </div>
              </div>
            </Tab>
          ))}

        </Tabs>
        : <div
          className="alert alert-danger mb-0 text-center my-4"
          role="alert"
        >
          {"No product operation routines found !"}
        </div>}





      {/* <div className="prod_configurator card shadow-sm rounded-0 p-2">
        <div className="card-body">
          <div className="d-flex justify-content-start">
            <p className="pc_card_title roboto-bold">
              {constLabel?.lbl_product_specification
                ? constLabel.lbl_product_specification
                : "Operation Sequence"}
            </p>
          </div>

          {fields.length > 0 ? (
            fields.map(({ id }, index) => (
              <React.Fragment key={index}>
                <div className="col-12 row me-0 me-md-1 vf-danger">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <label className="lbl-style col-form-label roboto-bold">
                        {constLabel?.lbl_product_name
                          ? constLabel.lbl_product_name
                          : "Product Name"}
                      </label>

                      <label
                        className="col-form-label roboto-bold ms-0 ms-md-2"
                        style={{ color: "#EEAE2D" }}
                      >
                        {main_obj && main_obj?.prod_overview?.productName}
                      </label>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        style={{
                          color: "black",
                          cursor: "pointer",
                        }}
                        icon={faClose}
                        size="lg"
                        onClick={() => {
                          setCurrIndex((prevCurr) => parseInt(prevCurr) - 1);

                          if (fields[index].po_uuid !== undefined) {
                            delProdOper(index);
                          } else {
                            remove(index);
                            let tempMachines = [...machines];
                            tempMachines.splice(index, 1);
                            setMachines(tempMachines);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="row mt-3 mb-5">
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_sequence_no
                              ? constLabel.lbl_sequence_no
                              : "Sequence No."}
                          </label>
                          <input
                            className={
                              errors.items?.[index]?.sequenceNo
                                ? "form-control mt-2 is-invalid"
                                : "form-control mt-2 "
                            }
                            type="text"
                            {...register(`items[${index}].sequenceNo`)}
                            placeholder="Enter sequence no."
                          />

                          {errors.items?.[index]?.sequenceNo && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.sequenceNo.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-8">
                        <div className="form-group">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_operation_name
                              ? constLabel.lbl_operation_name
                              : "Operation Name"}
                          </label>
                          <Controller
                            name={`items[${index}].operation`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                className="mt-2"
                                id="basic-typeahead"
                                labelKey="operationName"
                                multiple={false}
                                options={operationList.data}
                                isLoading={false}
                                placeholder="Select operation"                                
                                onChange={(selected) => {
                                  onChange(selected);
                                  machineConfig(selected, index);
                                }}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={
                                  errors.items?.[index]?.operation
                                    ? true
                                    : false
                                }
                              />
                            )}
                          />

                          {errors.items?.[index]?.operation && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.operation.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_operation_cost
                              ? constLabel.lbl_operation_cost
                              : "Operation Cost "}
                            <span>&#8377;</span>
                          </label>
                          <input
                            className={
                              errors.items?.[index]?.oprcost
                                ? "form-control mt-2 is-invalid"
                                : "form-control mt-2 "
                            }
                            type="text"
                            defaultValue={0}
                            placeholder="Enter operation cost"
                            {...register(`items[${index}].oprcost`)}
                          />

                          {errors.items?.[index]?.oprcost && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.oprcost.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_route
                              ? constLabel.lbl_route
                              : "Route"}
                          </label>
                          <Controller
                            name={`items[${index}].route`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                className="mt-2"
                                id="basic-typeahead"
                                labelKey="routesType"
                                multiple={false}
                                options={routesList.data}
                                isLoading={routesList.loading}
                                placeholder="Select route"
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={
                                  errors.items?.[index]?.route ? true : false
                                }
                              />
                            )}
                          />

                          {errors.items?.[index]?.route && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.route.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 machineDropdown">
                    <div className="d-flex">
                      <div>
                        <div className="mt-2">
                          <label className="invisible">Dropdown</label>
                        </div>

                        <button
                          className="btn btn-grey px-3"
                          onClick={() => {
                            setCurrIndex(index);
                            setMachineModal({
                              ...machineModal,
                              state: true,
                              mode: "create",
                            });
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              color: "#fff",
                            }}
                            icon={faAdd}
                            size="sm"
                            className="me-2"
                          />
                          {constLabel?.lbl_add_machine
                            ? constLabel.lbl_add_machine
                            : "Add machine"}
                        </button>
                      </div>

                      <div className="ms-0 ms-md-3">
                        <div className="mt-2">
                          <label className="invisible">Dropdown</label>
                        </div>
                        <button
                          className="btn btn-yellow-wt px-3"
                          onClick={() => {
                            setGalVisible(true);
                            setCurrIndex(index);
                            if (action == "update") {
                              fetchImages(index);
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              color: "#fff",
                              marginRight: "5px",
                            }}
                            icon={faUpload}
                            size="sm"
                          />{" "}
                          {constLabel?.lbl_drawing
                            ? constLabel.lbl_drawing
                            : "Drawing"}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="form-check mt-3 ms-3">                  

                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={"serialNumTracking" + index}
                      {...register(`items[${index}].serial_number_tracking`)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={"serialNumTracking" + index}
                      style={{ fontSize: "14px", color: "#344454" }}
                    >
                      {constLabel?.lbl_serial_number_tracking
                            ? constLabel?.lbl_serial_number_tracking
                            : "Serial number tracking"}
                    </label>
                  </div>
                </div>

                {machines[index]?.data?.length > 0 ? (
                  <div className="pill-container">
                    {machines[index]?.data?.map((mac, maci) => (
                      <div className="custom-pill" key={maci}>
                        
                        <u
                          style={{ color: "blue" }}
                          onClick={() => {
                            setCurrIndex(index);
                            setMachineModal({
                              ...machineModal,
                              state: true,
                              mode: "update",
                              selMachine: mac,
                              macInd: maci,
                            });
                          }}
                        >
                          {mac.machine[0]?.machineName + " - " + mac.machine[0]?.serialNo + " - " + "(" + mac.cycleTime + ")"}
                        </u>

                        <FontAwesomeIcon
                          style={{
                            color: "#fff",
                            cursor: "pointer",
                            marginLeft: "20px",
                          }}
                          icon={faClose}
                          size="lg"
                          onClick={() => {
                            if (mac.uuid !== undefined) {
                              removeMachine(mac);
                            } else {
                              removeMac(index, maci);
                            }
                          }}
                        />
                      </div>
                    ))}
                  </div>
                ) : null}

                <hr className="my-4" />
              </React.Fragment>
            ))
          ) : (
            <div
              className="alert alert-danger mb-0 text-center my-4"
              role="alert"
            >
              {"No product operation routines found !"}
            </div>
          )}

          <div>
            <span
              onClick={() => {
                append({});
                let tempdimg = [...drawingFiles];
                tempdimg.push({ img: [] });
                setDrawingFiles(tempdimg);

                let tempMachines = [...machines];

                tempMachines.push({ data: [] });
                setMachines(tempMachines);
                setCurrIndex((prevCurr) => parseInt(prevCurr) + 1);
              }}
              className="mt-4 mb-0 text_gold roboto-bold cursor_pointer"
              style={{ fontSize: "20px" }}
            >
              {"+"}{" "}
              {constLabel?.lbl_add_sequence
                ? constLabel?.lbl_add_sequence
                : "Add Sequence"}
            </span>
          </div>

          <div className="d-flex justify-content-end">
           
            <button
              className="btn btn-grey px-4 me-2"
              onClick={() => previousStep()}
            >
              {constLabel?.lbl_previous ? constLabel?.lbl_previous : "Previous"}
            </button>
            <button
              className="btn button-primary px-4 me-2"
              onClick={handleSubmit(onSubmit)}
              disabled={btnDisable}
            >
              {constLabel?.lbl_save_continue
                ? constLabel?.lbl_save_continue
                : "Save & Continue"}
            </button>

            <button
              className="btn btn-grey px-4 "
              // onClick={() => props.setCurrentStep(props.currentStep + 1)}
              onClick={() => {
                nextStep();
              }}
            >
              {constLabel?.lbl_skip ? constLabel?.lbl_skip : "Skip"}
            </button>
          </div>
        </div>
      </div> */}
    </React.Fragment>
  );
}

export default ProductSpecification;
