import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "sweetalert2";
import {
  faBan,
  faPlus,
  faUpload,
  faFilter,
  faCog,
  faDownload,
  faObjectGroup,
  faTable,
  faCircle,
  faFileExcel,
  faFileExport,
} from "@fortawesome/free-solid-svg-icons";
import { Pagination, Spinner, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  Eye,
  Trash2,
  Copy,
  FileText,
  Edit2,
  Calendar,
  Image,
  Edit,
} from "react-feather";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  getSalesOrder,
  delete_WorkOrder,
  get_WorkOrder,
  getWorkOrderDataExcel,
  get_quick_view,
  quickViewExcelDownload,
} from "../../../api";
import {
  convertDateYYMMDD,
  excelReportDate,
  get_dispatch_quantity,
  getFinancialYearStartDate,
  getShortReadableId,
  setAccessPrivilage,
} from "../../../common/Functions/CommonFunctions";
import { Tooltip } from "react-tooltip";
import Multiselect from "multiselect-react-dropdown";
import {
  calMaxPage,
  calMaxPage_new,
  convertDate,
  getLabels,
} from "../../../common/Functions/CommonFunctions";
import { Work_Order_Col_Name } from "../../../common/Labels/labelConstant";
import { useSelector } from "react-redux";
import WOImageGallary from "./ImageGallary/WOImageGallary";
import List from "../../../assets/images/icons/project_menu/checklist.png";
import Slide from "../../../assets/images/icons/project_menu/collapse.png";
import ExcelJS from "exceljs";

function WorkOrder() {
  const [showLabel, setShowLabel] = useState(Work_Order_Col_Name);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [filterConfig, setFilterConfig] = useState({
    work_order_status: "",
    so_id: [],
    work_order: "",
    product_name: "",
  });
  const [startDate, setStartDate] = useState(getFinancialYearStartDate(new Date()));
  const [endDate, setEndDate] = useState(new Date());
  const [salesOrders, setSalesOrders] = useState({ data: [], loading: false });

  const [constLabel, setConstLabel] = useState({});
  const [lockBtn, setLockBtn] = useState(false);
  const [lockExcl, setLockExcl] = useState(false);
  const [eyeBtn, setEyeBtn] = useState(null);
  const [galVisible, setGalVisible] = useState(false);
  const [woData, setWOData] = useState([]);
  const [show, setShow] = useState(false);
  const [quickViewData, setQuickViewData] = useState({
    data: [],
    loading: false,
  });

  const [wo_uuid, setWO_UUID] = useState(null);

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );
  const [privilage] = useState(setAccessPrivilage(loginData, "Work Order"));

  let navigate = useNavigate();

  useEffect(() => {
    setConstLabel(getLabels("Production", "Work Order"));

    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchWorkOrders(page, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig, startDate, endDate]);

  useEffect(() => {
    fetchSalesOrder();
  }, []);

  function fetchSalesOrder() {
    setSalesOrders({ ...salesOrders, data: [], loading: true });

    getSalesOrder().then(
      (res) => {
        setSalesOrders({ ...salesOrders, data: res.data.data, loading: false });
      },
      (err) => {
        setSalesOrders({ ...salesOrders, data: [], loading: false });
      }
    );
  }

  function getPrvMonthDateFromToday() {
    const today = new Date();
    const endDate = new Date(today);
    endDate.setMonth(today.getMonth() - 1);
    return endDate;
  }

  function fetchWorkOrders(pg, epp) {
    setDataList({ ...dataList, data: [], loading: true });
    const payload = {
      entriesPerPage: epp,
      pageNo: pg,
      startDate: convertDateYYMMDD(new Date(startDate), "-"),
      endDate: convertDateYYMMDD(new Date(endDate), "-"),
    };

    if (filterConfig.so_id.length > 0) {
      payload.so_id = filterConfig.so_id?.[0]?.uuid;
    } else {
      delete payload.so_id;
    }

    if (filterConfig.work_order_status != "") {
      // payload.searchVal = filterConfig.search_val;
      payload.work_order_status = filterConfig.work_order_status.trim(); //Search by work order status

      // setSrNo(0)
    } else {
      delete payload.work_order_status;
    }

    if (filterConfig.work_order != "") {
      payload.work_order_no = filterConfig.work_order.trim(); //Search by work order no
    } else {
      delete payload.work_order_no;
    }

    if (filterConfig.product_name != "") {
      payload.product_name = filterConfig.product_name.trim();
    } else {
      delete payload.product_name;
    }

    get_WorkOrder(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          data: res.data.data,
          loading: false,
          totalRecords: res.data.total,
        });
        // setMaxPage(calMaxPage_new(parseInt(res.data.total), epp));

        setMaxPage(calMaxPage(res.data.total));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({ ...dataList, data: [], loading: false });

        // if(err.response.status == 400){
        //   toast.error(err.response.data.message, {
        //     autoClose: 2000,
        //   });
        //   setPriceRange({from: 0, to: 100});
        // }
      }
    );
  }

  //console.log("max",maxPage);
  const delWorkOrder = (val) => {
    // console.log("val",val);
    SweetAlert.fire({
      title: "Are you sure to delete ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        delete_WorkOrder({ workorder_uuid: val.uuid }).then(
          (res) => {
            toast.success("Work order deleted successfully", {
              autoClose: 2000,
            });
            if (dataList.data.length == 1 && page > 1) {
              fetchWorkOrders(page - 1, entriesPerPage);
            } else {
              fetchWorkOrders(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  function clearFilter() {
    let obj = {
      work_order_status: "",
      so_id: [],
      work_order: "",
      product_name: "",
    };
    setFilterConfig({ ...obj });
    fetchSalesOrder();
    setStartDate(getFinancialYearStartDate(new Date()));
    setEndDate(new Date());
  }

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  const handleFromDateChange = (date) => {
    if (endDate && date > endDate) {
      return;
    }
    setStartDate(date);
  };

  const handleToDateChange = (date) => {
    if (startDate && date < startDate) {
      return;
    }
    setEndDate(date);
  };

  async function goToDownload() {
    setLockBtn(true);
    // console.log("inside download excel", filterConfig);
    const payload = {
      startDate: convertDateYYMMDD(new Date(startDate), "-"),
      endDate: convertDateYYMMDD(new Date(endDate), "-"),
    };

    // await getProductionData(payload).then(
    await getWorkOrderDataExcel(payload).then(
      (res) => {
        // console.log("res", res);
        if (res) {
          // excel_download(res.data.data);
          // console.log("res", res);
          base64ToExcel(res.data);
        }
      },
      (err) => {
        // console.log("err", err);
        toast.error(err.message, {
          autoClose: 2000,
        });
        setLockBtn(false);
      }
    );
  }

  function base64ToExcel(response) {
    const { statusCode, headers, body, isBase64Encoded } = response;

    // Assume base64String contains the base64 representation of the Excel file
    const base64String = body; // Replace this with your base64 string

    // Convert the base64 string into a Uint8Array
    const bytes = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));

    // Create a Blob object from the Uint8Array
    const blob = new Blob([bytes], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create a link element with download attribute
    const link = document.createElement("a");
    link.href = url;
    const currentDate = new Date().toDateString().split(" ").join("_");
    link.download = `WorkOrderReport_${currentDate}.xlsx`;

    // Programmatically click on the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Cleanup: remove the link and revoke the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    setLockBtn(false);
  }

  async function goToDownload2(wo) {
    setLockExcl(true);
    // console.log("wo", wo);
    const payload = {
      work_order_id: wo.uuid,
    };

    await quickViewExcelDownload(payload).then(
      (res) => {
        if (res) {
          // console.log("quick view excel", res);
          base64ToExcel_quickView(res.data, getShortReadableId(wo.work_order_no));
        }
      },
      (err) => {
        toast.error(err.message, {
          autoClose: 2000,
        });
        setLockExcl(false);
      }
    );
  }

  function base64ToExcel_quickView(response, wo_number) {
    const { statusCode, headers, body, isBase64Encoded } = response;

    // Assume base64String contains the base64 representation of the Excel file
    const base64String = body; // Replace this with your base64 string

    // Convert the base64 string into a Uint8Array
    const bytes = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));

    // Create a Blob object from the Uint8Array
    const blob = new Blob([bytes], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create a link element with download attribute
    const link = document.createElement("a");
    link.href = url;
    const currentDate = new Date().toDateString().split(" ").join("_");
    link.download = `WO_Current_Status_Report_${wo_number}-${currentDate}.xlsx`;

    // Programmatically click on the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Cleanup: remove the link and revoke the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    setLockExcl(false);
  }

  async function quickViewModal(wo_uuid) {
    setQuickViewData({ ...quickViewData, data: [], loading: true });
    await get_quick_view({ work_order_id: wo_uuid }).then(
      (res) => {
        // console.log("quick view res", res);
        setQuickViewData({
          ...quickViewData,
          data: res.data.data,
          loading: false,
        });
        setShow(true);
        setEyeBtn(null);
      },
      (err) => {
        console.log("err", err);
        setQuickViewData({ ...quickViewData, data: [], loading: false });
        setEyeBtn(null);
      }
    );
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
    }
  }

  const excel_download = async (excel_data, wo) => {
    setLockExcl(true);
    const currentDate = new Date().toDateString().split(" ").join("_");
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    // Adding the title in the center of the sheet
    const title = `Work Order Status Report - ${excelReportDate(new Date())}`;
    worksheet.mergeCells('C2:E2'); // Merge cells from C2 to E2 for title
    const titleCell = worksheet.getCell('C2');
    titleCell.value = title;
    titleCell.alignment = { horizontal: 'center' }; // Center alignment
    titleCell.font = { size: 16, bold: true }; // Make the title bold and larger
    titleCell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    // Adding additional rows for product details
    worksheet.addRow([]); // Empty row
    const productDetails = [
      ["Product Name", excel_data[0]?.product_name],
      ["Work Order No", getShortReadableId(excel_data[0]?.work_order_no)],
      ["Qty", excel_data[0]?.work_order_qty],
      ["Status", excel_data[0]?.work_order_status]
    ];
    
    // Loop through the product details and add rows
    productDetails.forEach(([label, value]) => {
      const row = worksheet.addRow([label, value]);
    
      // Bold and center the value
      row.getCell(2).font = { bold: true }; // Bold the second cell
      row.getCell(2).alignment = { horizontal: 'center' }; // Center the value
    });
    worksheet.addRow([]); // Another empty row for spacing

    const keyTitleMap = [
      "Sequence No",
      "Operation Name",
      "Planned Qty",
      "Produced Qty",
      "Scrap Qty",
      "Production Efficiency",
      "Actual Cycle Time",
    ];

    const flattenedData = excel_data[0]?.operation.map((item, index) => {
      return [
        item.sequenceNo,
        item.operationname == "" || item.operationname == null ? "NA" : item.operationname,
        // item.planned_quantity !== "" || item.planned_quantity !== null ? item.planned_quantity : 0,
        item.planned_quantity,
        item.produced_quantity,
        item.scrap_weight,
        item.avg_efficiency,
        item.avg_actual_cycle_time + " " + "mins",
      ];
    });

    const data = [keyTitleMap, ...flattenedData];
    //

    // Add data to the worksheet
    worksheet.addRows(data);

    // Adjust cell width based on string length
    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const length = cell.value ? String(cell.value).length : 10;
        if (length > maxLength) {
          maxLength = length;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2; // Set a minimum width
    });

    // Set background color for the first row
    worksheet.getRow(9).eachCell({ includeEmpty: true }, (cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "3081D0" }, // Yellow background color
      };
      cell.font = {
        color: { argb: "ffffff" },
      };
    });

    // Save the workbook to a file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);

    // Create a link and trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = `Work Order Status Report - ${getShortReadableId(wo.work_order_no)}-${excelReportDate(new Date())}.xlsx`;
    document.body.appendChild(link);
    link.click();

    setLockExcl(false);
    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      {privilage.read && (
        <div id="mySidebar" className="customsidebar ">
          <a className="closebtn" onClick={() => toggleNav()}>
            ×
          </a>
          <WOImageGallary
            setGalVisible={setGalVisible}
            galVisible={galVisible}
            woData={woData}
            page={page}
            entriesPerPage={entriesPerPage}
            fetchWorkOrders={fetchWorkOrders}
          />
          <div className=" content">
            <div className="filter row">
              <label
                className="filterLabel mb-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_selectDateRange
                  ? constLabel.lbl_selectDateRange
                  : "Select Date Range"}
              </label>
              <div className="fromDate col-12 col-md-6">
                <DatePicker
                  id="fromDate"
                  className="form-control form-control-sm"
                  selected={startDate}
                  showYearDropdown
                  scrollableMonthYearDropdown
                  dateFormat="dd/MM/yyyy"
                  placeholderText="From Date"
                  // onChange={(date) => setStartDate(date)}
                  onChange={(date) => handleFromDateChange(date)}
                  maxDate={new Date()}
                />
              </div>

              <div className="toDate col-12 col-md-6">
                <DatePicker
                  id="toDate"
                  className="form-control form-control-sm"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  selected={endDate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="To Date"
                  // onChange={(date) => setEndDate(date)}
                  onChange={(date) => handleToDateChange(date)}
                  maxDate={new Date()}
                />
              </div>
            </div>

            <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {"Select Sales Order"}
              </label>

              <Typeahead
                id="basic-typeahead-single"
                labelKey="so_id"
                name="so_id"
                multiple={false}
                options={salesOrders.data}
                isLoading={salesOrders.loading}
                selected={filterConfig.so_id}
                onChange={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, so_id: val });
                  } else {
                    setFilterConfig({ ...filterConfig, so_id: [] });
                  }
                }}
                placeholder="Search by sales order"
              />
            </div>

            <div className="mt-2">
              <label
                className="filterLabel mb-1 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_status ? constLabel?.lbl_status : "Status"}
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by work order status"
                // value={filterConfig.work_order_status != ""  ? filterConfig.work_order_status : ""}
                value={filterConfig.work_order_status}
                onChange={(val) => {
                  const inputValue = val.target.value;
                  if (!inputValue.trim()) {
                    // Input contains only whitespace, don't trigger the search.
                    if (filterConfig.work_order_status.length == 1) {
                      setFilterConfig({
                        ...filterConfig,
                        work_order_status: "",
                      });
                    }
                    if (inputValue.length == 0) {
                      setFilterConfig({
                        ...filterConfig,
                        work_order_status: "",
                      });
                    }
                  } else {
                    setFilterConfig({
                      ...filterConfig,
                      work_order_status: inputValue,
                    });
                  }
                }}
              />
            </div>

            <div className="mt-2">
              <label
                className="filterLabel mb-1 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_work_order_no
                  ? constLabel?.lbl_work_order_no
                  : "Work Order No"}
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by work order no"
                value={filterConfig.work_order}
                onChange={(val) => {
                  const inputValue = val.target.value;
                  if (!inputValue.trim()) {
                    // Input contains only whitespace, don't trigger the search.
                    if (filterConfig.work_order.length == 1) {
                      setFilterConfig({ ...filterConfig, work_order: "" });
                    }

                    if (inputValue.length == 0) {
                      setFilterConfig({ ...filterConfig, work_order: "" });
                    }
                  } else {
                    setFilterConfig({
                      ...filterConfig,
                      work_order: inputValue,
                    });
                  }
                }}
              />
            </div>

            <div className="mt-2">
              <label
                className="filterLabel mb-1 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_product_name
                  ? constLabel?.lbl_product_name
                  : "Product Name"}
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by product name"
                value={filterConfig.product_name}
                onChange={(val) => {
                  const inputValue = val.target.value;
                  if (!inputValue.trim()) {
                    // Input contains only whitespace, don't trigger the search.
                    if (filterConfig.product_name.length == 1) {
                      setFilterConfig({ ...filterConfig, product_name: "" });
                    }

                    if (inputValue.length == 0) {
                      setFilterConfig({ ...filterConfig, product_name: "" });
                    }
                  } else {
                    setFilterConfig({
                      ...filterConfig,
                      product_name: inputValue,
                    });
                  }
                }}
              />
            </div>

            <div className="my-3 d-flex justify-content-end">
              <button
                onClick={() => clearFilter()}
                className="btn btn-sm btn-yellow "
              >
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                {constLabel?.lbl_clear_filter
                  ? constLabel.lbl_clear_filter
                  : "Clear Filter"}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="min-vh-100" id="root_div_main">
        <div className="content-wrapper">
          <div className="card border-0">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="d-md-flex justify-content-start col-12 col-md-6">
                  <Tooltip id={"open-tooltip"} place="top" />
                  <img
                    src={Slide}
                    height="32"
                    width="32"
                    className="cursor_pointer my-1 me-2"
                    onClick={() => toggleNav()}
                    data-tooltip-id={"open-tooltip"}
                    data-tooltip-content={"Sidebar"}
                  />
                  <h1 className="bold">
                    {constLabel?.lbl_work_order
                      ? constLabel.lbl_work_order
                      : "Work Order"}
                  </h1>
                </div>

                <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                  {privilage.report && (
                    <button
                      className="btn btn-light-grey me-0 me-md-2 mt-2 mt-md-0"
                      disabled={lockBtn}
                      onClick={() => goToDownload()}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff" }}
                        icon={faDownload}
                        size="sm"
                      />{" "}
                      {constLabel?.lbl_work_order_report
                        ? constLabel.lbl_work_order_report
                        : "Work Order Report"}{" "}
                      {lockBtn ? (
                        <Spinner animation="border" size="sm" />
                      ) : null}{" "}
                    </button>
                  )}

                  {privilage.write && (
                    <button
                      className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                      onClick={() =>
                        navigate(
                          `/pcms/create-work-order`
                          // { state: dataList.data[0] }
                        )
                      }
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff" }}
                        icon={faPlus}
                        size="sm"
                      />{" "}
                      {constLabel?.lbl_create_work_order
                        ? constLabel.lbl_create_work_order
                        : "Create Work Order"}
                    </button>
                  )}

                  <Tooltip id={"show-tooltip"} place="top" />
                  <div
                    className="dropdown"
                    data-tooltip-id={"show-tooltip"}
                    data-tooltip-content={"Show / Hide Columns"}
                  >
                    <div
                      className="d-md-flex justify-content-start align-items-center"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {/* <FontAwesomeIcon icon={faListCheck} size="lg" /> */}
                      {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                      <img
                        src={List}
                        height="32"
                        width="32"
                        className="cursor_pointer"
                      />
                    </div>

                    <ul className="dropdown-menu px-2">
                      {showLabel !== undefined && showLabel.length > 0
                        ? showLabel.map((val, ind, arr) => (
                            <li key={ind}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{ width: "20px", height: "20px" }}
                                  onChange={() => handleClick(ind)}
                                  value=""
                                  checked={val.flag}
                                />
                                <label
                                  className=""
                                  style={{ fontSize: "13px" }}
                                  htmlFor="flexCheckDefault"
                                >
                                  {val.label}
                                </label>
                              </div>
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="mt-3">
                {dataList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : dataList.data.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead className="table-grey roboto-bold">
                          <tr className="">
                            <th scope="col">{"Sr.No"}</th>
                            {showLabel.map((v, i) =>
                              v.label === "Sales Order" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_sales_order
                                    ? constLabel.lbl_sales_order
                                    : "Sales Order"}
                                </th>
                              ) : v.label === "Work Order No" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_work_order_no
                                    ? constLabel.lbl_work_order_no
                                    : "Work Order No"}
                                </th>
                              ) : v.label === "Start Date" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_start_date
                                    ? constLabel.lbl_start_date
                                    : "Start Date"}
                                </th>
                              ) : v.label === "End Date" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_end_date
                                    ? constLabel.lbl_end_date
                                    : "End Date"}
                                </th>
                              ) : v.label === "Product Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_product_name
                                    ? constLabel.lbl_product_name
                                    : "Product Name"}
                                </th>
                              ) : v.label === "OEM Part No" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_oem_part_no
                                    ? constLabel.lbl_oem_part_no
                                    : "OEM Part No"}
                                </th>
                              ) : v.label === "Quantity" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_quantity
                                    ? constLabel.lbl_quantity
                                    : "Quantity"}
                                </th>
                              ) : v.label === "NC Count" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_nc_count
                                    ? constLabel.lbl_nc_count
                                    : "NC Count"}
                                </th>
                              ) : v.label === "Final Inspection Qty" &&
                                v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_final_inspection_qty
                                    ? constLabel.lbl_final_inspection_qty
                                    : "Final Inspection Qty"}
                                </th>
                              ) : v.label === "Dispatched Qty" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_dispatched_qty
                                    ? constLabel.lbl_dispatched_qty
                                    : "Dispatched Qty"}
                                </th>
                              ) : v.label === "Status" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_status
                                    ? constLabel.lbl_status
                                    : "Status"}
                                </th>
                              ) : v.label === "Unit" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_unit
                                    ? constLabel.lbl_unit
                                    : "Unit"}
                                </th>
                              ) : // v.label === "Lot No" && v.flag ? (<th key={i} scope="col">{constLabel?.lbl_lot_no ? constLabel.lbl_lot_no : "Lot No"}</th>) :
                              v.label === "Job From" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_job_from
                                    ? constLabel.lbl_job_from
                                    : "Job From"}
                                </th>
                              ) : v.label === "Job To" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_job_to
                                    ? constLabel.lbl_job_to
                                    : "Job To"}
                                </th>
                              ) : v.label === "Remark" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_remark
                                    ? constLabel.lbl_remark
                                    : "Remark"}
                                </th>
                              ) : null
                            )}
                            <th scope="col" width="15%">
                              {"Action"}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="roboto">
                          {dataList.data.map((val, ind) => (
                            <tr key={ind}>
                              <td>{parseInt(ind) + 1 + parseInt(srNo)}</td>
                              {showLabel.map((item, idx) =>
                                item.label === "Sales Order" && item.flag ? (
                                  <td key={idx}>
                                    {val.so_details &&
                                    val.so_details !== "" &&
                                    val.so_details !== null
                                      ? val.so_details?.so_id
                                      : "NA"}
                                  </td>
                                ) : // item.label === "Work Order No" && item.flag ? (<td key={idx}>{val.work_order_no != "" && val.work_order_no !== null
                                // ? val.work_order_no : "NA"}</td>) :
                                item.label === "Work Order No" && item.flag ? (
                                  <td key={idx}>
                                    {val.work_order_no && val.work_order_status === "Released" ? (
                                      <a
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          navigate(`/pcms/job-card-board`, {
                                            state: val,
                                          });
                                        }}
                                      >
                                        {getShortReadableId(val.work_order_no)}
                                      </a>
                                    ) : (
                                      val.work_order_no ? getShortReadableId(val.work_order_no) : "N/A"
                                    )}
                                  </td>
                                ) : item.label === "Start Date" && item.flag ? (
                                  <td key={idx}>
                                    {val.start_date != "" &&
                                    val.start_date !== null
                                      ? convertDate(val.start_date, "-")
                                      : "NA"}
                                  </td>
                                ) : item.label === "End Date" && item.flag ? (
                                  <td key={idx}>
                                    {val.end_date != "" && val.end_date !== null
                                      ? convertDate(val.end_date, "-")
                                      : "NA"}
                                  </td>
                                ) : item.label === "Product Name" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.product_name != "" &&
                                    val.product_name !== null
                                      ? val.product_name
                                      : "NA"}
                                  </td>
                                ) : item.label === "OEM Part No" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.part_no != "" && val.part_no !== null
                                      ? val.part_no
                                      : "NA"}
                                  </td>
                                ) : item.label === "Quantity" && item.flag ? (
                                  <td key={idx}>
                                    {val.quantity != "" && val.quantity !== null
                                      ? val.quantity
                                      : "NA"}
                                  </td>
                                ) : item.label === "NC Count" && item.flag ? (
                                  <td key={idx}>
                                    {val.work_order &&
                                    val.work_order.length != null
                                      ? val.work_order.length
                                      : "NA"}
                                  </td>
                                ) : item.label === "Final Inspection Qty" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.actual_quantity != "" &&
                                    val.actual_quantity != null
                                      ? val.actual_quantity
                                      : "NA"}
                                  </td>
                                ) : item.label === "Dispatched Qty" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.work_order_details &&
                                    val.work_order_details.length != null
                                      ? get_dispatch_quantity(
                                          val.work_order_details
                                        )
                                      : "NA"}
                                  </td>
                                ) : item.label === "Status" && item.flag ? (
                                  <td key={idx}>
                                    {val.work_order_status != "" &&
                                    val.work_order_status != null
                                      ? val.work_order_status
                                      : "NA"}
                                  </td>
                                ) : item.label === "Unit" && item.flag ? (
                                  <td key={idx}>
                                    {val.unit != "" && val.unit != null
                                      ? val.unit
                                      : "NA"}
                                  </td>
                                ) : // item.label === "Lot No" && item.flag ? (<td key={idx}>{val.lot_no != "" && val.lot_no != null ? val.lot_no : "NA" }</td>) :
                                item.label === "Job From" && item.flag ? (
                                  <td key={idx}>
                                    {val.serial_number_start != "" &&
                                    val.serial_number_start != null
                                      ? val.serial_number_start
                                      : "NA"}
                                  </td>
                                ) : item.label === "Job To" && item.flag ? (
                                  <td key={idx}>
                                    {val.serial_number_end != "" &&
                                    val.serial_number_end != null
                                      ? val.serial_number_end
                                      : "NA"}
                                  </td>
                                ) : item.label === "Remark" && item.flag ? (
                                  <td key={idx}>
                                    {val.remark != "" && val.remark != null
                                      ? val.remark
                                      : "NA"}
                                  </td>
                                ) : null
                              )}
                              <td>
                                {privilage.write && (
                                  <>
                                    <Tooltip
                                      id={"edit-tooltip" + ind}
                                      place="top"
                                    />
                                    <Edit
                                      data-tooltip-id={"edit-tooltip" + ind}
                                      data-tooltip-content={
                                        constLabel?.lbl_edit_work_order
                                          ? constLabel.lbl_edit_work_order
                                          : "Edit Work Order"
                                      }
                                      className="menu_icon_grey cursor_pointer me-2"
                                      size={20}
                                      onClick={() =>
                                        navigate("/pcms/update-work-order", {
                                          state: val,
                                        })
                                      }
                                    />
                                  </>
                                )}

                                {privilage.read && (
                                  <>
                                    <Tooltip
                                      id={"view-tooltip" + ind}
                                      place="top"
                                    />
                                    {/* <Eye
                                    data-tooltip-id={"view-tooltip" + ind}
                                    data-tooltip-content={
                                      constLabel?.lbl_quick_view
                                        ? constLabel.lbl_quick_view
                                        : "Quick View"
                                    }
                                    className="menu_icon_grey cursor_pointer me-2"
                                    size={20}
                                    disabled={eyeBtn == true ? true : false}
                                    onClick={() => {
                                      quickViewModal(val.uuid);
                                    }}
                                  /> */}
                                    {eyeBtn == ind ? (
                                      <Spinner
                                        animation="border"
                                        size="sm"
                                        className="me-2"
                                      />
                                    ) : (
                                      <Eye
                                        data-tooltip-id={"view-tooltip" + ind}
                                        data-tooltip-content={
                                          constLabel?.lbl_quick_view
                                            ? constLabel.lbl_quick_view
                                            : "Quick View"
                                        }
                                        className="menu_icon_grey cursor_pointer me-2"
                                        size={20}
                                        onClick={() => {
                                          quickViewModal(val.uuid);
                                          setEyeBtn(ind);
                                          setWO_UUID(val);
                                        }}
                                      />
                                    )}
                                  </>
                                )}

                                {privilage.write && (
                                  <>
                                    <Tooltip
                                      id={"image-tooltip" + ind}
                                      place="top"
                                    />
                                    <Image
                                      data-tooltip-id={"image-tooltip" + ind}
                                      data-tooltip-content={
                                        constLabel?.lbl_image_gallary
                                          ? constLabel.lbl_image_gallary
                                          : "Image Gallary"
                                      }
                                      className="menu_icon_grey cursor_pointer me-2"
                                      size={20}
                                      onClick={() => {
                                        setGalVisible(true);
                                        setWOData(val);
                                      }}
                                    />
                                  </>
                                )}

                                {privilage.delete && (
                                  <>
                                    <Tooltip
                                      id={"delete-tooltip" + ind}
                                      place="top"
                                    />
                                    <Trash2
                                      data-tooltip-id={"delete-tooltip" + ind}
                                      data-tooltip-content={
                                        constLabel?.lbl_delete_work_order
                                          ? constLabel.lbl_delete_work_order
                                          : "Delete Work Order"
                                      }
                                      className="menu_icon_red cursor_pointer"
                                      size={20}
                                      onClick={() => delWorkOrder(val)}
                                    />
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                      <p className="mb-0">
                        Show
                        <select
                          className="mx-1"
                          defaultValue={entriesPerPage}
                          onChange={(e) => {
                            setEntriesPerPage(e.target.value);
                            fetchWorkOrders(1, e.target.value);
                          }}
                        >
                          <option value={"10"}>10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        Entries
                      </p>
                      <p className="mb-0">{`Showing ${
                        parseInt(srNo) + 1
                      } to ${Math.min(
                        parseInt(entriesPerPage) + parseInt(srNo),
                        dataList.totalRecords
                      )} of ${dataList.totalRecords} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchWorkOrders(
                              page - 1,
                              entriesPerPage,
                              parseInt(srNo) - parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) =>
                              page - 1 == 1
                                ? 0
                                : prevC - parseInt(entriesPerPage)
                            );
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage ||
                            maxPage === 0 ||
                            entriesPerPage > dataList.data.length
                              ? true
                              : false
                          }
                          onClick={() => {
                            fetchWorkOrders(
                              page + 1,
                              entriesPerPage,
                              parseInt(srNo) + parseInt(entriesPerPage)
                            );
                            setSrNo(
                              (prevC) => prevC + parseInt(entriesPerPage)
                            );
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  </>
                ) : (
                  <div
                    className="alert alert-danger mb-0 text-center my-4"
                    role="alert"
                  >
                    {"No Work Orders Found !"}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)} size="xl">
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {quickViewData.data &&
              getShortReadableId(quickViewData.data?.[0]?.work_order_no)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-end align-items-center">
            <Tooltip id={"export-tooltip"} place="top" />
            <button
              className="btn btn-light-grey btn-sm me-0 me-md-2 my-2 mt-md-0"
              disabled={lockExcl}
              // onClick={() => goToDownload2(wo_uuid)}
              onClick={() => excel_download(quickViewData.data, wo_uuid)}
              data-tooltip-id={"export-tooltip"}
              data-tooltip-content={
                constLabel?.lbl_quick_view_excel
                  ? constLabel.lbl_quick_view_excel
                  : "Quick View Excel"
              }
            >
              <FontAwesomeIcon
                style={{ color: "#fff" }}
                icon={faFileExcel}
                size="sm"
              />{" "}
              {lockExcl ? <Spinner animation="border" size="sm" /> : null}{" "}
            </button>
          </div>
          {quickViewData.loading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : quickViewData.data?.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead className="table-grey roboto-bold">
                  <tr className="">
                    <th scope="col">{"Sr No"}</th>
                    <th scope="col">{"Operation"}</th>
                    <th scope="col">{"Avg. Actual Cycle Time"}</th>
                    <th scope="col">{"Total Produced Qty"}</th>
                    <th scope="col">{"NC Count"}</th>
                    <th scope="col">{"Scrap Weight"}{" "}{"(KG)"}</th>
                  </tr>
                </thead>
                <tbody className="roboto">
                  {quickViewData.data?.[0]?.operation?.map((val, i) => (
                    <tr key={i}>
                      <td style={{ verticalAlign: "middle" }}>
                        {i+1}
                      </td>
                      <td>
                        {val?.operationname !== "" &&
                        val?.operationname !== null
                          ? val?.operationname
                          : "NA"}
                      </td>
                      <td className="d-flex justify-content-between">
                        {val?.avg_actual_cycle_time !== "" &&
                        val?.avg_actual_cycle_time !== null &&
                        val?.avg_efficiency !== "" &&
                        val?.avg_efficiency !== null
                          ? val?.avg_actual_cycle_time + " " + "mins"
                          : "NA"}
                        <FontAwesomeIcon
                          icon={faCircle}
                          size={18}
                          className={`${
                            val.avg_efficiency >= 91
                              ? "ind_green"
                              : val.avg_efficiency >= 81 &&
                                val.avg_efficiency <= 90
                              ? "ind_orange"
                              : "ind_red"
                          } cursor_pointer`}
                          data-tooltip-id={"indicator-tooltip"}
                        />
                        <Tooltip
                          id={"indicator-tooltip"}
                          place="top"
                          effect="solid"
                        >
                          <div className="bold">
                            Good -{" "}
                            <FontAwesomeIcon
                              icon={faCircle}
                              size={12}
                              className="ind_green"
                            />{" "}
                            - 91 and above
                          </div>
                          <div className="bold">
                            Avg -{" "}
                            <FontAwesomeIcon
                              icon={faCircle}
                              size={12}
                              className="ind_orange"
                            />{" "}
                            - 81 to 90
                          </div>
                          <div className="bold">
                            Bad -{" "}
                            <FontAwesomeIcon
                              icon={faCircle}
                              size={12}
                              className="ind_red"
                            />{" "}
                            - 80 and below
                          </div>
                        </Tooltip>
                      </td>
                      <td>
                        {val?.produced_quantity !== "" &&
                        val?.produced_quantity !== null
                          ? val?.produced_quantity
                          : "NA"}
                      </td>
                      <td>
                        {val?.nc_count !== "" && val?.nc_count !== null
                          ? val?.nc_count
                          : "NA"}
                      </td>
                      <td>
                        {val?.scrap_weight !== "" && val?.scrap_weight !== null
                          ? val?.scrap_weight
                          : 0}{" "}{"KG"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-center text-danger mb-0">{"No data found !"}</p>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default WorkOrder;
