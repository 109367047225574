// src/Account.js

import React from 'react';

const Account = () => {
    return (
        <div>
            <h2>Account Settings</h2>
            <form>
                <div>
                    <label>Email:</label>
                    <input type="email" name="email" />
                </div>
                <div>
                    <label>Password:</label>
                    <input type="password" name="password" />
                </div>
                <div>
                    <label>Confirm Password:</label>
                    <input type="password" name="confirmPassword" />
                </div>
                <button type="submit">Save</button>
            </form>
        </div>
    );
};

export default Account;
