import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faSearch,
  faFilter,
  faBan,
  faPlus,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
// import { getNCList, getOperatorList } from "../../../src/api/index";
import { Pagination } from "react-bootstrap";
import { Edit2, Eye, Trash2, Image, Edit } from "react-feather";
import { getBOM, deleteBOM } from "../../../api";
import SweetAlert from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import {
  calMaxPage,
  getLabels,
  setAccessPrivilage,
} from "../../../common/Functions/CommonFunctions";
import { BOM_Col_Name } from "../../../common/Labels/labelConstant";
import { useSelector } from "react-redux";
import List from "../../../assets/images/icons/project_menu/checklist.png";

function Bom() {
  let navigate = useNavigate();

  const [showLabel, setShowLabel] = useState(BOM_Col_Name);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [bomList, setBomList] = useState({
    data: [],
    loading: true,
    total: "",
  });

  const [filterConfig, setFilterConfig] = useState({
    search_val: "",
  });

  const [constLabel, setConstLabel] = useState({});

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );
  const [privilage, setPrivilage] = useState(
    setAccessPrivilage(loginData, "Bill of Material")
  );

  useEffect(() => {
    setConstLabel(getLabels("Master", "Bill of Material"));
  }, []);

  // useEffect(() => {
  //   setPage(1);
  //   fetchBoms(page, entriesPerPage);
  // }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchBoms(page, entriesPerPage);
    }, 1000);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [filterConfig]);

  function fetchBoms(pg, epp) {
    const payload = {
      entriesPerPage: epp,
      pageNo: pg,
    };
    setBomList({ data: [], loading: true });

    if (filterConfig.search_val != "") {
      payload.searchVal = filterConfig.search_val.trim();
    } else {
      delete payload.searchVal;
    }
    getBOM(payload).then(
      (res) => {
        console.log("res", res);
        console.log("res", res.data.total);

        setBomList({
          data: res.data.data,
          loading: false,
          total: res.data.total,
        });
        setMaxPage(calMaxPage(res.data.total));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setBomList({ data: [], loading: false });
      }
    );
  }

  function delBom(val) {
    SweetAlert.fire({
      title: "Are you sure to delete \n" + val.productname + " ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteBOM({ uuid: val.uuid }).then(
          (res) => {
            toast.success("BOM deleted successfully", {
              autoClose: 2000,
            });
            if (bomList.data.length == 1 && page > 1) {
              fetchBoms(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchBoms(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function clearFilter() {
    setFilterConfig({
      ...filterConfig,
      search_val: "",
    });
    setSrNo(0);
    setPage(1);
  }

  function toggleDiv(ind) {
    const selRow = document.getElementById("collapse_row" + ind);

    selRow.classList.toggle("expanded");
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="min-vh-100" id="root_div_main">
        <div id="mySidebar" className="customsidebar">
          <a className="closebtn" onClick={() => closeNav()}>
            ×
          </a>

          <div className=" content">
            {/* <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_selProductType
                  ? constLabel.lbl_selProductType
                  : "Select Product Type"}
              </label>
              <Multiselect
                options={prodType.data}
                onSelect={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, productTypename: val });
                  } else {
                    setFilterConfig({ ...filterConfig, productTypename: [] });
                  }
                }}
                onRemove={(e) => {
                  setFilterConfig({ ...filterConfig, productTypename: e });
                }}
                selectedValues={filterConfig.productTypename}
                displayValue="productType"
                placeholder="Select product type"
                loading={prodType.loading}
                customCloseIcon={
                  <p className="ms-2 mb-0 p-0" style={{ fontStyle: "normal" }}>
                    x
                  </p>
                }
              />
            </div> */}

            <div className="mt-2">
              <label
                className="filterLabel mb-1 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_product_name
                  ? constLabel?.lbl_product_name
                  : "Product Name"}{" "}
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by product name "
                value={filterConfig.search_val}
                onChange={(val) => {
                  const inputValue = val.target.value;
                  if (!inputValue.trim()) {
                    // Input contains only whitespace, don't trigger the search.
                    if (filterConfig.search_val.length == 1) {
                      setFilterConfig({ ...filterConfig, search_val: "" });
                    }
                  } else {
                    setFilterConfig({
                      ...filterConfig,
                      search_val: inputValue,
                    });
                  }
                }}
              />
            </div>

            <div className="my-3 d-flex justify-content-end">
              <button
                onClick={() => clearFilter()}
                className="btn btn-sm btn-yellow "
              >
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                {constLabel?.lbl_clear_filter
                  ? constLabel?.lbl_clear_filter
                  : "Clear Filter"}
              </button>
            </div>
          </div>
        </div>

        <div className="content-wrapper">
            <div className="card border-0">
              <div className="card-body ">
                <div className="row align-items-center">
                  <div className="d-md-flex justify-content-start align-items-center col-12 col-md-6">
                    {/* <FontAwesomeIcon
                      onClick={() => toggleNav()}
                      className="cursor_pointer mb-2"
                      style={{
                        color: "Black",
                        marginRight: "10px",
                      }}
                      icon={faBars}
                      size="lg"
                    /> */}
                    <h1 className="bold">
                      {constLabel?.lbl_bill_of_material
                        ? constLabel.lbl_bill_of_material
                        : "Bill of Material"}
                    </h1>
                  </div>

                  <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                    <div className="col-12 col-md-6 me-md-2 me-md-0">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Search by product name"
                        value={filterConfig.search_val}
                        onChange={(val) => {
                          const inputValue = val.target.value;
                          if (!inputValue.trim()) {
                            // Input contains only whitespace, don't trigger the search.
                            if (filterConfig.search_val.length == 1) {
                              setFilterConfig({ ...filterConfig, search_val: "" });
                            }
                          } else {
                            setFilterConfig({
                              ...filterConfig,
                              search_val: inputValue,
                            });
                          }
                        }}
                      />
                    </div>
                    {privilage.write && (
                      <button
                        onClick={() =>
                          navigate("/pcms/create/bill-of-material", {
                            state: constLabel,
                          })
                        }
                        className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faPlus}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_create_bom
                          ? constLabel.lbl_create_bom
                          : "Create BOM"}
                      </button>
                    )}
                    <Tooltip id={"show-tooltip"} place="top" />
                     <div 
                      className="dropdown"
                      data-tooltip-id={"show-tooltip"}
                      data-tooltip-content={"Show / Hide Columns"}
                     >
                        <div
                          className="d-flex justify-content-center align-items-center"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                          <img src={List} height='32' width='32' className="cursor_pointer" />
                        </div>

                        <ul className="dropdown-menu px-2">
                          {showLabel !== undefined && showLabel.length > 0
                            ? showLabel.map((val, ind, arr) => (
                                <li key={ind}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      style={{ width: "20px", height: "20px" }}
                                      onChange={() => handleClick(ind)}
                                      value=""
                                      checked={val.flag}
                                    />
                                    <label
                                      className=""
                                      style={{ fontSize: "13px" }}
                                      htmlFor="flexCheckDefault"
                                    >
                                      {val.label}
                                    </label>
                                  </div>
                                </li>
                              ))
                            : null}
                        </ul>
                      </div>
                  </div>

                  <div className="mt-3">
                    {bomList.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : privilage.read && bomList.data?.length > 0 ? (
                      <div className="table-responsive pc">
                        <table className="table mt-2 table-bordered">
                          <thead className="table-grey  roboto-bold">
                            <tr className="">
                              <th scope="col">{"SrNo"}</th>
                              {showLabel.map((v, i) =>
                                v.label === "BOM Id" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_bom_id
                                      ? constLabel.lbl_bom_id
                                      : "BOM Id"}
                                  </th>
                                ) : v.label === "Product Name" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_product_name
                                      ? constLabel.lbl_product_name
                                      : "Product Name"}
                                  </th>
                                ) : v.label === "Product Type" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_product_type
                                      ? constLabel.lbl_product_type
                                      : "Product Type"}
                                  </th>
                                ) : v.label === "Product Family" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_product_family
                                      ? constLabel.lbl_product_family
                                      : "Product Family"}
                                  </th>
                                ) : v.label === "BOM Type" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_bom_type
                                      ? constLabel.lbl_bom_type
                                      : "BOM Type"}
                                  </th>
                                ) : v.label === "Quantity" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_quantity
                                      ? constLabel.lbl_quantity
                                      : "Quantity"}
                                  </th>
                                ) : v.label === "Remark" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_remark
                                      ? constLabel.lbl_remark
                                      : "Remark"}
                                  </th>
                                ) : null
                              )}
                              <th scope="col" width="15%">
                                {"Action"}
                              </th>
                            </tr>
                          </thead>
                          <tbody className=" roboto">
                            {bomList.data.map((val, i) => (
                              <React.Fragment key={i}>
                                <tr>
                                  <td style={{ verticalAlign: "middle" }}>
                                    {parseInt(i) + 1 + parseInt(srNo)}
                                  </td>
                                  {showLabel.map((item, idx) =>
                                    item.label === "BOM Id" && item.flag ? (
                                      <td key={idx}>
                                        {val.bomId !== null ? val.bomId : "NA"}
                                      </td>
                                    ) : item.label === "Product Name" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.productname !== null
                                          ? val.productname
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Product Type" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.productType
                                          ? val.productType
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Product Family" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.product_family_id?.familyname
                                          ? val.product_family_id?.familyname
                                          : "NA"}
                                      </td>
                                    ) : item.label === "BOM Type" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.bom_type ? val.bom_type : "NA"}
                                      </td>
                                    ) : item.label === "Quantity" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.qty ? val.qty : "NA"}
                                      </td>
                                    ) : item.label === "Remark" && item.flag ? (
                                      <td key={idx}>
                                        {val.remarks ? val.remarks : "NA"}
                                      </td>
                                    ) : null
                                  )}
                                  <td className="">
                                    {privilage.write && (
                                      <>
                                        <Tooltip id={"edit-tooltip" + i} place="top" />
                                          <Edit
                                            data-tooltip-id={"edit-tooltip" + i}
                                            data-tooltip-content={
                                              constLabel?.lbl_edit_bom
                                                ? constLabel.lbl_edit_bom
                                                : "Edit BOM"
                                            }
                                            className="menu_icon_grey cursor_pointer me-2" 
                                            size={20} 
                                            onClick={() => {
                                              navigate(
                                                "/pcms/update/bill-of-material",
                                                {
                                                  state: {
                                                    locVal: val,
                                                    constLabel: constLabel,
                                                  },
                                                }
                                              );
                                            }}
                                          />
                                      </>
                                    )}

                                    {privilage.read && (
                                      <>
                                        <Tooltip id={"view-tooltip" + i} place="top" />
                                          <Eye
                                            data-tooltip-id={"view-tooltip" + i}
                                            data-tooltip-content={
                                              constLabel?.lbl_view_item
                                                ? constLabel.lbl_view_item
                                                : "View Item"
                                            }
                                            className="menu_icon_grey cursor_pointer me-2"
                                            size={20}
                                            onClick={() => {
                                              toggleDiv(i, val);
                                            }}
                                          />
                                      </>
                                    )}

                                    {privilage.delete && (
                                      <>
                                        <Tooltip id={"delete-tooltip" + i} place="top" />
                                          <Trash2
                                            data-tooltip-id={"delete-tooltip" + i}
                                            data-tooltip-content={
                                              constLabel?.lbl_delete_bom
                                                ? constLabel.lbl_delete_bom
                                                : "Delete BOM"
                                            }
                                            className="menu_icon_red cursor_pointer me-2"
                                            size={20}
                                            onClick={() => delBom(val)}
                                          />
                                      </>
                                    )}
                                  </td>
                                </tr>
                                <tr
                                  className={"collapsible-row"}
                                  id={"collapse_row" + i}
                                >
                                  <td colSpan="12">
                                    <div className="collapse-content">
                                      <div className="row mb-3">
                                        <div className="col-12 col-md-1">
                                          <label className="roboto-bold">
                                            {"Sr.No"}
                                          </label>
                                        </div>
                                        <div className="col-12 col-md-5">
                                          <label className="roboto-bold">
                                            {constLabel?.lbl_product_name
                                              ? constLabel.lbl_product_name
                                              : "Product Name"}
                                          </label>
                                        </div>
                                        <div className="col-12 col-md-3">
                                          <label className="roboto-bold">
                                            {constLabel?.lbl_quantity
                                              ? constLabel.lbl_quantity
                                              : "Quantity"}
                                          </label>
                                        </div>
                                        <div className="col-12 col-md-3">
                                          <label className="roboto-bold">
                                            {constLabel?.lbl_unit
                                              ? constLabel.lbl_unit
                                              : "Unit"}
                                          </label>
                                        </div>
                                      </div>
                                      {val.bom_line_item &&
                                        val.bom_line_item.length > 0 &&
                                        val.bom_line_item.map((vv, index) => (
                                          <div className="row " key={index}>
                                            <div className="col-12 col-md-1">
                                              <label className="roboto">
                                                {index + 1}
                                              </label>
                                            </div>

                                            <div className="col-12 col-md-5">
                                              <label className="roboto">
                                                {vv.productname
                                                  ? vv.productname
                                                  : "NA"}
                                              </label>
                                            </div>
                                            <div className="col-12 col-md-3">
                                              <label className="roboto">
                                                {vv.qty ? vv.qty : "NA"}
                                              </label>
                                            </div>

                                            <div className="col-12 col-md-3">
                                              <label className="roboto">
                                                {vv.unitname
                                                  ? vv.unitname
                                                  : "NA"}
                                              </label>
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div
                        className="alert alert-danger mb-0 text-center my-4"
                        role="alert"
                      >
                        {"No BOMS Found"}
                      </div>
                    )}

                    {bomList.loading
                      ? null
                      : bomList.data?.length > 0 && (
                          <div className="d-flex justify-content-between align-items-center mt-2">
                            <p className="mb-0">
                              Show
                              <select
                                className="mx-1"
                                defaultValue={entriesPerPage}
                                onChange={(e) => {
                                  setEntriesPerPage(e.target.value);
                                  fetchBoms(1, e.target.value);
                                }}
                              >
                                <option value={"10"}>10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                              Entries
                            </p>
                            <p className="mb-0">{`Showing ${
                              parseInt(srNo) + 1
                            } to ${Math.min(
                              parseInt(entriesPerPage) + parseInt(srNo),
                              bomList.total
                            )} of ${bomList.total} entries`}</p>
                            <Pagination className="my-2">
                              <Pagination.Prev
                                disabled={page === 1 ? true : false}
                                onClick={() => {
                                  fetchBoms(
                                    page - 1,
                                    entriesPerPage,
                                    parseInt(srNo) - parseInt(entriesPerPage)
                                  );
                                  setSrNo((prevC) =>
                                    page - 1 == 1
                                      ? 0
                                      : prevC - parseInt(entriesPerPage)
                                  );
                                  setPage(page - 1);
                                }}
                              >
                                {"Prev"}
                              </Pagination.Prev>

                              <Pagination.Item active>{page}</Pagination.Item>

                              <Pagination.Next
                                disabled={
                                  page === maxPage ||
                                  maxPage === 0 ||
                                  entriesPerPage > bomList.data.length
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  //
                                  fetchBoms(
                                    page + 1,
                                    entriesPerPage,
                                    parseInt(srNo) + parseInt(entriesPerPage)
                                  );
                                  setSrNo(
                                    (prevC) => prevC + parseInt(entriesPerPage)
                                  );
                                  setPage(page + 1);
                                }}
                              >
                                {"Next"}
                              </Pagination.Next>
                            </Pagination>
                          </div>
                        )}
                  </div>

                </div>
              </div>
            </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Bom;
