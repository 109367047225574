import React, { useState, useEffect } from "react";
import ImageGallery from "./ImageGallery";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faSearch,
  faFilter,
  faBan,
  faPlus,
  faCircle,
  faCog,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
// import { getNCList, getOperatorList } from "../../../src/api/index";
import { Pagination, Spinner } from "react-bootstrap";
import { Edit2, Eye, Trash2, Image, Edit } from "react-feather";
import {
  calMaxPage,
  convertDateYYMMDD,
  convertDate,
  getLabels,
  getShortReadableId,
  getFinancialYearStartDate
} from "../../common/Functions/CommonFunctions";
import Modal from "react-bootstrap/Modal";
import SweetAlert from "sweetalert2";
import {
  getOperationListForNc,
  getMachineList,
  getNCStatus,
  getLotNumList,
  getNCList,
  getOperatorList,
  getNCReason,
  getmaterialList,
  getProdList,
  deleteNCAPI,
  getJobTagList,
  getNCExcel,
  getOperation
} from "../../api/index";
import { NC_Col_Name } from "../../common/Labels/labelConstant";
// import Sidebar from "../../Sidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import List from "../../assets/images/icons/project_menu/checklist.png"
import Slide from "../../assets/images/icons/project_menu/collapse.png"

function NC() {
  let navigate = useNavigate();
  const [startDate, setStartDate] = useState(getFinancialYearStartDate(new Date()));
  const [endDate, setEndDate] = useState(new Date());

  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [opList, setOpList] = useState({ data: [], loading: false });
  const [oprtList, setOprtList] = useState({ data: [], loading: false });
  const [lotList, setLotList] = useState({ data: [], loading: false });
  const [jobTagList, setJobTagList] = useState({ data: [], loading: false });
  const [matList, setMatList] = useState({ data: [], loading: false });

  const [showLabel, setShowLabel] = useState(NC_Col_Name);
  const [constLabel, setConstLabel] = useState({});
  const [ncList, setNcList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [machineList, setMachineList] = useState({ data: [], loading: false });
  const [disActionList, setDisActionList] = useState({
    data: [],
    loading: false,
  });
  const [ncRsnList, setNcRsnList] = useState({ data: [], loading: false });
  const [galVisible, setGalVisible] = useState(false);

  const [dataList, setDataList] = useState({ data: [], loading: false });
  const loginData = useSelector((state) => state.auth);
  const [privilage, setPrivilage] = useState({});

  const [filterConfig, setFilterConfig] = useState({
    camShaft: [],
    operation: [],
    operator: [],
    lot_no: [],
    job_no: [],
    nc_tag: [],
    operationInWhich: [],
    machineNo: [],
    materialCode: [],
    ncReason: [],
    disposalAction: [],
  });
  
  const [selItem, setSelItem] = useState({});
  const [selItem2, setSelItem2] = useState({});

  const [show2, setShow2] = useState(false);

  const [isChecked, setIsChecked] = useState(false);
  const [lockBtn, setLockBtn] = useState(false);

  const abortController = new AbortController();

  const handleCheckboxChange = () => {
    // Toggle the isChecked state when the checkbox is changed
    let val = !isChecked;
    if (val) {
      setFilterConfig({ ...filterConfig, nc_tag_null: "True" });
    } else {
      let obj = { ...filterConfig };
      delete obj.nc_tag_null;
      setFilterConfig({ ...obj });
    }
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    setConstLabel(getLabels("Quality", "NC"));
    // setShowLabel(getLabels('Quality', 'NC').tbl_column);
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchNCList(1, entriesPerPage);
      // fetchLotList(startDate, endDate);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig, startDate, endDate]);

  useEffect(() => {
    for (
      let i = 0;
      i < loginData.loginData?.data?.response.module_list.length;
      i++
    ) {
      if (
        loginData.loginData?.data?.response.module_list[i].module == "Quality"
      ) {
        setPrivilage(loginData.loginData?.data?.response.module_list[i]);
      }
    }
    // fetchNCList(page, entriesPerPage);

    let mounted = true;
    if (mounted) {
      setTimeout(() => {
        fetchOperationList();

        fetchNcReasonList();
        fetchProdList();
      }, [2000]);
      fetchDisposalList();
      fetchMachineList();
      // fetchMaterialList();
      fetchOperatorList();
      // fetchLotList();
      // fetchJobTag();
    }

    return () => {
      abortController.abort();
      mounted = false;
    };
  }, []);

  function clearFilter() {
    let obj = {
      ...filterConfig,
      camShaft: [],
      operation: [],
      operator: [],
      lot_no: [],
      job_no: [],
      nc_tag: [],
      operationInWhich: [],
      machineNo: [],
      materialCode: [],
      ncReason: [],
      disposalAction: [],
    };
    delete obj.nc_tag_null;
    setIsChecked(false);
    setFilterConfig({ ...obj });
    setStartDate(getFinancialYearStartDate(new Date()));
    setEndDate(new Date());
  }

  function fetchProdList() {
    setDataList({ ...dataList, loading: true, data: [] });

    getProdList(undefined, { signal: abortController.signal }).then(
      (res) => {
        setDataList({ ...dataList, loading: false, data: res.data.data });
      },
      (err) => {}
    );
  }

  function fetchMaterialList() {
    setMatList({ ...matList, data: [], loading: true });
    getmaterialList(undefined, { signal: abortController.signal }).then(
      (res) => {
        if (res.data?.data) {
          const resultList = res.data.data.map((item) => {
            if (item.material_code === null) {
              let temp = { ...item };
              temp.material_code = "";
              return temp;
            } else {
              return item;
            }
          });

          setMatList({ ...matList, data: resultList, loading: false });
        }
      },
      (err) => {
        setMatList({ ...matList, data: [], loading: false });
      }
    );
  }

  function fetchOperationList() {
    setOpList({ ...opList, data: [], loading: true });
    // getOperationListForNc(undefined, { signal: abortController.signal }).then(
    getOperation(undefined, { signal: abortController.signal }).then(
      (res) => {
        setOpList({ ...opList, data: res.data.data, loading: false });
      },
      (err) => {
        setOpList({ ...opList, data: [], loading: false });
      }
    );
  }

  function fetchMachineList() {
    setMachineList({ ...machineList, data: [], loading: true });
    getMachineList(undefined, { signal: abortController.signal }).then(
      (res) => {
        const result = res.data.data.map((item) => ({
          ...item,
          machineNameSerialNo: item.machineName + " - " + item.serialNo,
        }));
        setMachineList({ ...machineList, data: result, loading: false });
      },
      (err) => {
        setMachineList({ ...machineList, data: [], loading: false });
      }
    );
  }

  function fetchDisposalList() {
    setDisActionList({ ...disActionList, data: [], loading: true });
    getNCStatus(undefined, { signal: abortController.signal }).then(
      (res) => {
        setDisActionList({
          ...disActionList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        setDisActionList({ ...disActionList, data: [], loading: false });
      }
    );
  }

  function fetchNcReasonList() {
    setNcRsnList({ ...ncRsnList, data: [], loading: true });
    getNCReason(undefined, { signal: abortController.signal }).then(
      (res) => {
        setNcRsnList({ ...ncRsnList, data: res.data.data, loading: false });
      },
      (err) => {
        setNcRsnList({ ...ncRsnList, data: [], loading: false });
      }
    );
  }

  function fetchOperatorList() {
    setOprtList({ ...oprtList, data: [], loading: true });
    getOperatorList(undefined, { signal: abortController.signal }).then(
      (res) => {
        const result = res.data.data.map((item) => ({
          ...item,
          fullName:
            item.FirstName + " " + item.MiddleName + " " + item.LastName,
        }));
        setOprtList({ ...oprtList, data: result, loading: false });
      },
      (err) => {
        setOprtList({ ...oprtList, data: [], loading: false });
      }
    );
  }

  function fetchLotList(sd, ed) {
    setLotList({ ...lotList, data: [], loading: true });
    getLotNumList({
      start_date: convertDateYYMMDD(new Date(sd), "-"),
      end_date: convertDateYYMMDD(new Date(ed), "-"),
    }).then(
      (res) => {
        setLotList({ ...lotList, data: res.data.data, loading: false });
      },
      (err) => {
        setLotList({ ...lotList, data: [], loading: false });
      }
    );
  }

  function fetchJobTag() {
    setJobTagList({ ...jobTagList, data: [], loading: true });
    getJobTagList(undefined, { signal: abortController.signal }).then(
      (res) => {
        const updatedArray = res.data.data.map((obj) => {
          const updatedObj = { ...obj };
          for (const key in updatedObj) {
            if (updatedObj[key] === null) {
              updatedObj[key] = "";
            }
          }
          return updatedObj;
        });

        setJobTagList({ ...jobTagList, data: updatedArray, loading: false });
      },
      (err) => {
        setJobTagList({ ...jobTagList, data: [], loading: false });
      }
    );
  }

  function fetchNCList(pg, epp) {
    setNcList({ ...ncList, loading: true, data: [] });

    const payload = {
      entriesPerPage: epp,
      pageNo: pg,
      startDate: convertDateYYMMDD(new Date(startDate), "-"),
      endDate: convertDateYYMMDD(new Date(endDate), "-"),
    };

    if (filterConfig.camShaft.length > 0) {
      payload.camshaftType = JSON.stringify(
        filterConfig.camShaft.map((item) => item.uuid)
      );
    } else {
      delete payload.camshaftType;
    }
    if (filterConfig.operation.length > 0) {
      payload.operation = JSON.stringify(
        filterConfig.operation.map((item) => item.uuid)
      );
    } else {
      delete payload.operation;
    }
    if (filterConfig.operationInWhich.length > 0) {
      payload.operationDetect = JSON.stringify(
        filterConfig.operationInWhich.map((item) => item.uuid)
      );
    } else {
      delete payload.operationDetect;
    }
    if (filterConfig.machineNo.length > 0) {
      payload.machine = JSON.stringify(
        filterConfig.machineNo.map((item) => item.uuid)
      );
    } else {
      delete payload.machine;
    }
    if (filterConfig.materialCode.length > 0) {
      payload.RMICode = JSON.stringify(
        filterConfig.materialCode.map((item) => item.materialcode)
      );
    } else {
      delete payload.RMICode;
    }
    if (filterConfig.ncReason.length > 0) {
      payload.ncreason = JSON.stringify(
        filterConfig.ncReason.map((item) => item.uuid)
      );
    } else {
      delete payload.ncreason;
    }
    if (filterConfig.disposalAction.length > 0) {
      payload.ncstatus = JSON.stringify(
        filterConfig.disposalAction.map((item) => item.uuid)
      );
    } else {
      delete payload.ncstatus;
    }
    if (filterConfig.operator.length > 0) {
      payload.operator = JSON.stringify(
        filterConfig.operator.map((item) => item.uuid)
      );
    } else {
      delete payload.operator;
    }
    // if (filterConfig.lot_no.length > 0) {
    //     payload.lot_no = JSON.stringify(filterConfig.lot_no.map(item => item.uuid))
    // } else {
    //     delete payload.lot_no
    // }

    if (filterConfig.lot_no.length > 0) {
      // payload.partNo_list = filterConfig.partNo;
      payload.lot_no = `["${filterConfig.lot_no[0]}"]`; //Only sent 1st index value for serarch
    } else {
      delete payload.lot_no;
    }

    if (filterConfig.job_no.length > 0) {
      payload.job_no = JSON.stringify(
        filterConfig.job_no.map((item) => item.job_no)
      );
    } else {
      delete payload.job_no;
    }
    if (filterConfig.nc_tag.length > 0) {
      payload.nc_tag = JSON.stringify(
        filterConfig.nc_tag.map((item) => item.nc_tag)
      );
    } else {
      delete payload.nc_tag;
    }

    if (filterConfig.nc_tag_null) {
      payload.nc_tag_null = filterConfig.nc_tag_null;
    }
    // const formDataToSend = new FormData();

    // for (const [key, value] of Object.entries(payload)) {
    //     formDataToSend.append(key, value);
    // }

    getNCList(payload, { signal: abortController.signal }).then(
      (res) => {
        setNcList({
          ...ncList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });

        setMaxPage(calMaxPage(res.data.total));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        // console.log("err.message",err)
        // toast.error(err.message, {
        //   autoClose: 2000,
        // });
        setNcList({ ...ncList, loading: false, data: [] });
      }
    );
  }

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  const delNC = (val) => {
    SweetAlert.fire({
      title: "Are you sure to delete \n" + val.camshaft_type + " ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteNCAPI({ uuid: val.uuid }).then(
          (res) => {
            toast.success("NC deleted successfully", {
              autoClose: 2000,
            });

            if (ncList.data.length == 1 && page > 1) {
              fetchNCList(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchNCList(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  const handleFromDateChange = (date) => {
    if (endDate && date > endDate) {
      return;
    }
    setStartDate(date);
  };

  const handleToDateChange = (date) => {
    if (startDate && date < startDate) {
      return;
    }
    setEndDate(date);
  };

  async function goToDownload() {
    setLockBtn(true);
    // console.log("inside download excel", filterConfig);
    const payload = {
      startDate: convertDateYYMMDD(new Date(startDate), "-"),
      endDate: convertDateYYMMDD(new Date(endDate), "-"),
    };

    // await getProductionData(payload).then(
    await getNCExcel(payload).then(
      (res) => {
        // console.log("res", res);
        if (res) {
          // excel_download(res.data.data);
          base64ToExcel(res.data);
        }
      },
      (err) => {
        console.log("err", err);
        toast.error(err.message, {
          autoClose: 2000,
        });
        setLockBtn(false);
      }
    );
  }

  function base64ToExcel(response) {
    const { statusCode, headers, body, isBase64Encoded } = response;

    // Assume base64String contains the base64 representation of the Excel file
    const base64String = body; // Replace this with your base64 string

    // Convert the base64 string into a Uint8Array
    const bytes = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));

    // Create a Blob object from the Uint8Array
    const blob = new Blob([bytes], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create a link element with download attribute
    const link = document.createElement("a");
    link.href = url;
    const currentDate = new Date().toDateString().split(" ").join("_");
    link.download = `Report_${currentDate}.xlsx`;

    // Programmatically click on the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Cleanup: remove the link and revoke the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    setLockBtn(false);
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);

      fetchJobTag();
    }
  }

  return (
    <div className="min-vh-100" id="root_div_main">
      {galVisible ? (
        <ImageGallery
          galVisible={galVisible}
          fetchNCList={fetchNCList}
          page={page}
          entriesPerPage={entriesPerPage}
          setGalVisible={setGalVisible}
          val={selItem2}
        />
      ) : null}

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />

        <div id="mySidebar" className="customsidebar ">
          <a className="closebtn" onClick={() => closeNav()}>
            ×
          </a>

          <div className=" content">
            <div className="filter row">
              <label
                className="filterLabel mb-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_selectDateRange
                  ? constLabel.lbl_selectDateRange
                  : "Select Date Range"}
              </label>
              <div className="fromDate col-12 col-md-6">
                <DatePicker
                  id="fromDate"
                  className="form-control form-control-sm"
                  selected={startDate}
                  showYearDropdown
                  scrollableMonthYearDropdown
                  dateFormat="dd/MM/yyyy"
                  placeholderText="From Date"
                  // onChange={(date) => setStartDate(date)}
                  onChange={(date) => handleFromDateChange(date)}
                  maxDate={new Date()}
                />
              </div>

              <div className="toDate col-12 col-md-6">
                <DatePicker
                  id="toDate"
                  className="form-control form-control-sm"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  selected={endDate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="To Date"
                  // onChange={(date) => setEndDate(date)}
                  onChange={(date) => handleToDateChange(date)}
                  maxDate={new Date()}
                />
              </div>
            </div>

            <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_selectCamShaftType
                  ? constLabel.lbl_selectCamShaftType
                  : "Select Cam Shaft Type"}
              </label>
              <Multiselect
                options={dataList.data}
                onSelect={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, camShaft: val });
                  } else {
                    setFilterConfig({ ...filterConfig, camShaft: [] });
                  }
                }}
                onRemove={(e) => {
                  setFilterConfig({ ...filterConfig, camShaft: e });
                }}
                selectedValues={filterConfig.camShaft}
                displayValue="productName"
                placeholder="Select Cam Shaft Type"
                loading={dataList.loading}
                customCloseIcon={
                  <p className="ms-2 mb-0 p-0" style={{ fontStyle: "normal" }}>
                    x
                  </p>
                }
              />
              {/* <Typeahead
                              id="basic-typeahead-single"
                              labelKey="productName"
                              name="customerType"
                              multiple={true}
                              size='sm'
                              options={dataList.data}
                              isLoading={dataList.loading}
                              selected={filterConfig.camShaft}
                              onChange={(val) => {
                                  if (val.length > 0) {
                                      
                                      setFilterConfig({ ...filterConfig, camShaft: val });
                                  } else {
                                      setFilterConfig({ ...filterConfig, camShaft: [] });
                                  }
                              }}
                              placeholder="Search Cam Shaft"
                          /> */}
            </div>

            <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_selectOperation
                  ? constLabel.lbl_selectOperation
                  : "Select Operation"}
              </label>
              <Multiselect
                options={opList.data}
                onSelect={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, operation: val });
                  } else {
                    setFilterConfig({ ...filterConfig, operation: [] });
                  }
                }}
                onRemove={(e) => {
                  setFilterConfig({ ...filterConfig, operation: e });
                }}
                selectedValues={filterConfig.operation}
                displayValue="operationName"
                placeholder="Select Operation"
                loading={opList.loading}
                customCloseIcon={
                  <p className="ms-2 mb-0 p-0" style={{ fontStyle: "normal" }}>
                    x
                  </p>
                }
              />
              {/* <Typeahead
                              id="basic-typeahead-single"
                              labelKey="operationName"
                              name="Operation"
                              multiple={true}
                              size='sm'
                              options={opList.data}
                              isLoading={opList.loading}
                              selected={filterConfig.operation}
                              onChange={(val) => {
                                  if (val.length > 0) {
                                      
                                      setFilterConfig({ ...filterConfig, operation: val });
                                  } else {
                                      setFilterConfig({ ...filterConfig, operation: [] });
                                  }
                              }}
                              placeholder="Search Operation"
                          /> */}
            </div>

            <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_selectOperationNCDetect
                  ? constLabel.lbl_selectOperationNCDetect
                  : "Select Operation in which NC Detected"}
              </label>

              <Multiselect
                options={opList.data}
                onSelect={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, operationInWhich: val });
                  } else {
                    setFilterConfig({ ...filterConfig, operationInWhich: [] });
                  }
                }}
                onRemove={(e) => {
                  setFilterConfig({ ...filterConfig, operationInWhich: e });
                }}
                selectedValues={filterConfig.operationInWhich}
                displayValue="operationName"
                placeholder="Select Operation"
                loading={opList.loading}
                customCloseIcon={
                  <p className="ms-2 mb-0 p-0" style={{ fontStyle: "normal" }}>
                    x
                  </p>
                }
              />
              {/* <Typeahead
                              id="basic-typeahead-single"
                              labelKey="operationName"
                              name="Operation"
                              multiple={true}
                              size='sm'
                              options={opList.data}
                              isLoading={opList.loading}
                              selected={filterConfig.operationInWhich}
                              onChange={(val) => {
                                  if (val.length > 0) {
                                      
                                      setFilterConfig({ ...filterConfig, operationInWhich: val });
                                  } else {
                                      setFilterConfig({ ...filterConfig, operationInWhich: [] });
                                  }
                              }}
                              placeholder="Search Operation"
                          /> */}
            </div>

            <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_selectMachine
                  ? constLabel.lbl_selectMachine
                  : "Select Machine"}
              </label>

              <Multiselect
                options={machineList.data}
                onSelect={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, machineNo: val });
                  } else {
                    setFilterConfig({ ...filterConfig, machineNo: [] });
                  }
                }}
                onRemove={(e) => {
                  setFilterConfig({ ...filterConfig, machineNo: e });
                }}
                selectedValues={filterConfig.machineNo}
                displayValue="machineNameSerialNo"
                placeholder="Select Machine"
                loading={machineList.loading}
                customCloseIcon={
                  <p className="ms-2 mb-0 p-0" style={{ fontStyle: "normal" }}>
                    x
                  </p>
                }
              />

              {/* <Typeahead
                              id="basic-typeahead-single"
                              labelKey="machineName"
                              name="Operation"
                              multiple={true}
                              size='sm'
                              options={machineList.data}
                              isLoading={machineList.loading}
                              selected={filterConfig.machineNo}
                              onChange={(val) => {
                                  if (val.length > 0) {
                                      
                                      setFilterConfig({ ...filterConfig, machineNo: val });
                                  } else {
                                      setFilterConfig({ ...filterConfig, machineNo: [] });
                                  }
                              }}
                              placeholder="Search Machine"
                          /> */}
            </div>

            {/* <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_selectMaterialCode
                  ? constLabel.lbl_selectMaterialCode
                  : "Select Material Code"}
              </label>

              <Multiselect
                options={matList.data}
                onSelect={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, materialCode: val });
                  } else {
                    setFilterConfig({ ...filterConfig, materialCode: [] });
                  }
                }}
                onRemove={(e) => {
                  setFilterConfig({ ...filterConfig, materialCode: e });
                }}
                selectedValues={filterConfig.materialCode}
                displayValue="material_code"
                placeholder="Select Material Code"
                loading={matList.loading}
                customCloseIcon={
                  <p className="ms-2 mb-0 p-0" style={{ fontStyle: "normal" }}>
                    x
                  </p>
                }
              />
            </div> */}

            <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_selectReasonForNC
                  ? constLabel.lbl_selectReasonForNC
                  : "Select Reason for NC"}
              </label>
              <Multiselect
                options={ncRsnList.data}
                onSelect={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, ncReason: val });
                  } else {
                    setFilterConfig({ ...filterConfig, ncReason: [] });
                  }
                }}
                onRemove={(e) => {
                  setFilterConfig({ ...filterConfig, ncReason: e });
                }}
                selectedValues={filterConfig.ncReason}
                displayValue="NCReason"
                placeholder="Select NC Reason"
                loading={ncRsnList.loading}
                customCloseIcon={
                  <p className="ms-2 mb-0 p-0" style={{ fontStyle: "normal" }}>
                    x
                  </p>
                }
              />

              {/* <Typeahead
                              id="basic-typeahead-single"
                              labelKey="NCReason"
                              name="Operation"
                              multiple={true}
                              size='sm'
                              options={ncRsnList.data}
                              isLoading={ncRsnList.loading}
                              selected={filterConfig.ncReason}
                              onChange={(val) => {
                                  if (val.length > 0) {
                                      
                                      setFilterConfig({ ...filterConfig, ncReason: val });
                                  } else {
                                      setFilterConfig({ ...filterConfig, ncReason: [] });
                                  }
                              }}
                              placeholder="Search NC Reason"
                          /> */}
            </div>

            <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_selectDisposalAction
                  ? constLabel.lbl_selectDisposalAction
                  : "Select Disposal Action"}
              </label>
              <Multiselect
                options={disActionList.data}
                onSelect={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, disposalAction: val });
                  } else {
                    setFilterConfig({ ...filterConfig, disposalAction: [] });
                  }
                }}
                onRemove={(e) => {
                  setFilterConfig({ ...filterConfig, disposalAction: e });
                }}
                selectedValues={filterConfig.disposalAction}
                displayValue="statusname"
                placeholder="Select Disposal Action"
                loading={disActionList.loading}
                customCloseIcon={
                  <p className="ms-2 mb-0 p-0" style={{ fontStyle: "normal" }}>
                    x
                  </p>
                }
              />

              {/* <Typeahead
                              id="basic-typeahead-single"
                              labelKey="statusname"
                              name="Operation"
                              multiple={true}
                              size='sm'
                              options={disActionList.data}
                              isLoading={disActionList.loading}
                              selected={filterConfig.disposalAction}
                              onChange={(val) => {
                                  if (val.length > 0) {
                                      
                                      setFilterConfig({ ...filterConfig, disposalAction: val });
                                  } else {
                                      setFilterConfig({ ...filterConfig, disposalAction: [] });
                                  }
                              }}
                              placeholder="Search Disposal Action"
                          /> */}
            </div>

            <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_selectOperator
                  ? constLabel.lbl_selectOperator
                  : "Select Operator"}
              </label>
              <Multiselect
                options={oprtList.data}
                onSelect={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, operator: val });
                  } else {
                    setFilterConfig({ ...filterConfig, operator: [] });
                  }
                }}
                onRemove={(e) => {
                  setFilterConfig({ ...filterConfig, operator: e });
                }}
                selectedValues={filterConfig.operator}
                displayValue={"fullName"}
                placeholder="Select Operator"
                loading={oprtList.loading}
                customCloseIcon={
                  <p className="ms-2 mb-0 p-0" style={{ fontStyle: "normal" }}>
                    x
                  </p>
                }
              />
            </div>

            {/* <div>
                          <label
                              className="filterLabel my-2 roboto-bold"
                              style={{ fontSize: "14px" }}
                          >
                              Select Lot No
                          </label>
                          <Multiselect
                              options={lotList.data}
                              onSelect={(val) => {
                                  if (val.length > 0) {
                                      
                                      setFilterConfig({ ...filterConfig, lot_no: val });
                                  } else {
                                      setFilterConfig({ ...filterConfig, lot_no: [] });
                                  }
                              }}
                              onRemove={(e) => {
                                  
                                  setFilterConfig({ ...filterConfig, lot_no: e });
                              }}
                              selectedValues={filterConfig.lot_no}
                              displayValue={"lotId"}
                              placeholder="Select Lot"
                              loading={lotList.loading}
                              customCloseIcon={
                                  <p className="ms-2 mb-0 p-0" style={{ fontStyle: "normal" }}>
                                      x
                                  </p>
                              }
                          />
                      </div> */}

            <div className="mt-2">
              <label
                className="filterLabel mb-1 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_enterLotNo
                  ? constLabel.lbl_enterLotNo
                  : "Enter Lot No"}
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by lot no"
                value={
                  filterConfig.lot_no.length > 0 ? filterConfig.lot_no[0] : ""
                }
                onChange={(val) => {
                  // if (val.target.value !== "") {

                  //     setFilterConfig({ ...filterConfig, lot_no: [val.target.value] });
                  // } else {
                  //     setFilterConfig({ ...filterConfig, lot_no: [] });
                  // }
                  const inputValue = val.target.value;
                  if (!inputValue.trim()) {
                    // Input contains only whitespace, don't trigger the search.
                    if (filterConfig.lot_no.length == 1) {
                      setFilterConfig({ ...filterConfig, lot_no: [] });
                    }
                  } else {
                    setFilterConfig({ ...filterConfig, lot_no: [inputValue] });
                  }
                }}
              />
            </div>

            <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_selectJobNo
                  ? constLabel.lbl_selectJobNo
                  : "Select Job No"}
              </label>
              <Multiselect
                options={jobTagList.data}
                onSelect={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, job_no: val });
                  } else {
                    setFilterConfig({ ...filterConfig, job_no: [] });
                  }
                }}
                onRemove={(e) => {
                  setFilterConfig({ ...filterConfig, job_no: e });
                }}
                selectedValues={filterConfig.job_no}
                displayValue={"job_no"}
                placeholder="Select job no"
                loading={jobTagList.loading}
                customCloseIcon={
                  <p className="ms-2 mb-0 p-0" style={{ fontStyle: "normal" }}>
                    x
                  </p>
                }
              />
            </div>

            <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_selectTagNo
                  ? constLabel.lbl_selectTagNo
                  : "Select Tag No"}
              </label>
              <Multiselect
                options={jobTagList.data}
                onSelect={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, nc_tag: val });
                  } else {
                    setFilterConfig({ ...filterConfig, nc_tag: [] });
                  }
                }}
                onRemove={(e) => {
                  setFilterConfig({ ...filterConfig, nc_tag: e });
                }}
                selectedValues={filterConfig.nc_tag}
                displayValue={"nc_tag"}
                placeholder="Select tag no"
                loading={jobTagList.loading}
                customCloseIcon={
                  <p className="ms-2 mb-0 p-0" style={{ fontStyle: "normal" }}>
                    x
                  </p>
                }
              />
            </div>
            {/* <div>
              <div className="form-check mt-3">
                <label className="form-check-label lbl-style roboto-bold">
                  {"NC with Null Tag"}
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                </label>
              </div>
            </div> */}
            <div>
              <div className="form-check form-switch mt-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckChecked"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <label
                  className="form-check-label lbl-style roboto-bold"
                  htmlFor="flexSwitchCheckChecked"
                >
                  {"NC with Null Tag"}
                </label>
              </div>
            </div>

            <div className="my-3 d-flex justify-content-end">
              <button
                onClick={() => clearFilter()}
                className="btn btn-sm btn-yellow "
              >
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                {constLabel?.lbl_clear_filter
                  ? constLabel.lbl_clear_filter
                  : "Clear Filter"}
              </button>
            </div>
          </div>
        </div>

        <div className="content-wrapper">
          <div className="card border-0 mb-4">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="d-md-flex justify-content-start col-12 col-md-6">
                  {/* <FontAwesomeIcon
                    onClick={() => toggleNav()}
                    className="cursor_pointer mb-2"
                    style={{
                      color: "Black",
                      marginRight: "10px",
                    }}
                    icon={faBars}
                    size="lg"
                  /> */}
                  <Tooltip id={"open-tooltip"} place="top" />
                  <img src={Slide} height='32' width='32' className="cursor_pointer my-1 me-2" onClick={() => toggleNav()}
                        data-tooltip-id={"open-tooltip"}
                        data-tooltip-content={"Sidebar"}
                  />
                  
                  <h1 className="bold">
                    {constLabel?.lbl_non_conformance
                      ? constLabel.lbl_non_conformance
                      : "Non-Conformance"}
                  </h1>
                </div>
                <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">

                  {loginData.loginData?.data?.response.role_name !== "client_admin" &&
                  loginData.loginData?.data?.response.role_name !== "admin" ? (
                    privilage.report == true ? (
                      <button
                        className="btn btn-light-grey me-0 me-2 ms-0 ms-md-2"
                        disabled={lockBtn}
                        onClick={() => goToDownload()}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faDownload}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_nc_report
                          ? constLabel.lbl_nc_report
                          : "NC Report"}{" "}
                        {lockBtn ? <Spinner animation="border" size="sm" /> : null}
                      </button>
                    ) : null
                  ) : (
                    <button
                      className="btn btn-light-grey me-0 me-2 ms-0 ms-md-2"
                      disabled={lockBtn}
                      onClick={() => goToDownload()}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff" }}
                        icon={faDownload}
                        size="sm"
                      />{" "}
                      {constLabel?.lbl_nc_report
                        ? constLabel.lbl_nc_report
                        : "NC Report"}{" "}
                      {lockBtn ? <Spinner animation="border" size="sm" /> : null}
                    </button>
                  )}

                  {loginData.loginData?.data?.response.role_name !== "client_admin" &&
                  loginData.loginData?.data?.response.role_name !== "admin" ? (
                    privilage.write == true ? (
                      <button
                        className="btn button-primary me-0 me-2 ms-0 ms-md-2"
                        onClick={() => {
                          navigate("/pcms/nc-create");
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faPlus}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_create_new_nc
                          ? constLabel.lbl_create_new_nc
                          : "Create New NC"}
                      </button>
                    ) : null
                  ) : (
                    <button
                      className="btn button-primary me-0 me-2 ms-0 ms-md-2"
                      onClick={() => {
                        navigate("/pcms/nc-create");
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff" }}
                        icon={faPlus}
                        size="sm"
                      />{" "}
                      {constLabel?.lbl_create_new_nc
                        ? constLabel.lbl_create_new_nc
                        : "Create New NC"}
                    </button>
                  )}
                  
                  <Tooltip id={"show-tooltip"} place="top" />
                  <div
                    className="dropdown"
                    data-tooltip-id={"show-tooltip"}
                    data-tooltip-content={"Show / Hide Columns"}
                  >
                    <div
                      className="d-md-flex justify-content-start align-items-center"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {/* <FontAwesomeIcon icon={faCog} size="lg" /> */}
                      {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                      <img src={List} height='32' width='32' className="cursor_pointer" />
                    </div>

                    <ul className="dropdown-menu px-2">
                      {showLabel !== undefined && showLabel.length > 0
                        ? showLabel.map((val, ind, arr) => (
                            <li key={ind}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{ width: "20px", height: "20px" }}
                                  onChange={() => handleClick(ind)}
                                  value=""
                                  checked={val.flag}
                                />
                                <label
                                  className=""
                                  style={{ fontSize: "13px" }}
                                  htmlFor="flexCheckDefault"
                                >
                                  {val.label}
                                </label>
                              </div>
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>
                </div>
              </div>

              {ncList.loading ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : ncList.data?.length > 0 ? (
                <div
                  className="table-responsive"
                >
                  <table className="table mt-2 table-bordered">
                    <thead className="table-grey roboto-bold">
                      <tr className="">
                        <th scope="col">{"SrNo"}</th>
                        {/* <th scope="col">{"Tag No"}</th> */}
                        {showLabel.map((v, i) =>
                          v.label === "Date" && v.flag ? (
                            <th key={i} scope="col">
                              {"Date"}
                            </th>
                          ) : v.label === "Camshaft" && v.flag ? (
                            <th key={i} scope="col">
                              {"Camshaft"}
                            </th>
                          ) : v.label === "NC Tag" && v.flag ? (
                            <th key={i} scope="col">
                              {"NC Tag"}
                            </th>
                          ) : v.label === "Lot No" && v.flag ? (
                            <th key={i} scope="col">
                              {"Lot No"}
                            </th>
                          ) : v.label === "Work Order No" && v.flag ? (
                            <th key={i} scope="col">
                              {"Work Order No"}
                            </th>
                          ) : v.label === "Lot Size" && v.flag ? (
                            <th key={i} scope="col">
                              {"Lot Size"}
                            </th>
                          ) : v.label === "NC Reason" && v.flag ? (
                            <th key={i} scope="col">
                              {"NC Reason"}
                            </th>
                          ) : v.label === "Disposal Action" && v.flag ? (
                            <th key={i} scope="col">
                              {"Disposal Action"}
                            </th>
                          ) : v.label === "Job No" && v.flag ? (
                            <th key={i} scope="col">
                              {"Job No"}
                            </th>
                          ) : v.label === "Operation" && v.flag ? (
                            <th key={i} scope="col">
                              {"Operation"}
                            </th>
                          ) : v.label === "Operation In Which" && v.flag ? (
                            <th key={i} scope="col">
                              {"Operation In Which"}
                            </th>
                          ) : v.label === "Operator" && v.flag ? (
                            <th key={i} scope="col">
                              {"Operator"}
                            </th>
                          ) : v.label === "Part No" && v.flag ? (
                            <th key={i} scope="col">
                              {"Part No"}
                            </th>
                          ) : v.label === "Quantity" && v.flag ? (
                            <th key={i} scope="col">
                              {"Quantity"}
                            </th>
                          ) : v.label === "RMI Code" && v.flag ? (
                            <th key={i} scope="col">
                              {"RMI Code"}
                            </th>
                          ) : v.label === "Selling Price" && v.flag ? (
                            <th key={i} scope="col">
                              {"Selling Price"}
                            </th>
                          ) : null
                        )}

                        <th scope="col" width="15%">
                          {"Action"}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="roboto">
                      {ncList.data.map((val, i) => (
                        <tr key={i}>
                          <td style={{ verticalAlign: "middle" }}>
                            {parseInt(i) + 1 + parseInt(srNo)}
                          </td>
                          {showLabel.map((item, idx) =>
                            item.label === "Date" && item.flag ? (
                              <td key={idx}>
                                {convertDate(val.nc_date, "-")}
                              </td>
                            ) : item.label === "Camshaft" && item.flag ? (
                              <td key={idx}>{val.camshaft_type}</td>
                            ) : item.label === "NC Tag" && item.flag ? (
                              <td key={idx}>{val.nc_tag}</td>
                            ) : item.label === "Lot No" && item.flag ? (
                              <td key={idx}>
                                {val.lot_no !== null ? val.lot_no : "N/A"}
                              </td>
                            ) : item.label === "Work Order No" &&
                              item.flag ? (
                              <td key={idx}>
                                {val.work_order_id !== null
                                  ? getShortReadableId(val.work_order_id?.work_order_no)
                                  : "N/A"}
                              </td>
                            ) : item.label === "Lot Size" && item.flag ? (
                              <td key={idx}>{val.lot_size}</td>
                            ) : item.label === "NC Reason" && item.flag ? (
                              <td key={idx}>{val.ncreason}</td>
                            ) : item.label === "Disposal Action" &&
                              item.flag ? (
                              <td key={idx}>{val.ncstatus}</td>
                            ) : item.label === "Job No" && item.flag ? (
                              <td key={idx}>{val.job_no}</td>
                            ) : item.label === "Operation" && item.flag ? (
                              <td key={idx}>{val.operation}</td>
                            ) : item.label === "Operation In Which" &&
                              item.flag ? (
                              <td key={idx}>{val.operation_inwhich}</td>
                            ) : item.label === "Operator" && item.flag ? (
                              <td key={idx}>{val.operator_id?.FirstName + " " + val.operator_id?.LastName}</td>
                            ) : item.label === "Part No" && item.flag ? (
                              <td key={idx}>{val.part_no}</td>
                            ) : item.label === "Quantity" && item.flag ? (
                              <td key={idx}>{val.qty}</td>
                            ) : item.label === "RMI Code" && item.flag ? (
                              <td key={idx}>{val.rmiCode}</td>
                            ) : item.label === "Selling Price" &&
                              item.flag ? (
                              <td key={idx}>{val.selling_price}</td>
                            ) : null
                          )}

                          <td className="">
                            {loginData.loginData?.data?.response.role_name !==
                              "client_admin" &&
                            loginData.loginData?.data?.response.role_name !==
                              "admin" ? (
                              privilage.write == true ? (
                                <>
                                  <Tooltip id={"edit-tooltip" + i} place="top"/>
                                    <Edit 
                                      data-tooltip-id={"edit-tooltip" + i}
                                      data-tooltip-content={
                                        constLabel?.lbl_edit_nc
                                          ? constLabel.lbl_edit_nc
                                          : "Edit NC"
                                      }
                                      className="menu_icon_grey cursor_pointer me-2"
                                      size={20} 
                                      onClick={() => {
                                        const newVal = { ...val };
  
                                        if (
                                          newVal.machine_id_id !== null &&
                                          newVal.camshaft_type_id !== null &&
                                          (newVal.lot_no_id !== null ||
                                            newVal.work_order_id !== null) &&
                                          newVal.operation_id_id !== null &&
                                          newVal.operator_id !== null
                                        ) {
                                          newVal.machine_id_id.machineNameSerialNo =
                                            newVal.machine_id_id.machineName +
                                            " - " +
                                            newVal.machine_id_id.serialNo;
                                          navigate("/pcms/nc-update", {
                                            state: newVal,
                                          });
                                        } else {
                                          toast.error("Cannot update old nc", {
                                            autoClose: 2000,
                                          });
                                        }
                                      }}
                                    />
                                </>
                              ) : null
                            ) : (
                              <>
                                <Tooltip id={"edit-tooltip" + i} place="top"/>
                                    <Edit 
                                      data-tooltip-id={"edit-tooltip" + i}
                                      data-tooltip-content={
                                        constLabel?.lbl_edit_nc
                                          ? constLabel.lbl_edit_nc
                                          : "Edit NC"
                                      }
                                      className="menu_icon_grey cursor_pointer me-2"
                                      size={20} 
                                      onClick={() => {
                                        const newVal = { ...val };
    
                                        if (
                                          newVal.machine_id_id !== null &&
                                          newVal.camshaft_type_id !== null &&
                                          (newVal.lot_no_id !== null ||
                                            newVal.work_order_id !== null) &&
                                          newVal.operation_id_id !== null &&
                                          newVal.operator_id !== null
                                        ) {
                                          newVal.machine_id_id.machineNameSerialNo =
                                            newVal.machine_id_id.machineName +
                                            " - " +
                                            newVal.machine_id_id.serialNo;
                                          navigate("/pcms/nc-update", {
                                            state: newVal,
                                          });
                                        } else {
                                          toast.error("Cannot update old nc", {
                                            autoClose: 2000,
                                          });
                                        }
                                      }}
                                    />
                              </>
                            )}

                            {loginData.loginData?.data?.response.role_name !==
                              "client_admin" &&
                            loginData.loginData?.data?.response.role_name !==
                              "admin" ? (
                              privilage.view == true ? (
                                <>
                                  <Tooltip id={"view-tooltip" + i} place="top"/>
                                    <Eye  
                                      data-tooltip-id={"view-tooltip" + i}
                                      data-tooltip-content={
                                        constLabel?.lbl_view_nc
                                          ? constLabel.lbl_view_nc
                                          : "View NC"
                                      }
                                      className="menu_icon_grey cursor_pointer me-2"
                                      size={20}
                                      onClick={() => {
                                        setSelItem(val);
                                        setShow2(true);
                                      }}
                                    />
                                </>
                              ) : null
                            ) : (
                              <>
                                <Tooltip id={"view-tooltip" + i} place="top"/>
                                  <Eye  
                                    data-tooltip-id={"view-tooltip" + i}
                                    data-tooltip-content={
                                      constLabel?.lbl_view_nc
                                        ? constLabel.lbl_view_nc
                                        : "View NC"
                                    }
                                    className="menu_icon_grey cursor_pointer me-2"
                                    size={20}
                                    onClick={() => {
                                      setSelItem(val);
                                      setShow2(true);
                                    }}
                                  />
                              </>
                            )}

                            {loginData.loginData?.data?.response.role_name !==
                              "client_admin" &&
                            loginData.loginData?.data?.response.role_name !==
                              "admin" ? (
                              privilage.view == true ? (
                                <>
                                  <Tooltip id={"image-tooltip" + i} place="top" />
                                  <Image  
                                    data-tooltip-id={"image-tooltip" + i}
                                    data-tooltip-content={
                                      constLabel?.lbl_image_gallary
                                        ? constLabel.lbl_image_gallary
                                        : "Image Gallary"
                                    }
                                    className="menu_icon_grey cursor_pointer me-2"
                                    size={20}
                                    onClick={() => {
                                      setGalVisible(true);
                                      setSelItem2(val);
                                    }}
                                  />
                                </>
                              ) : null
                            ) : (
                              <>
                                <Tooltip id={"image-tooltip" + i} place="top" />
                                <Image  
                                    data-tooltip-id={"image-tooltip" + i}
                                    data-tooltip-content={
                                      constLabel?.lbl_image_gallary
                                        ? constLabel.lbl_image_gallary
                                        : "Image Gallary"
                                    }
                                    className="menu_icon_grey cursor_pointer me-2"
                                    size={20}
                                    onClick={() => {
                                      setGalVisible(true);
                                      setSelItem2(val);
                                    }}
                                  />
                              </>
                            )}

                            {loginData.loginData?.data?.response.role_name !==
                              "client_admin" &&
                            loginData.loginData?.data?.response.role_name !==
                              "admin" ? (
                              privilage.delete == true ? (
                                <>
                                  <Tooltip id={"delete-tooltip" + i} place="top" />
                                    <Trash2
                                      data-tooltip-id={"delete-tooltip" + i}
                                      data-tooltip-content={
                                        constLabel?.lbl_delete_nc
                                          ? constLabel.lbl_delete_nc
                                          : "Delete NC"
                                      }
                                      className="menu_icon_red cursor_pointer"
                                      size={20} 
                                      onClick={() => delNC(val)}
                                    />
                                </>
                              ) : null
                            ) : (
                              <>
                                <Tooltip id={"delete-tooltip" + i} place="top" />
                                  <Trash2
                                    data-tooltip-id={"delete-tooltip" + i}
                                    data-tooltip-content={
                                      constLabel?.lbl_delete_nc
                                        ? constLabel.lbl_delete_nc
                                        : "Delete NC"
                                    }
                                    className="menu_icon_red cursor_pointer"
                                    size={20} 
                                    onClick={() => delNC(val)}
                                  />
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="text-center text-danger mb-0">No NC Found</p>
              )}

              {ncList.loading ? null : ncList.data?.length > 0 ? (
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <p className="mb-0">
                    Show
                    <select
                      className="mx-1"
                      defaultValue={entriesPerPage}
                      onChange={(e) => {
                        setEntriesPerPage(e.target.value);
                        fetchNCList(1, e.target.value);
                      }}
                    >
                      <option value={"10"}>10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    Entries
                  </p>
                  <p className="mb-0">{`Showing ${parseInt(srNo) + 1} to ${
                    ncList.data.length +
                    entriesPerPage * page -
                    entriesPerPage
                  } of ${ncList.totalRecords} entries`}</p>
                  <Pagination className="my-2">
                    <Pagination.Prev
                      disabled={page === 1 ? true : false}
                      onClick={() => {
                        fetchNCList(
                          page - 1,
                          entriesPerPage,
                          parseInt(srNo) - parseInt(entriesPerPage)
                        );
                        setSrNo((prevC) =>
                          page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                        );
                        setPage(page - 1);
                      }}
                    >
                      {"Prev"}
                    </Pagination.Prev>

                    <Pagination.Item active>{page}</Pagination.Item>

                    <Pagination.Next
                      disabled={
                        page === maxPage ||
                        maxPage === 0 ||
                        entriesPerPage > ncList.data.length
                          ? true
                          : false
                      }
                      onClick={() => {
                        fetchNCList(
                          page + 1,
                          entriesPerPage,
                          parseInt(srNo) + parseInt(entriesPerPage)
                        );
                        setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                        setPage(page + 1);
                      }}
                    >
                      {"Next"}
                    </Pagination.Next>
                  </Pagination>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <Modal show={show2} onHide={() => setShow2(false)} size="lg">
          <Modal.Header
            style={{ background: "#2A3643", color: "white" }}
            closeButton
          >
            <Modal.Title>
              {constLabel?.lbl_nc_detail ? constLabel.lbl_nc_detail : "NC Detail"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div className="row">
                <div className="col-12 col-md-3">
                  <div>
                    <p className="m-0 nc_detail_label">
                      {constLabel?.lbl_work_order_no ? constLabel.lbl_work_order_no : "Work Order No"}
                    </p>
                    <p className="mb-2 roboto-bold">{selItem.work_order_id !== undefined && selItem.work_order_id !== null ?  selItem.work_order_id?.work_order_no : "NA"}</p>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div>
                    <p className="m-0 nc_detail_label">
                      {constLabel?.lbl_job_card_no ? constLabel.lbl_job_card_no : "Job Card No"}
                    </p>
                    <p className="mb-2 roboto-bold">{selItem.job_card_id !== undefined && selItem.job_card_id !== null ?  selItem.job_card_id?.job_card_no : "NA"}</p>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div>
                    <p className="m-0 nc_detail_label">
                      {constLabel?.lbl_lot_no ? constLabel.lbl_lot_no : `Lot No`}
                    </p>
                    <p className="mb-2 roboto-bold">{selItem.lot_no !== undefined && selItem.lot_no !== null ?  selItem.lot_no : "NA"}</p>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div>
                    <p className="m-0 nc_detail_label">
                      {selItem.work_order_id !== undefined && selItem.work_order_id !== null 
                        ? (constLabel?.lbl_work_order_qty ? constLabel.lbl_work_order_qty : "Work Order Qty") 
                        : (constLabel?.lbl_lot_size ? constLabel.lbl_lot_size : "Lot Size")
                      }
                    </p>
                    <p className="mb-2 roboto-bold">
                      {selItem.work_order_id !== undefined && selItem.work_order_id !== null 
                        ? (selItem.work_order_id?.quantity ?? "NA")
                        : (selItem.lot_size ?? "NA")
                      }
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-3 mt-2">
                  <div>
                    <p className="m-0 nc_detail_label">
                      {constLabel?.lbl_product_name ? constLabel.lbl_product_name : `Product Name`}
                    </p>
                    <p className="mb-2 roboto-bold">{selItem.camshaft_type_id !== undefined && selItem.camshaft_type_id !== null ?  selItem?.camshaft_type_id?.productName : "NA"}</p>
                  </div>
                </div>
                <div className="col-12 col-md-3 mt-2">
                  <div>
                    <p className="m-0 nc_detail_label">
                      {constLabel?.lbl_part_no ? constLabel.lbl_part_no : "Part No"}
                    </p>
                    <p className="mb-2 roboto-bold">{selItem.part_no !== undefined && selItem.part_no !== null ?  selItem?.part_no : "NA"}</p>
                  </div>
                </div>
                <div className="col-12 col-md-3 mt-2">
                  <div>
                    <p className="m-0 nc_detail_label">
                      {constLabel?.lbl_nc_date ? constLabel.lbl_nc_date : `NC Date`}
                    </p>
                    <p className="mb-2 roboto-bold">{convertDate(selItem.nc_date, "-") ?? "NA"}</p>
                  </div>
                </div>
              </div>

            <div
              className="bg-lightgrey"
              style={{ padding: "12px", borderRadius: "12px" }}
            >
              <div className="row">
                <div className="col-12 col-md-6">
                  <div>
                    <p className="m-0 nc_detail_label">
                      {constLabel?.lbl_nc_tag ? constLabel.lbl_nc_tag : "NC Tag"}
                    </p>
                    <p className="mb-2 roboto-bold">{selItem.nc_tag}</p>
                  </div>
                  <div>
                    <p className="m-0 nc_detail_label">
                      {constLabel?.lbl_quantity
                        ? constLabel.lbl_quantity
                        : `Quantity`}
                    </p>
                    {/* <p className='mb-2 roboto-bold'>{`${selItem.lot_size ? selItem.lot_size : "NA"}`}</p> */}
                    <p className="mb-2 roboto-bold">
                      {selItem.qty ? selItem.qty : "NA"}
                    </p>
                  </div>
                  <div>
                    <p className="m-0 nc_detail_label">
                      {constLabel?.lbl_machine
                        ? constLabel.lbl_machine
                        : `Machine`}
                    </p>
                    <p className="mb-2 roboto-bold">{`${selItem.machine_id_id?.machineName} - ${selItem.machine_id_id?.serialNo}`}</p>
                  </div>

                  {/* <div>
                    <p className="m-0 nc_detail_label">
                      {constLabel?.lbl_root_cause_at_operation
                        ? constLabel.lbl_root_cause_at_operation
                        : `Root Cause At Operation`}
                    </p>
                    <p className="mb-2 roboto-bold">{`${
                      selItem.operation ? selItem.operation : "NA"
                    }`}</p>
                  </div> */}

                  {/* <div>
                    <p className="m-0 nc_detail_label">
                      {constLabel?.lbl_reason_for_nc
                        ? constLabel.lbl_reason_for_nc
                        : `Reason For NC`}
                    </p>
                    <p className="mb-2 roboto-bold">{`${
                      selItem.ncreason ? selItem.ncreason : "NA"
                    }`}</p>
                  </div> */}
                  {/* <div>
                    <p className="m-0 nc_detail_label">
                      {constLabel?.lbl_material_code
                        ? constLabel.lbl_material_code
                        : `Material Code`}
                    </p>
                    <p className="mb-2 roboto-bold">{`${
                      selItem.camshaft_type_id?.material
                        ? selItem.camshaft_type_id?.material
                        : "NA"
                    }`}</p>
                  </div> */}
                </div>
                <div className="col-12 col-md-6 ">
                  <div>
                    <p className="m-0 nc_detail_label">
                      {constLabel?.lbl_job_no ? constLabel.lbl_job_no : `Job No`}
                    </p>
                    <p className="mb-2 roboto-bold">{selItem.job_no}</p>
                  </div>
                  <div>
                    <p className="m-0 nc_detail_label">
                      {constLabel?.lbl_nc_detected_operation
                        ? constLabel.lbl_nc_detected_operation
                        : `NC Detected Operation`}
                    </p>
                    <p className="mb-2 roboto-bold">{`${
                      selItem.operation_inwhich ? selItem.operation_inwhich : "NA"
                    }`}</p>
                  </div>
                  {/* <div>
                    <p className="m-0 nc_detail_label">
                      {constLabel?.lbl_lot_size
                        ? constLabel.lbl_lot_size
                        : `Lot Size`}
                    </p>
                    <p className="mb-2 roboto-bold">{`${
                      selItem.lot_size ? selItem.lot_size : "NA"
                    }`}</p>
                  </div> */}

                  {/* <div>
                    <p className="m-0 nc_detail_label">
                      {constLabel?.lbl_lot_no ? constLabel.lbl_lot_no : `Lot No`}
                    </p>
                    <p className="mb-2 roboto-bold">{selItem.lot_no}</p>
                  </div> */}
                  <div>
                    <p className="m-0 nc_detail_label">
                      {constLabel?.lbl_root_cause
                        ? constLabel.lbl_root_cause
                        : `Root Cause`}
                    </p>
                    <p className="mb-2 roboto-bold">{`${
                      selItem.remark ? selItem.remark : "NA"
                    }`}</p>
                  </div>

                  {/* <div>
                    <p className="m-0 nc_detail_label">
                      {constLabel?.lbl_quantity
                        ? constLabel.lbl_quantity
                        : `Quantity`}
                    </p>
                    <p className="mb-2 roboto-bold">
                      {selItem.qty ? selItem.qty : "NA"}
                    </p>
                  </div> */}

                  {/* <div>
                    <p className="m-0 nc_detail_label">
                      {constLabel?.lbl_operation_inWhich_nc_detected
                        ? constLabel.lbl_operation_inWhich_nc_detected
                        : `Operation in which NC Detected`}
                    </p>
                    <p className="mb-2 roboto-bold">{`${
                      selItem.operation_inwhich ? selItem.operation_inwhich : "NA"
                    }`}</p>
                  </div> */}
                </div>
              </div>
            </div>
            <hr className="mb-1 mt-4" />
            <div className="d-md-flex justify-content-between">
              <p className="roboto-bold mb-0" style={{ fontSize: "18px" }}>
                {constLabel?.lbl_nc_history
                  ? constLabel.lbl_nc_history
                  : "NC History"}
              </p>
            </div>
            <hr className="my-1" />
            <div className="" style={{ padding: "12px", borderRadius: "12px" }}>
              <div className="row">
                {selItem?.nc_history?.length > 0 ? (
                  <div className="po_revision_history">
                    <div className="po_line"></div>

                    <div
                      className="row mx-auto"
                      style={{
                        marginTop: "-10px",
                        width: "100%",
                        overflowX: "auto",
                        fontSize: "14px",
                      }}
                    >
                      <div className="d-flex pb-2">
                        {selItem?.nc_history
                          .sort((a, b) => {
                            const dateA = new Date(a.updated_at);
                            const dateB = new Date(b.updated_at);
                            return dateB - dateA;
                          })
                          ?.map((val, ind, arr) => (
                            <div
                              className="mx-4"
                              key={ind}
                              style={{ width: "250px" }}
                            >
                              <FontAwesomeIcon
                                icon={faCircle}
                                style={{
                                  fontSize: "14px",
                                  color: "#4E7DAE",
                                }}
                              />

                              <div
                                className="card border-0 mt-3 cursor_pointer"
                                style={{
                                  background: "honeydew",
                                  minWidth: "250px",
                                }}
                              >
                                <div className="card-body">
                                  <div className="row">
                                    {/* First Part */}
                                    <div className="col">
                                      <p className="roboto-bold text-left small my-0 nc_detail_label">
                                        {constLabel?.lbl_status ? constLabel.lbl_status : "Status"}
                                      </p>
                                      <p className="roboto-bold text-left small">
                                        {val.status}
                                      </p>
                                    </div>

                                    {/* Second Part */}
                                    <div className="col">
                                      <p className="roboto-bold text-left small my-0 nc_detail_label">
                                        {constLabel?.lbl_updated_date ? constLabel.lbl_updated_date : "Updated Date"}
                                      </p>
                                      <p className="roboto-bold text-left small">
                                        {convertDate(val.status_update_date, "-")}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="row">
                                    {/* First Part */}
                                    <div className="">
                                      <p className="roboto-bold text-left small my-0 nc_detail_label">
                                        {constLabel?.lbl_nc_reason ? constLabel.lbl_nc_reason : "NC Reason"}
                                      </p>
                                      <p className="roboto-bold text-left small">
                                        {val.nc_reason}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="row">
                                    {/* First Part */}
                                    <div className="">
                                      <p className="roboto-bold text-left small my-0 nc_detail_label">
                                        {constLabel?.lbl_root_cause_operation ? constLabel.lbl_root_cause_operation : "Root Cause Operation"}
                                      </p>
                                      <p className="roboto-bold text-left small">
                                        {val.root_cause_operation}
                                      </p>
                                    </div>
                                  </div>
                                  
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="alert alert-danger mb-0 text-center my-1"
                    role="alert"
                  >
                    {"NC history not available !"}
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-grey px-4" onClick={() => setShow2(false)}>
              {constLabel?.lbl_close ? constLabel.lbl_close : "Close"}
            </button>
          </Modal.Footer>
        </Modal>
    </div>
  );
}

export default NC;
