import React, { useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import SweetAlert from "sweetalert2";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";
// import DatePicker from "react-datepicker";
import { Trash2 } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faArrowLeft,
  faCog,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getLabels } from "../../../../common/Functions/CommonFunctions";
import {
  get_available_conversion_qty,
  get_bar_length,
  get_conversion_sheet,
  save_conversionSheet,
  update_conversionSheet,
} from "../../../../api";

function RawMaterialConversion() {
  let navigate = useNavigate();
  let location = useLocation();

  const [constLabel, setConstLabel] = useState({});
  const [availConvQty, setAvailConvQty] = useState({});
  const [convSheet, setConvSheet] = useState();
  const [convSheet1, setConvSheet1] = useState();

  const [productFamily, setProductFamily] = useState([]);

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    setValue,
    getValues,
    setError
  } = useForm({
    // resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  function removeDuplicates(array, properties) {
    return array.filter((obj, index, self) =>
        index === self.findIndex((t) => (
            properties.every(property => t[property] === obj[property])
        ))
    );
}

  useEffect(() => {
    setConstLabel(getLabels("Master", "RMI"));

    if (location.state !== undefined && location.state !== null) {
      // location?.state?.toolinvenId
      get_available_conversion_qty({ rmi: location?.state?.toolinvenId }).then(
        (res) => {
          setAvailConvQty(res.data.data);

          const uniqueData = removeDuplicates(res.data?.data?.prodFamilyList, ['uuid', 'familyId']);
          // console.log("uniqueData", uniqueData);

          setValue(
            "material_type",
            location.state?.productDetails?.materialTypeDetail?.material_type
          );
          setValue("rmi_id", res.data.data.toolinvenId);
          setValue("material_code", res.data.data.material_code);
          setValue("product_name", res.data.data.productname);
          setValue("no_of_bars", res.data.data.qty);
          setValue("diameter", res.data.data.productDetails?.diameter);
          setValue("weight_per_meter", res.data.data.weight);

          //Conversion sheet api call
          if (res) {
            get_conversion_sheet({ rmi: location?.state?.toolinvenId }).then(
              (ress) => {
                // console.log("ress", ress);
                if (ress.data.data.length > 0) {
                  setConvSheet(ress.data.data);
                  ress.data?.data?.[0]?.products.forEach((fam) => {
                    const updatedData = uniqueData?.map(
                      (item) => {
                        if (item.familyId === fam.prodId) {
                          item.flag = true;
                          item.len = fam.length;
                          item.cutQty = fam.cutQty;
                        }
                        return item;
                      }
                    );
                    setProductFamily(updatedData);
                  });
                } else {
                  get_bar_length({
                    toolinvenId: location?.state?.toolinvenId,
                  }).then(
                    (res) => {
                      if (res) {
                        setConvSheet1(res.data.data);
                        // console.log("res bar length", res);
                      }
                    },
                    (err) => {
                      // console.log("err", err);
                      if (err.response.status == 500) {
                        toast.error(err.message, {
                          autoClose: 2000,
                        });
                      }
                    }
                  );

                  setProductFamily(uniqueData);
                }
              },
              (errr) => {
                // console.log("errr", errr);
                // console.log("errr.response", errr.response);
                if (errr.response.status == 500) {
                  toast.error(errr.message, {
                    autoClose: 2000,
                  });
                }
                if (errr.response.status == 404) {
                  if (errr.response.data.data.length == 0) {
                    get_bar_length({
                      toolinvenId: location?.state?.toolinvenId,
                    }).then(
                      (res) => {
                        if (res) {
                          setConvSheet1(res.data.data);
                          // console.log("res bar length", res);
                        }
                      },
                      (err) => {
                        // console.log("err", err);
                        if (err.response.status == 500) {
                          toast.error(err.message, {
                            autoClose: 2000,
                          });
                        }
                      }
                    );

                    setProductFamily(uniqueData);
                  }
                }
              }
            );
          }
        },
        (err) => {
          // console.log("err", err);
          if (err.response.status == 500) {
            toast.error(err.message, {
              autoClose: 2000,
            });
          }
          setAvailConvQty({});
        }
      );
    }
  }, []);

  useEffect(() => {
    var cutlot_arr = [];
    if (convSheet !== undefined && convSheet?.length > 0) {
      for (let i = 0; i < convSheet?.length; i++) {
        // console.log("convSheet", convSheet);
        // const products = convSheet[i].products.map((item) => ({
        //   cutQty: item.cutQty,
        // }));
        const products = productFamily?.map((prodItem) => {
          const matchingProd = convSheet[i].products?.find(
            (famItem) => famItem.prodId === prodItem.familyId
          );
          if (matchingProd) {
            return {
              cutQty: parseInt(matchingProd.cutQty),
              prodId: matchingProd.prodId,
              length: matchingProd.length,
              uuid: matchingProd.uuid,
              cutlotId: matchingProd.cutlotId,
            };
          }
        });

        // console.log("products", products);
        let index_of_prod_fam = products
          .map((item, ind) => {
            if (item !== undefined) {
              return ind;
            } else {
              return -1;
            }
          })
          .filter((item) => item !== -1);
        //  console.log("index_of_prod_fam", index_of_prod_fam);

        let arr_fam = productFamily.filter(
          (item, ind) => index_of_prod_fam.includes(ind) == true
        );
        // console.log("arr_fam", arr_fam);

        let value = 0;
        for (let j = 0; j < arr_fam.length; j++) {
          let bar_ind = convSheet[i].products.findIndex((obj) => {
            return (
              obj.hasOwnProperty("prodId") &&
              obj["prodId"] === arr_fam[j].familyId
            );
          });
          // console.log("bar_ind", bar_ind);

          if (bar_ind !== -1) {
            // console.log("bar_ind if",bar_ind)

            value +=
              convSheet[i].products[bar_ind].cutQty *
              convSheet[i].products[bar_ind].length;
          }
        }
        //  console.log("value",value)
        let wpm =0;
        if(availConvQty?.weight){
         wpm= parseFloat(availConvQty?.weight)
        }
        // let sumQty = convSheet[i].products.reduce(
        //   (accumulator, item) => parseInt(accumulator) + parseInt(item.cutQty),
        //   0
        // );
        // console.log("productFamily",productFamily);
        // console.log("convSheet", convSheet[i]);
        // console.log("convSheet[i].barLength", convSheet[i].barLength);

        cutlot_arr.push({
          uuid: convSheet[i].uuid,
          barNo: convSheet[i]?.barNo,
          barLength: convSheet[i]?.barLength,
          bar_weight: wpm==0 ? 0 : barCalc(i, convSheet[i]?.barLength),
          products: products,
          // end_piece_length: sumQty > 0 ? 0 : convSheet[i].barLength,
          end_piece_length: convSheet[i]?.barLength - value,
          end_piece_weight: wpm == 0 ? 0: (
            (wpm / 1000) *
            (parseInt(convSheet[i]?.barLength) - value)
          ).toFixed(2),
          stock_data: convSheet[i]?.stock_data
        });
      }
      // console.log("items", cutlot_arr);
      reset({ items: cutlot_arr });
    } else {
      return;
    }
  }, [convSheet]);

  useEffect(() => {
    var cutlot_arr = [];
    if (convSheet1 !== undefined && convSheet1?.length > 0) {
      for (let i = 0; i < convSheet1?.length; i++) {
        // console.log("convSheet1", convSheet1);
        cutlot_arr.push({
          uuid: convSheet1[i].uuid,
          barNo: convSheet1[i].bar_no,
          barLength: convSheet1[i].cutlength,
          bar_weight: barCalc(i, convSheet1[i].cutlength),
          end_piece_length: convSheet1[i].cutlength,
          end_piece_weight: barCalc(i, convSheet1[i].cutlength),
        });
      }
      // console.log("items", cutlot_arr);
      reset({ items: cutlot_arr });
    } else {
      return;
    }
  }, [convSheet1]);

  function barCalc(index, bl) {
    let wpm = parseFloat(availConvQty?.weight);

    if (wpm !== undefined && wpm !== 0 && bl !== undefined && bl !== "") {
      return ((wpm / 1000) * parseInt(bl)).toFixed(2);
    }
  }

  function barCalc2(index, bl) {
    let bw = getValues(`items[${index}].bar_weight`);
    let endPieceLen = getValues(`items[${index}].end_piece_length`);

    if (
      bw !== undefined &&
      bw !== 0 &&
      endPieceLen !== undefined &&
      endPieceLen !== ""
    ) {
      return ((parseFloat(endPieceLen) * bw) / parseInt(bl)).toFixed(2);
    } else {
      return 0;
    }
  }

  const onSubmit = (data) => {
    // console.log("data", data);
    let prod_details = [];

    let separatedValues = Object.keys(data)
      .filter(
        (key) =>
          key.startsWith("prod_length") && data[key] !== "0" && data[key] !== ""
      )
      .reduce((acc, key) => {
        let num = parseInt(key.replace("prod_length", ""));
        if (data[key] > 0 && data[key] !== "") {
          acc[num] = data[key];
        }
        return acc;
      }, {});

    // console.log("separatedValues",separatedValues);
    
    if (convSheet && convSheet.length > 0) {
      if (data.items && data.items?.length > 0) {
        for (let i = 0; i < data.items.length; i++) {


          // if(data.items?.[i]?.stock_data?.length > 0){
          //   continue;
          // }

          let obj = {
            uuid: data.items?.[i]?.uuid,
            barLength: data.items?.[i]?.barLength,
            // converID: 1,
            barNo: data.items?.[i]?.barNo,
            endPiece: (data.items?.[i]?.end_piece_length).toString(),
            products: [],
          };
          let arr = [];
          let separatevalues2 = { ...separatedValues };
          for (let j = 0; j < data.items[i].products.length; j++) {
            let item = data.items[i].products[j];
            let obj1 = {};

            //  console.log("item", item)
            if (item == undefined) {
              continue;
            }

            if (
              item &&
              item.prodId &&
              item.length &&
              item.cutQty &&
              item.uuid
            ) {
              obj1.prodId = item.prodId;
              obj1.length = item.length;
              obj1.uuid = item.uuid;
              obj1.cutlotId = item.cutlotId;
              if (item.cutQty1 > 0) {
                obj1.cutQty = Number(item.cutQty) + Number(item.cutQty1);
              } else {
                obj1.cutQty = Number(item.cutQty);
              }
            } else {
              //  console.log("item",item)

              let keys = Object.keys(separatevalues2);
              //  console.log("keys",keys.length)
              obj1.prodId = productFamily[`${keys[0]}`]?.familyId;
              obj1.length = separatevalues2[j];
              obj1.cutQty = Number(item.cutQty);

              obj1.uuid = item.uuid;
              obj1.cutlotId = item.cutlotId;
            }
            // console.log("obj1",obj1)
            if (separatevalues2[j]) {
              delete separatevalues2[j];
            }
            // console.log("separatevalues2",separatevalues2)

            arr.push(obj1);
          }
          //  console.log("arr",arr)
          obj.products = arr.filter((item) => item.length !== undefined);
          prod_details.push(obj);
        }
      }

      const payload = {
        rmi: data?.rmi_id,
        po_id_id: location.state?.po_id,
        poinward_id_id: location.state?.poinward_id_id,
        material_code: data?.material_code,
        material_type: data?.material_type,
        prodDetails: prod_details,
      };

      console.log("update payload", payload);

      update_conversionSheet(payload).then(
        (res) => {
          // console.log("res", res);
          toast.success("Raw material conversion updated successfully", {
            autoClose: 2000,
          });
          setTimeout(() => {
            navigate("/pcms/rmi");
          }, 2200);
        },
        (err) => {
          console.log("err", err);
          toast.error(err.response.data.message, { autoClose: 2000 });
        }
      );
    } else {
      if (data.items && data.items?.length > 0) {
        for (let i = 0; i < data.items.length; i++) {
          let obj = {
            uuid: data.items?.[i]?.uuid,
            barLength: data.items?.[i]?.barLength,
            // converID: 1,
            barNo: data.items?.[i]?.barNo,
            endPiece:
              data.items?.[i]?.end_piece_length !== undefined
                ? (data.items?.[i]?.end_piece_length).toString()
                : "",
            products: [],
          };
          let arr = [];
          let separatevalues2 = { ...separatedValues };
          for (let j = 0; j < data.items[i].products.length; j++) {
            let item = data.items[i].products[j];
            let obj1 = {};

            //  console.log("item",item)
            if (item == undefined) {
              continue;
            }

            if (item && item.prodId && item.length && item.cutQty) {
              obj1.prodId = item.prodId;
              obj1.length = item.length;
              if (item.cutQty1 > 0) {
                obj1.cutQty = Number(item.cutQty) + Number(item.cutQty1);
              } else {
                obj1.cutQty = Number(item.cutQty);
              }
            } else {
              //  console.log("item",item)

              let keys = Object.keys(separatevalues2);
              //  console.log("keys",keys.length)
              obj1.prodId = productFamily[`${keys[0]}`]?.familyId;
              obj1.length = separatevalues2[j];
              obj1.cutQty = Number(item.cutQty);
            }
            // console.log("obj1",obj1)
            if (separatevalues2[j]) {
              delete separatevalues2[j];
            }
            // console.log("separatevalues2",separatevalues2)

            arr.push(obj1);
          }
          //  console.log("arr",arr)
          obj.products = arr.filter((item) => item.length !== undefined);

          prod_details.push(obj);
        }
      }

      const payload = {
        rmi: data?.rmi_id,
        po_id_id: location.state?.po_id,
        poinward_id_id: location.state?.poinward_id_id,
        material_code: data?.material_code,
        material_type: data?.material_type,
        prodDetails: prod_details,
      };

      console.log("create payload", payload);

      save_conversionSheet(payload).then(
        (res) => {
          // console.log("res", res);
          toast.success("Raw material conversion created successfully", {
            autoClose: 2000,
          });
          setTimeout(() => {
            navigate("/pcms/rmi");
          }, 2200);
        },
        (err) => {
          console.log("err", err);
          toast.error(err.response.data.message, { autoClose: 2000 });
        }
      );
    }
  };

  function handleClick(ind) {
    // console.log("productFamily", productFamily);
    const updateProdFamily = productFamily?.map((val, i) => {
      if (ind === i) {
        // Toggle the flag for the clicked checkbox
        return { ...val, flag: !val.flag };
      }
      return val;
    });
    setProductFamily(updateProdFamily);
    // console.log("updateProdFamily", updateProdFamily);

    // setProductFamily((productFamily) => {
    //   const updateProdFamily = productFamily.map((val, i) => {
    //     if (ind === i) {
    //       // Toggle the flag for the clicked checkbox
    //       return { ...val, flag: !val.flag };
    //     }
    //     return val;
    //   });

    //   return updateProdFamily;
    // });
  }

  // bar weight calculation

  /*
    # weight per meter = wpm
    # bar weight = bw 

  */

  function bar_calc3(value, idx, index, title) {

    if(value < 0){
      toast.error("Enter valid cut quantity", { autoClose: 1000 });
      if (title == "CUTQTY1") {
        setValue(`items[${index}].products[${idx}].cutQty1`, 0);
      } else {
        setValue(`items[${index}].products[${idx}].cutQty`, 0);
      }
      setValue(`items[${index}].end_piece_length`, 0);
      setValue(`items[${index}].end_piece_weight`, 0);
      return;
    }

    let cnt = productFamily?.length;
    // console.log("value", value);
    // console.log("idx", idx);
    // console.log("index", index);
    // console.log("title", title);
    // console.log("productFamily", productFamily[idx]);
    // console.log("arr", convSheet[index]);
    let current_cut_piece_length = parseInt(getValues(`prod_length` + idx))
      ? parseInt(getValues(`prod_length` + idx)) * value
      : 0;
    let xyz = convSheet[index].products.find(
      (item) => item.prodId == productFamily[idx].familyId
    );

    if (title == "CUTQTY1" && xyz) {
      //  console.log("xyz", xyz);
      current_cut_piece_length +=
        parseInt(xyz.cutQty) * parseInt(getValues(`prod_length` + idx));
    }
    let remaining_bar_length = 0;
    for (let i = 0; i < cnt; i++) {
      if (i !== idx) {
        let a = parseInt(getValues(`prod_length` + i));

        if (a) {
          let b = parseInt(getValues(`items[${index}].products[${i}].cutQty`))
            ? parseInt(getValues(`items[${index}].products[${i}].cutQty`))
            : 0;
          if(title == "CUTQTY1"){
            b += parseInt(getValues(`items[${index}].products[${i}].cutQty1`))
            ? parseInt(getValues(`items[${index}].products[${i}].cutQty1`))
            : 0
          }

          remaining_bar_length += a * b;

        }
      }
    }
    // console.log("remaining_bar_length", remaining_bar_length);
    // console.log("current_cut_piece_length", current_cut_piece_length);
    let total = remaining_bar_length + current_cut_piece_length;
    if (parseInt(convSheet?.[index]?.barLength) < total) {
      toast.error("Enter less quantity than bar length", { autoClose: 1000 });
      if (title == "CUTQTY1") {
        setValue(`items[${index}].products[${idx}].cutQty1`, 0);
      } else {
        setValue(`items[${index}].products[${idx}].cutQty`, 0);
      }
    }

    const result = parseInt(convSheet?.[index]?.barLength) - total;
    const endPieceLength = result < 0 ? 0 : result;
    // console.log("endPieceLength", endPieceLength);
    setValue(`items[${index}].end_piece_length`, endPieceLength);
    setValue(
      `items[${index}].end_piece_weight`,
      barCalc(index, endPieceLength)
    );
  }

  function bar_calc4(value, idx, index, title) {
    let cnt = productFamily?.length;
    // console.log("value", value);
    // console.log("idx", idx);
    // console.log("index", index);
    // console.log("title", title);
    // console.log("productFamily", productFamily[idx]);
    // console.log("arr", convSheet1[index]);
    // console.log("prod_length", parseInt(getValues(`prod_length`+ idx)));

    let current_cut_piece_length = parseInt(getValues(`prod_length` + idx))
      ? parseInt(getValues(`prod_length` + idx)) * value
      : 0;

    // let xyz = convSheet1[index].products.find(
    //   (item) => item.prodId == productFamily[idx].familyId
    // );

    // if (title == "CUTQTY1" && xyz) {
    //   // console.log("xyz", xyz);
    //   current_cut_piece_length +=
    //     parseInt(xyz.cutQty) * parseInt(getValues(`prod_length` + idx));
    // }

    let remaining_bar_length = 0;
    for (let i = 0; i < cnt; i++) {
      if (i !== idx) {
        let a = parseInt(getValues(`prod_length` + i));

        if (a) {
          let b = parseInt(getValues(`items[${index}].products[${i}].cutQty`))
            ? parseInt(getValues(`items[${index}].products[${i}].cutQty`))
            : 0;
          remaining_bar_length += a * b;
        }
      }
    }
    // console.log("remaining_bar_length", remaining_bar_length);
    // console.log("current_cut_piece_length", current_cut_piece_length);
    let total = remaining_bar_length + current_cut_piece_length;

    if (parseInt(convSheet1?.[index]?.cutlength) < total) {
      toast.error("Enter less quantity than bar length", { autoClose: 1000 });
      if (title == "CUTQTY1") {
        setValue(`items[${index}].products[${idx}].cutQty1`, 0);
      } else {
        setValue(`items[${index}].products[${idx}].cutQty`, 0);
      }
    }

    const cutLen = parseInt(convSheet1?.[index]?.cutlength);
    const result = cutLen - total;
    const endPieceLength = result < 0 ? 0 : result;
    // setValue(`items[${index}].bar_weight`, barCalc(index, cutLen));
    setValue(`items[${index}].end_piece_length`, endPieceLength);
    setValue(
      `items[${index}].end_piece_weight`,
      barCalc(index, endPieceLength)
    );
  }


  let timeoutId = "";

function changeProdLength(value, idx){
    // Clear the previous timeout
    clearTimeout(timeoutId);

    // Set a new timeout to call the function after 500 milliseconds
    timeoutId = setTimeout(() => {
        let regex = /^\d+$/;

        if (!regex.test(value)) {
          toast.error('Please enter valid cut length !',{
            autoClose: 500
          })
          setValue(`prod_length` + idx, "");
        }
        
        // Use optional chaining to safely access the length property of convSheet
        let arr = convSheet?.length > 0 ? convSheet : convSheet1;
       
        // Extract barLength values as numbers
        const barLengths = arr.map(item => parseInt(item.barLength) || parseInt(item.cutlength) );
      
        // Find the smallest and largest values
        // const smallest = Math.min(...barLengths);
        const largest = Math.max(...barLengths);

        if (value > largest) {
          // Update barLength field if necessary
              toast.error('Please enter valid cut length !',{
                autoClose: 500
              })
          setValue(`prod_length` + idx, "");
        }
      
        // Iterate through arr using forEach for cleaner code
        arr?.forEach((item, i) => {
          setValue(`items[${i}].products[${idx}].cutQty`, 0);
        });
    }, 500); // 500 milliseconds debounce time
}

  
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                SweetAlert.fire({
                  title: "Are you sure?",
                  text: "Your present data will be lost!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, go back!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.onpopstate = undefined;

                    setTimeout(() => {
                      navigate(-1);
                    }, 1000);
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_raw_material_conversion
                ? constLabel?.lbl_raw_material_conversion
                : "Raw Material Conversion"}
            </p>
          </div>
          <div className="card-body">
            <form action="">
              <h4 className="bold">
                {constLabel?.lbl_material_overview
                  ? constLabel?.lbl_material_overview
                  : "Material Overview"}
              </h4>
              <div className="row mt-3">
                <div className="col-12 col-sm-3">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_material_type
                        ? constLabel?.lbl_material_type
                        : "Material Type"}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      className={"form-control mt-2"}
                      type="text"
                      id="material_type"
                      name="material_type"
                      readOnly
                      {...register("material_type")}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-3">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_rmi_id
                        ? constLabel?.lbl_rmi_id
                        : "RMI Id"}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      className={"form-control mt-2"}
                      type="text"
                      id="rmi_id"
                      name="rmi_id"
                      readOnly
                      {...register("rmi_id")}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-3">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_material_code
                        ? constLabel?.lbl_material_code
                        : "Material Code"}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      className={"form-control mt-2"}
                      type="text"
                      id="material_code"
                      name="material_code"
                      readOnly
                      {...register("material_code")}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-3">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_product_name
                        ? constLabel?.lbl_product_name
                        : "Product Name"}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      className={"form-control mt-2"}
                      type="text"
                      id="product_name"
                      name="product_name"
                      readOnly
                      {...register("product_name")}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-3 mt-3">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_no_of_bars
                        ? constLabel?.lbl_no_of_bars
                        : "No. of Bars"}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      className={"form-control mt-2"}
                      type="text"
                      id="no_of_bars"
                      name="no_of_bars"
                      readOnly
                      {...register("no_of_bars")}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-3 mt-3">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_diameter
                        ? constLabel?.lbl_diameter
                        : "Diameter"}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      className={"form-control mt-2"}
                      type="text"
                      id="diameter"
                      name="diameter"
                      readOnly
                      {...register("diameter")}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-3 mt-3">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_weight_per_meter
                        ? constLabel?.lbl_weight_per_meter
                        : "Weight per meter"}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      className={"form-control mt-2"}
                      type="text"
                      id="weight_per_meter"
                      name="weight_per_meter"
                      readOnly
                      {...register("weight_per_meter")}
                    />
                  </div>
                </div>
              </div>
              <hr className="px-2" />
              <div className="d-sm-flex justify-content-between align-items-center">
                <h4 className="bold">
                  {constLabel?.lbl_bar_conversion
                    ? constLabel?.lbl_bar_conversion
                    : "Bar Conversion"}
                </h4>
                <div className="dropdown">
                  <div
                    className="settignIcon_custom_nc d-flex justify-content-center align-items-center"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <FontAwesomeIcon
                      style={{ color: "#fff" }}
                      icon={faCog}
                      size="sm"
                    />
                  </div>

                  <ul className="dropdown-menu px-2" style={{ maxHeight: "70vh", overflowY: "scroll" }}>
                    {productFamily !== undefined && productFamily.length > 0
                      ? productFamily.map((val, ind, arr) => (
                          <li key={ind}>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                style={{ width: "20px", height: "20px" }}
                                onChange={() => handleClick(ind)}
                                value=""
                                checked={val.flag}
                              />
                              <label
                                className=""
                                style={{ fontSize: "13px" }}
                                htmlFor="flexCheckDefault"
                              >
                                {val.familyname}
                              </label>
                            </div>
                          </li>
                        ))
                      : null}
                  </ul>
                </div>
              </div>

              <div
                className="mt-3"
                style={{ overflow: "scroll", maxHeight: "60vh" }}
              >
                <table className="table table-responsive table-bordered table-sm">
                  <thead>
                    <tr>
                      <th rowSpan={"2"}>{"Bar No"}</th>
                      <th rowSpan={"2"}>{"Bar Length"}</th>
                      <th rowSpan={"2"}>{"Bar Weight"}</th>
                      {productFamily &&
                        productFamily.map(
                          (item, index) =>
                            item.flag && (
                              <th width={"15%"} key={index}>
                                {item.familyname}
                              </th>
                            )
                        )}
                      <th rowSpan={"2"}>{"End Piece Length"}</th>
                      <th rowSpan={"2"}>{"End Piece Weight"}</th>
                    </tr>
                    <tr>
                      {productFamily &&
                        productFamily?.map(
                          (item, index) =>
                            item.flag && (
                              <td key={index}>
                                <input
                                  className={"form-control form-control-sm"}
                                  type="text"
                                  name={`prod_length` + index}
                                  defaultValue={
                                    item.len && item.len > 0 ? item.len : 0
                                  }
                                  readOnly={
                                    item.len && item.len > 0 ? true : false
                                  }
                                  {...register(`prod_length` + index)}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    changeProdLength(value, index);
                                  }}
                                  // {...register(`prod_length` + index, {
                                  //   onChange: (e) => changeProdLength(e.target.value, index)
                                  // })}
                                />
                              </td>
                            )
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map(({ id }, index) => (
                      <tr key={id} className="">
                        {/* {console.log("fields", fields)} */}
                        <td className="p-1" style={{width: "15%"}}>
                          <input
                            className={"form-control form-control-sm"}
                            type="text"
                            name={`items[${index}].barNo`}
                            {...register(`items[${index}].barNo`)}
                            disabled
                          />
                        </td>

                        <td className="p-1">
                          <input
                            className={"form-control form-control-sm"}
                            type="text"
                            name={`items[${index}].barLength`}
                            {...register(`items[${index}].barLength`)}
                            disabled
                          />
                        </td>
                        <td className="p-1">
                          <input
                            className={"form-control form-control-sm"}
                            type="text"
                            name={`items[${index}].bar_weight`}
                            {...register(`items[${index}].bar_weight`)}
                            disabled
                          />
                        </td>
                        {productFamily?.map(
                          (item, idx) =>
                            item.flag && (
                              <td className="p-1" key={idx}>
                                <tr>
                                  <td>
                                    <input
                                      className={"form-control form-control-sm"}
                                      type="text"
                                      name={`items[${index}].products[${idx}].cutQty`}
                                      // disabled={
                                      //   fields?.[index]?.stock_data?.length > 0 ||
                                      //   (fields?.[index]?.products?.[idx]
                                      //     ?.cutQty &&
                                      //   item.cutQty &&
                                      //   item.cutQty > "0") 
                                       
                                      //     ? true
                                      //     : false
                                      // }
                                      {...register(
                                        `items[${index}].products[${idx}].cutQty`,
                                        {
                                          onChange: (e) => {
                                            if (convSheet?.length > 0) {
                                              bar_calc3(
                                                e.target.value,
                                                idx,
                                                index,
                                                "CUTQTY"
                                              );
                                            } else {
                                              bar_calc4(
                                                e.target.value,
                                                idx,
                                                index,
                                                "CUTQTY"
                                              );
                                            }
                                          },
                                        }
                                      )}
                                    />
                                  </td>

                                  <td>
                                    <input
                                      className={
                                        fields?.[index]?.products?.[idx]
                                          ?.cutQty &&
                                        item?.cutQty &&
                                        item?.cutQty > "0"
                                          ? "form-control form-control-sm"
                                          : "form-control form-control-sm invisible"
                                      }
                                      type="text"
                                      name={`items[${index}].products[${idx}].cutQty1`}
                                      disabled={fields?.[index]?.stock_data?.length > 0 ? true : false}
                                      {...register(
                                        `items[${index}].products[${idx}].cutQty1`,
                                        {
                                          onChange: (e) => {
                                              bar_calc3(
                                                e.target.value,
                                                idx,
                                                index,
                                                "CUTQTY1"
                                              );
                                          },
                                        }
                                      )}
                                    />
                                  </td>
                                </tr>
                              </td>
                            )
                        )}
                        <td className="p-1">
                          <input
                            className={"form-control form-control-sm"}
                            type="text"
                            name={`items[${index}].end_piece_length`}
                            {...register(`items[${index}].end_piece_length`)}
                            disabled
                          />
                        </td>
                        <td className="p-1">
                          <input
                            className={"form-control form-control-sm"}
                            type="text"
                            name={`items[${index}].end_piece_weight`}
                            {...register(`items[${index}].end_piece_weight`)}
                            disabled
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </form>
          </div>

          <div className="card-footer py-3">
            <button
              onClick={handleSubmit(onSubmit)}
              className="btn btn-grey px-3 float-end"
              // disabled={productFamily && productFamily > 0 ? false : true}
            >
              {constLabel?.lbl_save ? constLabel?.lbl_save : "Save"}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RawMaterialConversion;
