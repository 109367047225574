// All label constants
export const custom_name = {
  lbl_product: "Product",
  lbl_view_product: "View Product",
  lbl_create_product: "Create Product",

  lbl_customer_type: "Customer Type",
  lbl_add_customer_type: "Add Customer Type",
  lbl_update_customer_type: "Update Customer Type",

  lbl_supplier_type: "Supplier Type",
  lbl_add_supplier_type: "Add Supplier Type",
  lbl_update_supplier_type: "Update Supplier Type",

  lbl_operation_category: "Operation Category",
  lbl_add_operation_category: "Add Operation Category",
  lbl_update_operation_category: "Update Operation Category",
  lbl_category_name: "Category Name",

  lbl_operation: "Operation",
  lbl_add_operation: "Add Operation",
  lbl_update_operation: "Update Operation",
  lbl_operation_name: "Operation Name",

  lbl_store: "Store",
  lbl_add_store: "Add Store",
  lbl_update_store: "Update Store",
  lbl_store_name: "Store Name",
  lbl_store_type: "Store Type",
  lbl_organization: "Organization",

  lbl_location: "Location",
  lbl_add_location: "Add Location",
  lbl_update_location: "Update Location",
  lbl_location_name: "Location Name",

  lbl_location_type: "Location Type",

  lbl_party_name: "Party Name",

  lbl_company_name: "Company Name",

  lbl_filter_menu: "Filter Menu",
  lbl_clear_filter: "Clear Filter",

  lbl_name: "Name",
  lbl_category: "Category",
  lbl_address: "Address",

  lbl_save: "Save",
  lbl_update: "Update",
  lbl_download_csv: "Download CSV",
};

// Table headings for NC Master
export const NC_Col_Name = [
  { label: "Date", flag: true },
  { label: "Camshaft", flag: true },
  { label: "NC Tag", flag: true },
  { label: "Lot No", flag: true },
  { label: "Work Order No", flag: true },
  { label: "Lot Size", flag: true },
  { label: "NC Reason", flag: true },
  { label: "Disposal Action", flag: true },
  { label: "Job No", flag: true },
  { label: "Operation", flag: false },
  { label: "Operation In Which", flag: false },
  { label: "Operator", flag: false },
  { label: "Part No", flag: false },
  { label: "Quantity", flag: false },
  { label: "RMI Code", flag: false },
  { label: "Selling Price", flag: false },
];

export const Purchase_Order_Col_name = [
  { label: "PO Number", flag: true },
  { label: "Supplier", flag: true },
  { label: "Purchase Date", flag: true },
  { label: "Value", flag: false },
  { label: "Status", flag: true },
  { label: "Invoice To", flag: true },
  { label: "Disptach To", flag: true },
  { label: "Quote Ref", flag: true },
  { label: "Product Name", flag: true },
  { label: "Quantity", flag: true },
  { label: "Unit", flag: true },
  { label: "Remark", flag: false },
  { label: "State", flag: false },
  { label: "GSTN", flag: false },
  { label: "Client PO No", flag: false },
  { label: "Credit Period", flag: false },
];
// Table headings for Product Master
export const Product_Col_Name = [
  { label: "Product Name", flag: true },
  { label: "OEM Part No", flag: true },
  { label: "Part No", flag: false },
  { label: "Product Type", flag: true },
  { label: "Product Family", flag: true },
  { label: "Product Category", flag: true },
  { label: "HSN", flag: false },
  { label: "Has BOM", flag: false },
  { label: "Can Be Expensed", flag: false },
  { label: "Can Be Purchased", flag: false },
  { label: "Can Be Sold", flag: false },
  { label: "Material", flag: false },
  { label: "Sales Price", flag: false },
  { label: "Selling Price", flag: false },
  { label: "Purchase Price", flag: false },
  { label: "Remark", flag: false },
];

// Table headings for Customer Master
export const Customer_Col_Name = [
  { label: "Company Name", flag: true },
  { label: "Customer Type", flag: true },
  { label: "Contact No", flag: true },
  { label: "Email ID", flag: true },
  { label: "Website", flag: true },
  { label: "GST Type", flag: false },
  { label: "GST No", flag: false },
  { label: "PAN No", flag: false },
  { label: "Vendor Code", flag: false },
  { label: "Cutomer Name", flag: false },
  { label: "Contact Person Name", flag: false },
  { label: "Country", flag: false },
  { label: "State", flag: false },
  { label: "City", flag: false },
];

// Table headings for Supplier Master
export const Supplier_Col_Name = [
  { label: "Supplier Name", flag: true },
  { label: "Supplier Type", flag: true },
  { label: "Contact No", flag: true },
  { label: "Email ID", flag: true },
  { label: "Website", flag: true },
  { label: "GST Type", flag: false },
  { label: "GST No", flag: false },
  { label: "PAN No", flag: false },
  { label: "Vendor Code", flag: false },
  { label: "Contact Person Name", flag: false },
  { label: "Country", flag: false },
  { label: "State", flag: false },
  { label: "City", flag: false },
];

// Table headings for Orgnization Master
export const Org_Col_Name = [
  { label: "Parent Name", flag: true },
  { label: "Company Name", flag: true },
  { label: "Company Address", flag: true },
  { label: "Company Type", flag: true },
  { label: "First Name", flag: true },
  { label: "Last Name", flag: true },
  { label: "Email", flag: true },
  { label: "Phone", flag: true },
  { label: "Location", flag: false },
  { label: "Website", flag: false },
  { label: "GST Type", flag: false },
  { label: "GST No", flag: false },
  { label: "CIN No", flag: false },
  { label: "UAN No", flag: false },
  { label: "Industry", flag: false },
  { label: "LinkedIn", flag: false },
];

// Table headings for Sales Order Master
export const Sales_Order_Col_Name = [
  { label: "SO Id", flag: true },
  { label: "Customer PO Ref", flag: true },
  { label: "Order Date", flag: true },
  { label: "Order Due Date", flag: true },
  { label: "Invoice To", flag: true },
  { label: "Dispatch To", flag: true },
  { label: "Organization Name", flag: true },
  { label: "Customer", flag: true },
  { label: "Status", flag: true },
  { label: "Quote Ref", flag: false },
  { label: "Value", flag: false },
  { label: "Internal Remark", flag: false },
];

// Table headings for Purchase Inward
export const Purchase_Inward_Col_Name = [
  { label: "PO No", flag: true },
  { label: "Voucher No", flag: true },
  { label: "Client PO No", flag: false },
  { label: "Invoice Date", flag: true },
  { label: "Invoice To", flag: false },
  { label: "Supplier Invoice No", flag: true },
  { label: "Dispatch To", flag: false },
  { label: "Supplier Name", flag: true },
  { label: "Store Name", flag: false },
  { label: "Location Name", flag: false },
  { label: "Tally Voucher Date", flag: false },
  { label: "Tally Voucher No", flag: false },
];

// Table headings for Supplier Quotation
export const Supplier_Quotation_Col_Name = [
  { label: "Quote Id", flag: true },
  { label: "Date", flag: true },
  { label: "Supplier Name", flag: true },
  { label: "Quotation For", flag: true },
  { label: "Value", flag: false },
  { label: "Status", flag: true },
  { label: "Remark", flag: false },
];

// Table headings for Customer Type
export const Customer_Type_Col_Name = [{ label: "Customer Type", flag: true }];

// Table headings for Supplier Type
export const Supplier_Type_Col_Name = [{ label: "Supplier Type", flag: true }];

export const Cutlot_Col_Name = [
  { label: "Cutlot Id", flag: true },
  { label: "Material Code", flag: true },
  { label: "Raw Material Id", flag: true },
  { label: "Type", flag: true },
  { label: "Product Name", flag: true },
  { label: "Cutlot Available", flag: true },
  { label: "Processed Qty", flag: true },
  { label: "Cut piece qty", flag: true },
  { label: "Cut piece length", flag: true },
];

// Table headings for Operation Category
export const Operation_Category_Col_Name = [
  { label: "Category Name", flag: true },
];

// Table headings for Operation
export const Operation_Col_Name = [
  { label: "Operation Name", flag: true },
  { label: "Category Name", flag: true },
];

// Table headings for Location Type
export const Location_Type_Col_Name = [{ label: "Location Type", flag: true }];

// Table headings for Location Type
export const Location_Col_Name = [
  { label: "Location Type", flag: true },
  { label: "Location Name", flag: true },
  { label: "Party Name", flag: true },
  { label: "Company Name", flag: true },
  { label: "Address", flag: false },
];

// Table headings for Machine Category
export const Machine_Category_Col_Name = [
  { label: "Category Name", flag: true },
  { label: "Remark", flag: true },
];

// Table headings for Task
export const Task_Col_Name = [
  { label: "Task Name", flag: true },
  { label: "Category Name", flag: true },
  { label: "Start Date", flag: true },
  { label: "End Date", flag: true },
  { label: "Status", flag: true },
  { label: "Machine Name", flag: false },
  { label: "Assign To Name", flag: false },
  { label: "Service By Name", flag: false },
  { label: "Service Cost", flag: false },
  { label: "Comment", flag: false },
];

// Table headings for Task Category
export const Task_Category_Col_Name = [
  { label: "Category Name", flag: true },
  { label: "Remark", flag: true },
];

// Table headings for Disposal Action
export const Disposal_Action_Col_Name = [
  { label: "Disposal Action", flag: true },
];

// Table headings for NC Reason
export const NC_Reason_Col_Name = [{ label: "NC Reason", flag: true }];

// Table headings for Bill of Material
export const BOM_Col_Name = [
  { label: "BOM Id", flag: true },
  { label: "Product Name", flag: true },
  { label: "Product Type", flag: true },
  { label: "Product Family", flag: true },
  { label: "BOM Type", flag: true },
  { label: "Quantity", flag: true },
  { label: "Remark", flag: false },
];

// Table headings for Bill of Material
export const DocumentTypeTitles = [{ label: "Name", flag: true }];

// Table headings for GST Treatment
export const GST_Treatment_Col_Name = [{ label: "GST Registration Type", flag: true }];

// Table headings for GST Slab
export const GST_Slab_Col_Name = [
  { label: "Tax Name", flag: true },
  { label: "Tax Type", flag: true },
  { label: "Rate", flag: true },
];

// Table headings for Company Type
export const Company_Type_Col_Name = [{ label: "Company Type", flag: true }];

// Table headings for Organization Type
export const Organization_Type_Col_Name = [
  { label: "Organization Type", flag: true },
];

// Table headings for Store Type
export const Store_Type_Col_Name = [{ label: "Store Type", flag: true }];

// Table headings for Store
export const Store_Col_Name = [
  { label: "Store Name", flag: true },
  { label: "Store Type", flag: true },
  { label: "Internal Company", flag: true },
  { label: "Party Name", flag: true },
];

// Table headings for Store
export const Transaction_Col_Name = [
  { label: "Transaction Name", flag: true },
  { label: "Transaction Code", flag: true },
  { label: "Transaction Action", flag: true },
  { label: "Description", flag: true },
];

// Table headings for Status
export const Status_Col_Name = [
  { label: "Status Name", flag: true },
  { label: "Model Name", flag: true },
];

// Table headings for User
export const User_Col_Name = [
  { label: "User Name", flag: true },
  { label: "Email", flag: true },
  { label: "Mobile No", flag: true },
];

// Table headings for Roles
export const Roles_Col_Name = [{ label: "Role Name", flag: true }];

export const Work_Order_Col_Name = [
  { label: "Sales Order", flag: true },
  { label: "Work Order No", flag: true },
  { label: "Product Name", flag: true },
  { label: "Start Date", flag: true },
  { label: "End Date", flag: true },
  { label: "OEM Part No", flag: true },
  { label: "Quantity", flag: true },
  { label: "NC Count", flag: false },
  { label: "Final Inspection Qty", flag: false },
  { label: "Dispatched Qty", flag: false },
  { label: "Status", flag: true },
  { label: "Unit", flag: false },
  { label: "Lot No", flag: false },
  { label: "Job From", flag: false },
  { label: "Job To", flag: false },
  { label: "Remark", flag: false },
];

// Table Heading for Job Card
export const Job_Col_name = [
  { label: "Work Order No", flag: true },
  { label: "Work Order Qty", flag: true },
  { label: "Job Card No", flag: true },
  { label: "Lot No", flag: false },
  { label: "Product Name", flag: true },
  { label: "Product Operation", flag: true },
  { label: "Machine", flag: true },
  { label: "Operator", flag: true },
  { label: "Start Date", flag: true },
  { label: "End Date", flag: true },
  { label: "Planned Quantity", flag: true },
  { label: "Actual Quantity", flag: true },
  { label: "Production Efficiency", flag: false },
  { label: "NC Qty", flag: false },
  { label: "Quality Efficiency", flag: false },
  { label: "Total Time", flag: false },
  { label: "Start Time", flag: false },
  { label: "End Time", flag: false },
  { label: "Remark", flag: false },
];

// Table Heading for Production Date
export const Production_Data_Col_name = [
  { label: "Work Order No", flag: true },
  { label: "Work Order Qty", flag: true },
  { label: "Job Card No", flag: true },
  { label: "Lot No", flag: true },
  { label: "Lot Size", flag: false },
  { label: "Product Name", flag: true },
  { label: "Part No", flag: false },
  { label: "Operation", flag: true },
  { label: "Machine Id", flag: false },
  { label: "Operator Name", flag: true },
  { label: "Start Date", flag: true },
  { label: "End Date", flag: true },
  { label: "Shift", flag: false },
  { label: "No of Hours Worked", flag: false },
  // { label: "Std Production Report", flag: true },
  { label: "Planned Qty", flag: true },
  // { label: "Production Qty", flag: true },
  { label: "Produced Qty", flag: true },
  { label: "Rejected Qty", flag: false },
  { label: "Remark", flag: false },
];

// Table headings for Product Master
export const Emp_Col_Name = [
  { label: "Emp Id", flag: false },
  { label: "Emp Card Id", flag: true },
  { label: "Name", flag: true },
  { label: "Designation", flag: true },
  { label: "Department", flag: false },
  { label: "Sub Department", flag: false },
  { label: "Emp Type", flag: true },
  { label: "Contact No", flag: true },
  { label: "Gender", flag: true },
  { label: "Location", flag: true },
];

// Table headings for Product Family Master
export const Product_Family_Col_Name = [{ label: "Family Name", flag: true }];

// Table headings for Product Category Master
export const Product_Category_Col_Name = [
  { label: "Category Name", flag: true },
];

//Table headings for Tool Type

export const Tool_Type_Col_Name = [
  { label: "Tool Category", flag: true },
  { label: "Tool Type Name", flag: true },
  { label: "Tool Type Code", flag: true },
  { label: "Description", flag: true },
];

//Table headings for Too Sub-type

export const Tool_Sub_Type_Col_name = [
  { label: "Tool Type", flag: true, },
  { label: "Tool Sub-Type Code", flag: true, },
  { label: "Tool Sub-Type Name", flag: true, },
  { label: "Description", flag: true, }
];

// Table headings for Tool Grade

export const Tool_Grade_Col_Name = [
  { label: "Tool Grade", flag: true },
];

// Table headings for RMI Master
export const RMI_Col_Name = [
  { label: "RMI Id", flag: true },
  { label: "PO Number", flag: false },
  { label: "Material Code", flag: true },
  { label: "Inward Date", flag: true },
  { label: "Material Type", flag: true },
  // { label: "PO/SO No", flag: true },
  { label: "Product Name", flag: true },
  { label: "Challan No", flag: true },
  { label: "Inspection Status", flag: true },
  { label: "Available Qty", flag: true },
  { label: "Inward Qty", flag: true },
  { label: "Supplier Name", flag: false },
  { label: "Heat No", flag: false },
  { label: "Price", flag: false },
  { label: "Adj", flag: false },
  { label: "Forwarding", flag: false },
  { label: "Freight", flag: false },
  { label: "Grand Amt", flag: false },
  { label: "GST", flag: false },
  { label: "Invoice Date", flag: false },
  { label: "Net Amt", flag: false },
  { label: "Weight RCPL", flag: false },
  { label: "Weight Transport", flag: false },
  { label: "Total", flag: false },
];

export const Challan_Type_Col_Name = [
  { label: "Name", flag: true, },
  { label: "Challan Type", flag: true, },
  { label: "ID Prefix", flag: true, },
  { label: "Out Operation", flag: true, },
  { label: "Same Location", flag: true, },
  { label: "WIP", flag: true, },
  { label: "Remark", flag: true, }
];
//Table headings from Goods Challan 

export const Goods_Challan_COL_Name = [
  { label: "Good Challan Id", flag: true },
  { label: "Challan Type", flag: true },
  { label: "Challan Reference", flag: true },
  { label: "From Location", flag: true },
  { label: "To Location", flag: true },
  { label: "Date", flag: true },
  { label: "Status", flag: true },
]

// Table headings for Inspection Type Master
export const Inspection_Type_Col_Name = [{ label: "Inspection Type", flag: true }];

// Table headings for Stock availability report
export const Stock_Avail_Col_Name = [
  { label: "Part No", flag: true },
  { label: "Product", flag: true },
  { label: "Quantity", flag: true },
  { label: "Inventory Value", flag: true},
  { label: "Last Update Date", flag: true }
];

// Table headings for Stock availability report
export const Stock_Ledger_Col_Name = [
  { label: "Product", flag: true},
  { label: "Part No /Bar No", flag: true},
  { label: "Receipt / Issue Date", flag: true},
  { label: "Transaction", flag: true},
  { label: "Transaction Type", flag: true},
  { label: "Remark", flag: true},
  { label: "Receipt Qty", flag: true},
  { label: "Issue Qty", flag: true},
  { label: "Location", flag: false},
  { label: "Store", flag: true},
  { label: "Inventory Value", flag: true},
  { label: "Stock Before", flag: false},
  { label: "Stock After", flag: false},
];

// Table headings for Dispatch
export const Dispatch_Col_Name = [
  { label: "Dispatch Id", flag: true },
  { label: "Dispatch Date", flag: true },
  { label: "Customer Name", flag: true },
  { label: "Transporter", flag: true },
  { label: "Status", flag: true },
];

//Table headings for HSN Master
export const HSN_Master_Col_Name = [
  { label: "HSN SAC Code", flag: true },
  { label: "GST Rate", flag: true },
  { label: "Description", flag: true },

];

// Table headings for Corrective Action 
export const Corrective_Action_Col_Name = [
  { label: "Corrective Action", flag: true },
  { label: "Remark", flag: true }
];

// Table headings for Preventive Action 
export const Preventive_Action_Col_Name = [
  { label: "Preventive Action", flag: true },
  { label: "Remark", flag: true }
];

// Table headings for Material Type 
export const Material_Type_Col_Name = [
  { label: "Material Type", flag: true },
  { label: "Material Description", flag: true }
];




export const prod_plan_col_list = [
  { label: "Prod Plan Id", flag: true },
  { label: "Plan Date", flag: true },
  { label: "Organization", flag: true },
  { label: "Location", flag: true },
  { label: "Ignore Available Qty", flag: false },
  { label: "Resource Planning", flag: false },
  { label: "Material Resource Planning", flag: false },
  { label: "Status", flag: true },
  { label: "Remarks", flag: false },
];
//Table headings for Adjustment Reason
export const Adjustment_Reason_Col_Name = [{ label: "Adjustment Reason", flag: true }];

//Table heading of Material Request 
export const Material_Request_Col_name = [
  { label: "MR Number", flag: true },
  { label: "Material Request Date", flag: true },
  { label: "Status", flag: true },
  { label: "Remark", flag: true },
];

export const Rounting_Col_name = [
  { label: "Route Name", flag: true}
];



