import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import {
  getCountry,
  getState,
  getCity,
  get_country,
  get_state,
  get_city,
} from "../../../../../api/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getLabels } from "../../../../../common/Functions/CommonFunctions";
import { useLocation, useNavigate } from "react-router-dom";

const schema = yup
  .object()
  .shape({
    address: yup.string().required("Address is required !"),
    cCountry: yup
      .array()
      .min(1, "Please select country")
      .required("Please select country"),
    cState: yup
      .array()
      .min(1, "Please select state")
      .required("Please select state"),
    cCity: yup
      .array()
      .min(1, "Please select city")
      .required("Please select city"),
    postalCode: yup
      .string()
      .matches(/^[0-9]*$/, "Enter valid postal code")
      .notRequired(),
    faxNo: yup
      .string()
      .matches(/^[0-9() -]*$/, "Enter valid fax number")
      .notRequired(),
  })
  .required();

function NewAddressDetail(props) {
  let navigate = useNavigate();
  let location = useLocation();
  const [countryList, setCountryList] = useState({ data: [], state: false });
  const [stateList, setStateList] = useState({ data: [], state: false });
  const [cityList, setCityList] = useState({ data: [], state: false });

  const [constLabel, setConstLabel] = useState({});

  const { register, handleSubmit, formState, control, reset, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  const selectedCountry = watch("cCountry");
  const selectedState = watch("cState");

  let { errors } = formState;

  useEffect(() => {
    // resetting the form
    setConstLabel(getLabels("Party Master", "Supplier"));
    reset();
    setCountryList({ ...countryList, state: true, data: [] });

    get_country().then(
      (res) => {
        setCountryList({
          ...countryList,
          state: false,
          data: res.data.data,
        });
      },
      (err) => {
        //
        setCountryList({ ...countryList, state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }, []);

  useEffect(() => {
    // fetching states for selected country through api
    if (selectedCountry !== undefined && selectedCountry.length !== 0) {
      setStateList({ ...stateList, data: [], state: true });

      const payload = {
        country_id: selectedCountry[0]?.uuid,
      };
      get_state(payload).then(
        (res) => {
          // console.log("res", res);
          setStateList({
            ...stateList,
            data: res.data.data,
            state: false,
          });
          if(location.state !== null && location.pathname !== "/pcms/editSupplier"){
            let result = res.data.data.filter((item) => item.countryName === "India");
            setValue("cCountry", result);
          }
        },
        (err) => {
          setStateList({ ...stateList, data: [], state: false });
        }
      );
    } 
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState !== undefined && selectedState.length > 0) {
      setCityList({ ...cityList, data: [], state: true });

      const payload = {
        state_id: selectedState[0]?.uuid,
      };

      get_city(payload).then(
        (res) => {
          setCityList({ ...cityList, data: res.data.data, state: false });
        },
        (err) => {
          setCityList({ ...cityList, data: [], state: false });
        }
      );
    } 
  }, [selectedState]);

  const onSubmit = (data) => {
    if (data !== "") {
      const address = {
        address: data.address,
        address2: data.address1,
        country: data.cCountry[0]?.id,
        state: data.cState[0]?.id,
        city: data.cCity[0]?.id,
        country_name: data.cCountry[0]?.countryName,
        state_name: data.cState[0]?.stateName,
        city_name: data.cCity[0]?.cityName,
        country_id: data.cCountry[0]?.uuid,
        state_id: data.cState[0]?.uuid,
        city_id: data.cCity[0]?.uuid,
        faxNo: data.faxNo,
        postalCode: data.postalCode,
      };
      props.setMain_obj({ ...props.main_obj, ...address });
      props.nextStep();
    } else {
      errors.showMessages();
    }
  };

  return (
    <>
      <div className="row">
        <h4 className="mb-5 roboto-bold">
          {constLabel?.lbl_address_detail
            ? constLabel?.lbl_address_detail
            : "Address Detail"}
        </h4>
      </div>

      <div className="row">
        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold"
            >
              {constLabel?.lbl_address ? constLabel?.lbl_address : "Address"}
              <span className="text-danger"> *</span>
            </label>
            <textarea
              type="text"
              className={
                errors.address ? "form-control is-invalid" : "form-control"
              }
              id="address"
              name="address"
              placeholder="Enter address"
              defaultValue={props.main_obj.address && props.main_obj.address}
              {...register("address")}
            ></textarea>
            <span className="text-danger err-msg">
              {errors.address?.message}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold"
            >
              {constLabel?.lbl_address1 ? constLabel?.lbl_address1 : "Address1"}
            </label>
            <textarea
              type="text"
              className="form-control"
              id="address1"
              name="address1"
              placeholder="Enter address1"
              defaultValue={props.main_obj.address2 && props.main_obj.address2}
              {...register("address1")}
            ></textarea>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label roboto-bold"
            >
              {constLabel?.lbl_country ? constLabel.lbl_country : "Country"}
              <span className="text-danger"> *</span>
            </label>

            <Controller
              name="cCountry"
              control={control}
              defaultValue={
                props.main_obj.country_id &&
                props.main_obj.country_name !== null
                  ? [
                      {
                        id: props.main_obj.country,
                        countryName: props.main_obj.country_name,
                        uuid: props.main_obj.country_id,
                      },
                    ]
                  : []
              }
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="countryName"
                  multiple={false}
                  // size="sm"
                  options={
                    countryList.data !== undefined ? countryList.data : []
                  }
                  isLoading={countryList.state}
                  placeholder="Select country"
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  defaultSelected={
                    props.main_obj.country_id &&
                    props.main_obj.country_name !== null
                      ? [
                          {
                            id: props.main_obj.country,
                            countryName: props.main_obj.country_name,
                            uuid: props.main_obj.country_id,
                          },
                        ]
                      : []
                  }
                  isInvalid={errors.supplierType ? true : false}
                />
              )}
            />
            <span className="text-danger err-msg">
              {errors.cCountry?.message}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label roboto-bold"
            >
              {constLabel?.lbl_state ? constLabel.lbl_state : "State"}
              <span className="text-danger"> *</span>
            </label>

            <Controller
              name="cState"
              control={control}
              defaultValue={
                props.main_obj.state_id && props.main_obj.state_name !== null
                  ? [
                      {
                        id: props.main_obj.state,
                        stateName: props.main_obj.state_name,
                        uuid: props.main_obj.state_id,
                      },
                    ]
                  : []
              }
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="stateName"
                  multiple={false}
                  // size="sm"
                  options={stateList.data !== undefined ? stateList.data : []}
                  isLoading={stateList.state}
                  placeholder="Select state"
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  defaultSelected={
                    props.main_obj.state_id &&
                    props.main_obj.state_name !== null
                      ? [
                          {
                            id: props.main_obj.state,
                            countryName: props.main_obj.state_name,
                            uuid: props.main_obj.state_id,
                          },
                        ]
                      : []
                  }
                  isInvalid={errors.cState ? true : false}
                />
              )}
            />
            <span className="text-danger err-msg">
              {errors.cState?.message}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label roboto-bold"
            >
              {constLabel?.lbl_city ? constLabel.lbl_city : "City"}
              <span className="text-danger"> *</span>
            </label>

            <Controller
              name="cCity"
              control={control}
              defaultValue={
                props.main_obj.city_id && props.main_obj.city_name !== null
                  ? [
                      {
                        id: props.main_obj.city,
                        cityName: props.main_obj.city_name,
                        uuid: props.main_obj.city_id,
                      },
                    ]
                  : []
              }
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="cityName"
                  multiple={false}
                  // size="sm"
                  options={cityList.data !== undefined ? cityList.data : []}
                  isLoading={cityList.state}
                  placeholder="Select city"
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  defaultSelected={
                    props.main_obj.city_id && props.main_obj.city_name !== null
                      ? [
                          {
                            id: props.main_obj.city,
                            cityName: props.main_obj.city_name,
                            uuid: props.main_obj.city_id,
                          },
                        ]
                      : []
                  }
                  isInvalid={errors.cCity ? true : false}
                />
              )}
            />
            <span className="text-danger err-msg">{errors.cCity?.message}</span>
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold"
            >
              {constLabel?.lbl_postal_code
                ? constLabel?.lbl_postal_code
                : "Postal Code"}
              <span className="text-danger"> *</span>
            </label>
            <input
              type="text"
              className={
                errors.postalCode ? "form-control is-invalid" : "form-control"
              }
              id="postalCode"
              name="postalCode"
              placeholder="Enter fax no"
              maxLength={6}
              defaultValue={
                props.main_obj.postalCode && props.main_obj.postalCode
              }
              {...register("postalCode")}
            />
            <span className="text-danger err-msg">
              {errors.postalCode?.message}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold"
            >
              {constLabel?.lbl_fax_no ? constLabel?.lbl_fax_no : "Fax No"}{" "}<em className="body3"> (Ex. (123)-456-7890)</em> 
            </label>
            <input
              type="text"
              className="form-control"
              id="faxNo"
              name="faxNo"
              placeholder="Enter fax no"
              defaultValue={props.main_obj.faxNo && props.main_obj.faxNo}
              {...register("faxNo")}
            />
          </div>
        </div>
      </div>

      <hr className="my-3" />
      <div className="row  mt-0 ">
        <div className="d-md-flex justify-content-end">
          <button
            className="btn btn-grey px-4 me-2"
            onClick={() => props.previousStep()}
          >
            {constLabel?.lbl_previous ? constLabel?.lbl_previous : "Previous"}
          </button>
          <button
            className="btn button-primary px-4 me-2"
            onClick={handleSubmit(onSubmit)}
          >
            {constLabel?.lbl_save_continue
              ? constLabel?.lbl_save_continue
              : "Save & Continue"}
          </button>
          <button
            className="btn btn-grey px-4 me-2"
            disabled={Object.keys(props.main_obj).length > 1 ? false : true}
            onClick={() => props.nextStep()}
          >
            {constLabel?.lbl_next ? constLabel?.lbl_next : "Next"}
          </button>
        </div>
      </div>
    </>
  );
}

export default NewAddressDetail;
