import React, { useState, useEffect} from 'react'
import { useForm, Controller } from 'react-hook-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Edit2 } from 'react-feather';
import { Modal } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required("Name is required !")
      .matches(/^[a-zA-Z ]*$/, "Only alphabets and space Allowed !"),
    start_time: yup
      .string()
      .required("Start time is required !")
      .matches(/^[0-9:]*$/, "Only number and : Allowed !"),
    end_time: yup
      .string()
      .required("End Time is required !")
      .matches(/^[0-9:]*$/, "Only number and : Allowed !"),
  })
  .required();

function EditShiftMaster(props) {
const [visible, setVisible] = useState(false);

const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    }); // initialise the hook

    let { errors } = formState;

    const onSubmit = (data) => {
        
    
        if (data !== "") {
          const reqPayload = {
            box_type: data.box_type,
            dimentions: data.dimentions,
            std_box_qty: data.std_box_qty,
          };
    
          
    
          // saveNCReason(reqPayload)
          //   .then(
          //     (res) => {
          //       
          //       if (res.status === 200 || res.status === 201) {
          //         toast.success("NC Reason created Successfully !", {
          //           autoClose: 3000,
          //         });
          //         setVisible(false);
          //         fetchPkgMaterial();
          //       }
          //     },
          //     (err) => {
          //       
          //       if (err.response.status === 400) {
          //         
          //         toast.error(err.response, {
          //           autoClose: 2000,
          //         });
          //         reset();
          //       }
          //     }
          //   )
          //   .catch((error) => {
          //     
          //     toast.error("Something went wrong, please try again !", {
          //       autoClose: 2000,
          //     });
          //   });
        } else {
          errors.showMessages();
        }
      };

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
        <div
          data-tooltip-id={"edit-tooltip"}
          data-tooltip-content="Edit Shift" className="editIcon me-2">
          <Edit2
            color="#FFFFFF"
            size={18}
            className="cursor-pointer"
            onClick={() => {
              // setUpdateForm(val)
              setVisible(true); reset();
            }}
          />
        </div>
      <Modal show={visible} onHide={() => {setVisible(false); reset();}} size={"md"}>
          <Modal.Header
            style={{ background: "#2A3643", color: "white" }}
            closeButton
          >
            <Modal.Title>{"Update Shift"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <form action="">
              <div className="form-group row mt-2">
                <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
                  Name
                  <span>
                    <i style={{ color: "red" }}>*</i>
                  </span>
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control form-control"
                    id="name"
                    name="name"
                    {...register("name")}
                  />
                  <span className="text-danger">
                    {errors.name?.message}
                  </span>
                </div>
              </div>

              <div className="form-group row mt-2">
                <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
                Start Time
                  <span>
                    <i style={{ color: "red" }}>*</i>
                  </span>
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="start_time"
                    name="start_time"
                    {...register("start_time")}
                  />
                  <span className="text-danger">
                    {errors.start_time?.message}
                  </span>
                </div>
              </div>

              <div className="form-group row mt-2">
                <label className="form-label-sm form_label mt-2 roboto-bold col-md-4">
                   End Time
                  <span>
                    <i style={{ color: "red" }}>*</i>
                  </span>
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    id="end_time"
                    name="end_time"
                    {...register("end_time")}
                  />
                  <span className="text-danger">
                    {errors.end_time?.message}
                  </span>
                </div>
              </div>

            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleSubmit(onSubmit)}
              className="f-16 btn btn-yellow "
            >
              {"Save"}
            </button>
          </Modal.Footer>
        </Modal>

    </React.Fragment>
  )
}

export default EditShiftMaster