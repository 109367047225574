import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";

import { Edit, Eye, Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";

import { Tooltip } from "react-tooltip";
import List from "../../assets/images/icons/project_menu/checklist.png";
import Slide from "../../assets/images/icons/project_menu/collapse.png";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import General from "./General";
import Account from "./Account";
import { Screen } from "./Screen";
function Setting() {
  const [key, setKey] = useState(null);
  const [configuration, setconfiguration] = useState({});
  const [tab_arr, setTab_arr] = useState([
    "General",
    "Screen",
    "Color",
    "Account Setting",
  ]);

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="min-vh-100" id="root_div_main">
        {/* <div id="mySidebar" className="customsidebar ">
          <a className="closebtn" onClick={() => toggleNav()}>
            ×
          </a>

          <div className=" content">
            <div>
              <label
                className="filterLabel mb-1 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_customer
                  ? constLabel?.lbl_customer
                  : "Customer"}
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by customer (company) name"
                value={filterConfig.searchVal}
                onChange={(val) => {
                  const inputValue = val.target.value;
                  if (!inputValue.trim()) {
                    // Input contains only whitespace, don't trigger the search.
                    if (filterConfig.searchVal.length == 1) {
                      setFilterConfig({ ...filterConfig, searchVal: "" });
                    }
                  } else {
                    setFilterConfig({ ...filterConfig, searchVal: inputValue });
                  }
                }}
              />
            </div>

            <div>
                <label
                  className="filterLabel my-2 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {constLabel?.lbl_gst_no ? constLabel.lbl_gst_no : "GST No"}
                </label>
                <input
                  className="form-control "
                  type="text"
                  placeholder="Search by GST number"
                  value={filterConfig.GSTNO}
                  onChange={(val) => {
                    const inputValue = val.target.value; // Trim leading and trailing whitespaces
                      // console.log("inputValue",inputValue); 
                      // console.log("inputValue",inputValue.length); 
                      // console.log("!inputValue.trim()",!inputValue.trim())
                    if (!inputValue.trim()) {
                      // console.log("inputValue2",inputValue)
                      if (filterConfig.GSTNO.length == 1) {
                        // console.log("inputValue3",inputValue)
                        setFilterConfig({ ...filterConfig, GSTNO: "" });
                      }

                      if(inputValue.length==0){
                        setFilterConfig({ ...filterConfig, GSTNO: "" });
                      }

                    } else {
                      // console.log("inputValue4",inputValue)
                      setFilterConfig({
                        ...filterConfig,
                        GSTNO: inputValue,
                      });
                    }
                  }}
                />
              </div> 
            <div className="my-3 d-flex justify-content-end">
              <button
                className="btn btn-sm btn-yellow"
                onClick={() => clearFilter()}
              >
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                {constLabel?.lbl_clear_filter
                  ? constLabel.lbl_clear_filter
                  : "Clear Filter"}
              </button>
            </div>
          </div>
        </div> */}
        <div className="content-wrapper">
          <div className="card border-0">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="d-md-flex justify-content-start col-12 col-md-6">
                  <h1 className="bold">{"Setting"}</h1>
                </div>
              </div>
              <div className="mt-3 ">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => {
                    console.log("k", k);

                    setKey(k);
                  }}
                  className="mb-3"
                >
                  {tab_arr.map((item, index) => 
                    (<Tab eventKey={index} title={item} key={index}>
                      <div className="d-md-flex justify-content-between">
                        <div className="col-12 col-md-3">
                         
                          {item=="General"&&<General/>}
                      {item=="Account Setting"&&<Account/>}
                    
                      {item=="Screen"&&<Screen/>}
                     
                     
                        
                       
                          </div>   
                          <div className="col-12 col-md-9">


                            </div>                  
                   
                      </div>
                    </Tab>)
                  )}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Setting;
